import React, {useState} from 'react'
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { BsSearch } from 'react-icons/bs'

function SearchBox({setOpen}) {
    const history = useHistory()
    const [keyword, setKeyword] = useState('')
    const searchHandler = (e)=>{
        e.preventDefault()
        setOpen(false)
        if(keyword===''){
          return;
        }else{
          // if (url.includes("gifts") ){
          //   history.push(`/gifts/all/?keyword=&search=${keyword}&page=1`)
          // }
          // else{
          //   history.push(`/products/?keyword=${keyword}&page=1`)
          // }
          history.push(`/products/?keyword=${keyword}&page=1`)
        }
    }
  return (
    <Form className="d-flex my-2 my-xxl-0" onSubmit={searchHandler}>
      <InputGroup className="align-items-center">
        
        <FormControl
            type="search"
            placeholder="Search"
            
            aria-label="Search"
            value={keyword}
            onChange={(e)=>setKeyword(e.target.value)}
        />
        <Button type='submit'><BsSearch /></Button>
      </InputGroup>
                    
    </Form>
  )
}

export default SearchBox