import axios from 'axios'
import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    VENDOR_PRODUCT_LIST_REQUEST,
    VENDOR_PRODUCT_LIST_SUCCESS,
    VENDOR_PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL,

    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,

    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_FAIL,

    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,


    PRODUCT_TOP_REQUEST,
    PRODUCT_TOP_SUCCESS,
    PRODUCT_TOP_FAIL,

    PAGE_DETAILS_REQUEST,
    PAGE_DETAILS_SUCCESS,
    PAGE_DETAILS_FAIL,

    OCASSION_LIST_REQUEST,
    OCASSION_LIST_SUCCESS,
    OCASSION_LIST_FAIL,

    AVATAR_LIST_REQUEST,
    AVATAR_LIST_SUCCESS,
    AVATAR_LIST_FAIL,

    ALL_PRODUCTS_LIST_REQUEST,
    ALL_PRODUCTS_LIST_SUCCESS,
    ALL_PRODUCTS_LIST_FAIL,
    
    UPLOAD_PHOTO_REQUEST,
    UPLOAD_PHOTO_SUCCESS,
    UPLOAD_PHOTO_FAIL,

} from '../constants/productConstants'

// import { getDistance } from 'geolib';


export const listProducts = (keyword='') => async (dispatch, getState) => {

    
    
    try {
        dispatch({ type: PRODUCT_LIST_REQUEST })

        const {
            cart: { shippingAddress },
        } = getState()

        

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/all${keyword}`)

        // const distanceProducts = data.filter(item => getDistance({
        //     latitude: shippingAddress.latitude,
        //     longitude: shippingAddress.longitude
        // }, {
        //     latitude: item.storeLocation.split(',')[0],
        //     longitude: item.storeLocation.split(',')[1],
        // })<10000)



        

        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data,
            

        })

    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listAllProducts = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_LIST_REQUEST })

        

        

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/`)

        


        

        dispatch({
            type: ALL_PRODUCTS_LIST_SUCCESS,
            payload: data,
            

        })

    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listOcassions = () => async (dispatch, getState) => {
    try {
        dispatch({ type:OCASSION_LIST_REQUEST })

        

        

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/ocassions/`)

        dispatch({
            type:OCASSION_LIST_SUCCESS,
            payload: data,
            

        })

    } catch (error) {
        dispatch({
            type:OCASSION_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listAvatars = () => async (dispatch, getState) => {
    try {
        dispatch({ type:AVATAR_LIST_REQUEST })

        

        

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/avatars/`)

        dispatch({
            type:AVATAR_LIST_SUCCESS,
            payload: data,
            

        })

    } catch (error) {
        dispatch({
            type:AVATAR_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listTopProducts = () => async (dispatch, getState) => {
    try {
        dispatch({ type: PRODUCT_TOP_REQUEST })

        const {
            cart: { shippingAddress },
        } = getState()

        

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/top/`)

        
        

        

        

        dispatch({
            type: PRODUCT_TOP_SUCCESS,
            payload: data.slice(0,3)
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_TOP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listProductDetails = (id, pageNumber) => async (dispatch) => {
    try {
        
        dispatch({ type: PRODUCT_DETAILS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/${id}/`)

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data,
            pageNumber: pageNumber,
            
            
        })

    } catch (error) {

        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const previewPageDetails = (id, tracking_id, pageNumber) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST })

        if (tracking_id) {
            const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/${id}/${tracking_id}/`)

            dispatch({
                type: PRODUCT_DETAILS_SUCCESS,
                payload: data,
                pageNumber: pageNumber,

            })

        }else {
            const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/${id}/`)

            dispatch({
                type: PRODUCT_DETAILS_SUCCESS,
                payload: data,
                pageNumber: pageNumber,

            })
        }

    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listPageDetails = (id, pageNumber, tracking_id) => async (dispatch) => {
    try {

        dispatch({ type: PAGE_DETAILS_REQUEST })
        if(tracking_id){
            const { data } = await axios.post(`${process.env.REACT_APP_PROD_URL}/api/products/page/`, { id, pageNumber, tracking_id })

            dispatch({
                type: PAGE_DETAILS_SUCCESS,
                payload: data,


            })
        } else{
            const { data } = await axios.post(`${process.env.REACT_APP_PROD_URL}/api/products/page/`, { id, pageNumber })

            dispatch({
                type: PAGE_DETAILS_SUCCESS,
                payload: data,


            })
        }


    } catch (error) {
        dispatch({
            type: PAGE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const deleteProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(
            `${process.env.REACT_APP_PROD_URL}/api/products/delete/${id}/`,
            config
        )

        dispatch({
            type: PRODUCT_DELETE_SUCCESS,
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createProduct = (product) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROD_URL}/api/products/create/`,
            product,
            config
        )
        dispatch({
            type: PRODUCT_CREATE_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateProduct = (product) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_UPDATE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(
            `${process.env.REACT_APP_PROD_URL}/api/products/update/${product._id}/`,
            product,
            config
        )
        dispatch({
            type: PRODUCT_UPDATE_SUCCESS,
            payload: data,
        })


        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })


    } catch (error) {
        dispatch({
            type: PRODUCT_UPDATE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const createProductReview = (productId, review) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROD_URL}/api/products/${productId}/reviews/`,
            review,
            config
        )
        dispatch({
            type: PRODUCT_CREATE_REVIEW_SUCCESS,
            payload: data,
        })



    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listVendorProducts = () => async (dispatch, getState) => {
    try {
        dispatch({ type: VENDOR_PRODUCT_LIST_REQUEST })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/vendors/`, config)

        


        

        dispatch({
            type: VENDOR_PRODUCT_LIST_SUCCESS,
            payload: data

        })

    } catch (error) {
        dispatch({
            type: VENDOR_PRODUCT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const uploadPhoto = (formData) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPLOAD_PHOTO_REQUEST
        })

        // const {
        //     userLogin: { userInfo },
        // } = getState()

        // const config = {
        //     headers: {
        //         'Content-type': 'application/json',
        //         Authorization: `Bearer ${userInfo.token}`
        //     }
        // }

        const { data } = await axios.post(
            `${process.env.REACT_APP_PROD_URL}/api/orders/upload/`,
            formData,
            // config
        )
        dispatch({
            type: UPLOAD_PHOTO_SUCCESS,
            payload: data,
        })


    } catch (error) {
        dispatch({
            type: UPLOAD_PHOTO_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}