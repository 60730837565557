import React, {useEffect} from 'react'
import {Row,Col, Image, Container} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listAllProducts } from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Product from '../components/Product'
import { useHistory } from 'react-router-dom';

function CoverScreen() {
    const history = useHistory()
    const dispatch = useDispatch()
    const allProductsList = useSelector(state => state.allProductsList)
    const { error, loading, products} = allProductsList
    const sender = parseInt(localStorage.getItem('senderAvatar'))
    const recipient = parseInt(localStorage.getItem('recipientAvatar'))
    
    

    useEffect(() => {
        window.scrollTo(0,0)
        
        dispatch(listAllProducts())

        if(!sender || !recipient){
            history.push('/avatar')
        }

        
        
        
    }, [dispatch, history, sender, recipient])


    const covers = [
        {
            name: 'cover1',
            url:"https://drive.google.com/uc?export=view&id=1i5F5JetTFkVqeSlbzY7kdFWjO9J5FSdn",
        },
        {
            name: 'cover2',
            url:"https://drive.google.com/uc?export=view&id=1ZnJkkYrlub1Hty8tVyM2Lec002wwinjS"
        }
    ]
  return (
    <Container className="products_screen nav_margin">
        <Row>
            {loading ? <Loader />
                : error ? <Message variant='error'>{error}</Message>
                    :products.filter(x=>x.sender===sender && x.recepient===recipient).map(cover => (
                <Col className="px-3 py-4" sm={12} md={6} lg={4} xl={3} key={cover._id}>
                    <Product product={cover} />
                    
                </Col>
                
            ))}
            {(!loading && !error && products.filter(x=>x.sender===sender && x.recepient===recipient).length===0) &&<Message variant='info'>No products found</Message>}
        </Row>
    </Container>
  )
}

export default CoverScreen