export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'


export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'
export const PRODUCT_DETAILS_RESET = 'PRODUCT_DETAILS_RESET'


export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'


export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'


export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'


export const VENDOR_PRODUCT_LIST_REQUEST = 'VENDOR_PRODUCT_LIST_REQUEST'
export const VENDOR_PRODUCT_LIST_SUCCESS = 'VENDOR_PRODUCT_LIST_SUCCESS'
export const VENDOR_PRODUCT_LIST_FAIL = 'VENDOR_PRODUCT_LIST_FAIL'

export const PAGE_DETAILS_REQUEST = 'PAGE_DETAILS_REQUEST'
export const PAGE_DETAILS_SUCCESS = 'PAGE_DETAILS_SUCCESS'
export const PAGE_DETAILS_FAIL = 'PAGE_DETAILS_FAIL'

export const OCASSION_LIST_REQUEST = 'OCASSION_LIST_REQUEST'
export const OCASSION_LIST_SUCCESS = 'OCASSION_LIST_SUCCESS'
export const OCASSION_LIST_FAIL = 'OCASSION_LIST_FAIL'

export const AVATAR_LIST_REQUEST = 'AVATAR_LIST_REQUEST'
export const AVATAR_LIST_SUCCESS = 'AVATAR_LIST_SUCCESS'
export const AVATAR_LIST_FAIL = 'AVATAR_LIST_FAIL'

export const ALL_PRODUCTS_LIST_REQUEST = 'ALL_PRODUCTS_LIST_REQUEST'
export const ALL_PRODUCTS_LIST_SUCCESS = 'ALL_PRODUCTS_LIST_SUCCESS'
export const ALL_PRODUCTS_LIST_FAIL = 'ALL_PRODUCTS_LIST_FAIL'


export const UPLOAD_PHOTO_REQUEST = 'UPLOAD_PHOTO_REQUEST'
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'
export const UPLOAD_PHOTO_FAIL = 'UPLOAD_PHOTO_FAIL'


