import React, {useEffect} from 'react'
import {Row,Col, Image, Container} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listProducts } from '../actions/productActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Product from '../components/Product'
import Gift from '../components/Gift'
import { useHistory } from 'react-router-dom';
import Paginate from '../components/Paginate'
import 'intasend-inlinejs-sdk'

function ProductsScreen({history}) {
    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages} = productList

    let keyword = history.location.search
    
    new window.IntaSend({
        publicAPIKey: "ISPubKey_test_7557c319-53ac-4757-bae1-5dc195ea64bd",
        live: false //or false for sandbox environment

    })
    .on("COMPLETE", (response) => { console.log("COMPLETE:", response) })
    .on("FAILED", (response) => { console.log("FAILED", response) })
    .on("IN-PROGRESS", () => { console.log("INPROGRESS ...") })
    

    useEffect(() => {
        window.scrollTo(0,0)
        
        dispatch(listProducts(keyword))


        
        
    }, [dispatch, keyword])


    const covers = [
        {
            name: 'cover1',
            url:"https://drive.google.com/uc?export=view&id=1i5F5JetTFkVqeSlbzY7kdFWjO9J5FSdn",
        },
        {
            name: 'cover2',
            url:"https://drive.google.com/uc?export=view&id=1ZnJkkYrlub1Hty8tVyM2Lec002wwinjS"
        }
    ]
  return (
    <Container className="products_screen nav_margin">

        <Row>
            {(keyword && keyword.split('?keyword=')[1].split('&')[0]!=='') && <h2>Results for {keyword.split('?keyword=')[1].split('&')[0].replaceAll('%20', ' ')}</h2>}
            {loading ? <Loader width={80} />
                : error ? <Message width={80} variant='error'>{error}</Message>
                    :products.map(cover => {
                        if(cover.gift_type)
                            return <Col className="px-3 py-4" sm={12} md={6} lg={4} xl={3} key={cover._id}>
                                        <Gift className='stretch' product={cover} />
                                    </Col> 
                        
                        return <Col className="px-3 py-4" sm={12} md={6} lg={4} xl={3} key={cover._id}>
                                    <Product product={cover} />
                                </Col>
                
                    })}
            {(!loading && !error && products.length===0) && <Message variant='info'>No products found</Message>}
        </Row>
        
        {(!loading && !error) &&<Paginate page={page} pages={pages} keyword={keyword} />}
    </Container>
  )
}

export default ProductsScreen