import React, {useEffect, useState} from 'react'
import Draggable from 'react-draggable';
import { ReactSVG } from 'react-svg'
import SVG from 'react-inlinesvg';
import { Resizable } from 're-resizable'




function PreviewFlip({background, foreground, texts}) {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
            setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [width]);
    
    return (
        <div className={`cover_paper flip_page`}  style={{ overflow:'hidden', backgroundImage: `url(${background})`, }}>

            <Draggable
                defaultPosition={{ x: width <= 900 ? parseInt(40) : parseInt(50), y: width <= 900 ? parseInt(30) / 2 : parseInt(40) }}
                bounds="parent" >
                {/* <img className='foreground_img' src={page ? page.foreground_image : ''} style={{width:'200px', height:'400px'}} /> */}
                <Resizable
                    // defaultSize={{
                    //     width: 262,
                    //     height: 404
                    // }}
                    defaultSize={{ width: width <= 900 ? parseInt(262) / 2 : parseInt(262), height: width <= 900 ? parseInt(404) / 2 : parseInt(404) }}
                    style={{
                        position: 'absolute'
                    }}
                    lockAspectRatio={true}
                >
                    <div id='foreground'
                        style={{
                            backgroundImage: `url(${foreground})`, width: "100%",
                            height: "100%", backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                        }}
                        >

                    </div>

                </Resizable>    
            </Draggable>
            
            { texts.map(text=>(


                
                <Draggable
                    handle={`.textbox${text._id}`}
                    key = {text._id}
                    position={{x: width<=900?parseInt(text.x)/2 :parseInt(text.x), y: width<=900?parseInt(text.y)/2:parseInt(text.y)}}
                    bounds="parent"
                    disabled={true}
                >
                    <p  dangerouslySetInnerHTML={{__html: text.defaultText||text.message}} className={`${text._id} textbox${text._id} text-center`}  id={text._id} suppressContentEditableWarning={true} style={{whiteSpace: 'pre-line', width:width<=900?parseInt(text.width)/2 :parseInt(text.width), height:width<=900?parseInt(text.height)/2 :parseInt(text.height), fontFamily:text.fontFamily,fontSize:width<=900?parseInt(text.fontSize)/2:parseInt(text.fontSize), color:text.fontColor}}></p>
                </Draggable>))}
                
                
        </div>
                    
   );
}

export default PreviewFlip