import React from 'react'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { jengaPay } from '../actions/orderActions';
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material';

function PaymentButton({amount, name, email,tref, phoneNumber, description}) {
    const dispatch= useDispatch()
    const config = {
        public_key: 'FLWPUBK-b99ed1e95262a18030c0b4de1aff6022-X',
        tx_ref: tref,
        amount: amount,
        currency: 'KES',
        payment_options: 'card, mpesa',
        customer: {
          email: email,
          phonenumber: phoneNumber,
          name: name,
        },
        customizations: {
          title: 'Your Payment from InMyWords',
          description: description,
          logo: 'https://inmywords.co.ke/static/favicon.ico',
        },
    };

    const handleFlutterPayment = useFlutterwave(config);
    
    
  return (
    <Button
        variant="contained"
        color='success'
        fullWidth
        
        onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
               dispatch(jengaPay(response));
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
          });
        }}
    >
      Make Payment
    </Button>
  )
}

export default PaymentButton