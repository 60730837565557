import React, {useState, useEffect} from 'react'
import { Form, Button } from 'react-bootstrap'
import FormContainer from '../components/FormContainer'
import { useDispatch, useSelector } from 'react-redux'
import { jengaPay } from '../actions/orderActions'

function PaymentScreen({match, history}) {
    const [customerFirstName, setCustomerFirstName] = useState('')
    const [customerLastName, setCustomerLastName] = useState('')
    const [customerEmail, setcustomerEmail] = useState('')
    const [customerPhone, setCustomerPhone] = useState('')
    const [customerPostalCodeZip, setCustomerPostalCodeZip] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [customerAddress, setCustomerAddress] = useState('')
    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart
    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const payJenga = useSelector(state => state.payJenga)
    const { loading:loadPay, error:errorPay, success:successPay } = payJenga

    const validatePhone = () => {
        var re = /^\d{10}$/;
        setPhoneError(!re.test(customerPhone))
    }

    const handlePhoneChange = (e) => {
        setCustomerPhone( e.target.value)
        
        validatePhone()
    
    };
    const submitHandler = (e) => {
        e.preventDefault()
        

        if (setPhoneError) {
            return false
        }
        else {
            dispatch(jengaPay({customerFirstName, customerLastName, customerEmail, customerPostalCodeZip, customerPhone}))
        }

    }

    useEffect(() => {
        if(!userInfo){
            history.push('/login')
        }
        else if(!shippingAddress && (!shippingAddress.latitude || shippingAddress.longitude || shippingAddress.address)){
            history.push(`/shipping`)
        }
        else{
            setCustomerFirstName(userInfo.firstName)
            setCustomerLastName(userInfo.lastName)
            setcustomerEmail(userInfo.email)
            setCustomerPhone(userInfo.phoneNumber)
            setCustomerAddress(shippingAddress.address)
        }
    }, [history, userInfo, shippingAddress])
    
    
    return (
    
        <FormContainer classes={'nav_margin py-4'}>
            {/* <Form onSubmit={submitHandler}>
                <Form.Group className="mb-3" controlId="customerFirstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control value={customerFirstName} onChange={(e)=>setCustomerFirstName(e.target.value)}  type="text" placeholder="Enter First Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="customerLastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control value={customerLastName} onChange={(e)=>setCustomerLastName(e.target.value)}  type="text" placeholder="Enter Last Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="customerEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control isInvalid={emailError} value={customerEmail} onChange={(e)=>setcustomerEmail(e.target.value)} type="email" placeholder="Enter email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="customerPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control isInvalid={phoneError} value={customerPhone} onChange={(e)=>handlePhoneChange(e)} type="text" placeholder="Enter Phone Number" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="customerAddress">
                    <Form.Label>Billing Address</Form.Label>
                    <Form.Control value={customerAddress} onChange={(e)=>setCustomerAddress(e.target.value)}  type="text" placeholder="Enter Billing Address" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="customerPostalCodeZip">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control value={customerPostalCodeZip} onChange={(e)=>setCustomerPostalCodeZip(e.target.value)} type="text" placeholder="Enter Zip Code" />
                </Form.Group>

                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form> */}
            <form id="submitcheckout" action="https://payment-gateway-v3-uat.azurewebsites.net/processPayment" method="POST">   
                <input type="hidden" id="token" name="token"/> 
                <input type="hidden" id="merchantCode" name="merchantCode"/>  
                <input type="hidden" id="wallet" name="wallet"/>  
                <input type="hidden" id="orderAmount" name="orderAmount"/> 
                <input type="hidden" id="orderReference" name="orderReference"/> 
                <input type="hidden" id="productType" name="productType"/>  
                <input type="hidden" id="productDescription" name="productDescription"/> 
                <input type="hidden" id=" paymentTimeLimit " name=" paymentTimeLimit "/>  
                <input type="hidden" id="customerFirstName" name="customerFirstName"/>  
                <input type="hidden" id="customerLastName" name="customerLastName"/>  
                <input type="hidden" id="customerPostalCodeZip" name=" customerPostalCodeZip "/>  
                <input type="hidden" id="customerAddress" name=" customerAddress "/> 
                <input type="hidden" id="customerEmail" name="customerEmail" />
                <input type="hidden" id="customerPhone" name="customerPhone"/>
                <input type="hidden" id="extraData" name="extraData"/>  
                <input type="hidden" id="callbackUrl" name="callbackUrl"/>
            </form>
        </FormContainer>

    
  )
}

export default PaymentScreen