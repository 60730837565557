import React, { useEffect, useState, useRef } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ReactToPrint from 'react-to-print';
import { listProductDetails, listPageDetails, uploadPhoto } from '../actions/productActions';
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Draggable from 'react-draggable';
import { Resizable } from 're-resizable'
import { addToCart } from '../actions/cartActions'
import { useHistory, useLocation } from 'react-router-dom';
import { HexColorPicker } from "react-colorful";
import { isMobile, isSafari } from 'react-device-detect';
import { auto, eventListeners } from '@popperjs/core';
import FormControl from '@mui/material/FormControl';
import { Form } from 'react-bootstrap'
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import axios from 'axios'
import $ from "jquery";
import { GIFT_CART_REMOVE_ITEM } from '../constants/giftConstants'




function CoverDesignScreen({ match }) {

    const location = useLocation()
    const dispatch = useDispatch()
    const [resize, setResize] = useState(false)
    const history = useHistory()
    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const gift_cart = useSelector(state => state.gift_cart)
    const { giftCartItems } = gift_cart

    const sender = parseInt(localStorage.getItem('senderAvatar'))

    const tracking_id = localStorage.getItem('tracking_id')

    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product } = productDetails

    const pageDetails = useSelector(state => state.pageDetails)
    const { loading: loadingPage, error: errorPage, page, texts } = pageDetails

    const [fontSize1, setFontSize1] = useState(30)
    const [fontFamily1, setFontFamily1] = useState('myCoverFont')
    const [fontColor1, setFontColor1] = useState('#ffffff')
    const [fontMessage1, setFontMessage1] = useState('Enter Message')

    const [fontSize2, setFontSize2] = useState(30)
    const [fontFamily2, setFontFamily2] = useState('myCoverFont')
    const [fontColor2, setFontColor2] = useState('#ffffff')
    const [fontMessage2, setFontMessage2] = useState('Enter Message')

    const [fontSize3, setFontSize3] = useState(30)
    const [fontFamily3, setFontFamily3] = useState('myCoverFont')
    const [fontColor3, setFontColor3] = useState('#ffffff')
    const [fontMessage3, setFontMessage3] = useState('Enter Message')

    const [editMode, setEditMode] = useState(false)
    const redirect = location.search ? location.search.split('=')[1] : undefined

    useEffect(() => {
        if (localStorage.getItem("giftCartItems")) {
            // localStorage.removeItem('giftCartItems')
            dispatch({ type: GIFT_CART_REMOVE_ITEM })
        }

        console.log('page number', match.params.pageNumber)

        setFontMessage1('Enter Message')
        setFontMessage2('Enter Message')
        setFontMessage3('Enter Message')
        setEditMode(true)
        window.scrollTo(0, 0)
        dispatch(listProductDetails(match.params.id, match.params.pageNumber))
        dispatch(listPageDetails(match.params.id, match.params.pageNumber, tracking_id))

    }, [dispatch, match, cartItems, history, tracking_id, giftCartItems])





    const printRef = useRef();
    const [selectedPhoto, setPhoto] = useState("");
    var input_file = ""

    const triggerPhotoUpload = () => {
        console.log('starting upload')
        var fore_ground = document.getElementById('foreground')
        if (!fore_ground.style.border) {
            fore_ground.style.border = '2px solid rgba(255, 0, 0, 0.1)';
        }
        // var modal = document.getElementById("photoModal");
        // modal.style.display = true;
    }

    const toBase64String = (image_data) => {

        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(image_data);

            reader.onload = () => {
                resolve(reader.result.replace("data:", "").replace(/^.+,/, ""));
            };

            reader.onerror = (error) => {
                reject(error);
            };
        })
    }

    const handleFileUpload = async (event) => {
        // var input_file = document.getElementById("custom_photo")
        // console.log(input_file._valueTracker.getValue)
        // input_file._valueTracker.setValue(event.target.files[0])
        // var input_file = event
        const file = event.target.files[0];
        const base64 = await toBase64String(file);
        input_file = base64

        // selectedFile = event.target.files[0];
        // Perform further operations with the file
    };

    const [uploadingImage, setuploadingImage] = useState(false)
    const [fgImage, setFgImage] = useState("")

    const uploadPhotoProxy = async (e) => {
        setuploadingImage(true)
        console.log("Uploading photo ...")
        var fore_ground = document.getElementById('foreground')
        const formData = {
            "foreground_photo": input_file,
            "product_id": product._id,
            "pageNumber": page.pageNumber
        }
        if (tracking_id) {
            formData["tracking_id"] = tracking_id
        }

        await axios
            .post(`${process.env.REACT_APP_PROD_URL}/api/orders/upload/`, formData)
            .then((resp) => {
                // let upload_modal = document.getElementById('exampleModal')
                var resp_data = resp.data
                console.log(resp_data.tracking_no)
                localStorage.setItem('tracking_id', resp_data.tracking_no)
                fore_ground.style.backgroundImage = `url(${resp_data.foreground_image})`
                // upload_modal.hide()
                setuploadingImage(false)
            })
            .catch((err) => {
                setuploadingImage(false)
                alert(err)
            })

        // dispatch(uploadPhoto(formData))

    }

    const handleClick = (a) => {


    };

    const handlefontStyleChange = (value, classId, index) => {

        if (index === 0) {
            setFontFamily1(value)
        }

        if (index === 1) {
            setFontFamily2(value)
        }
        if (index === 2) {
            setFontFamily3(value)
        }

        if (document.getElementById(classId)) {
            document.getElementById(classId).style.fontFamily = value
        }
    };

    const handleFontSizeChange = (event, classId, index) => {

        if (index === 0) {
            setFontSize1(Number(event.target.value))
        }

        if (index === 1) {
            setFontSize2(Number(event.target.value))
        }
        if (index === 2) {
            setFontSize3(Number(event.target.value))
        }

        if (document.getElementById(classId)) {
            document.getElementById(classId).style.fontSize = `${event.target.value}px`

        }
    };

    const handlefontColorChange = (value, classId, index) => {

        if (index === 0) {
            setFontColor1(value)
        }

        if (index === 1) {
            setFontColor2(value)
        }
        if (index === 2) {
            setFontColor3(value)
        }

        if (document.getElementById(classId)) {
            document.getElementById(classId).style.color = value
        }
    };

    const handlefontMessageChange = (value, classId, index) => {

        if (index === 0) {
            setFontMessage1(value.target.value)
        }

        if (index === 1) {
            setFontMessage2(value.target.value)
        }
        if (index === 2) {
            setFontMessage3(value.target.value)
        }
        document.getElementById(classId).innerHTML = value.target.value

    };

    const handleEdit = () => {

        var fore_ground = document.getElementById('foreground')
        if (!fore_ground.style.border) {
            fore_ground.style.border = '2px solid rgba(255, 0, 0, 0.1)';
        }

        if (cartItems.filter(y => y.productId === match.params.id && y.pageNumber === match.params.pageNumber).length === 0) {
            texts.forEach((x, index) => {
                if (index === 0) {
                    setFontColor1(x.fontColor)
                    setFontSize1(parseInt(x.fontSize))
                    setFontFamily1(x.fontFamily)
                }

                if (index === 1) {
                    setFontColor2(x.fontColor)
                    setFontSize2(parseInt(x.fontSize))
                    setFontFamily2(x.fontFamily)
                }
                if (index === 2) {
                    setFontColor3(x.fontColor)
                    setFontSize3(parseInt(x.fontSize))
                    setFontFamily3(x.fontFamily)
                }

                setEditMode(true)
            })
        }

        cartItems.filter(y => y.productId === match.params.id && y.pageNumber === match.params.pageNumber).forEach((x, index) => {
            if (index === 0) {
                setFontColor1(x.fontColor)
                setFontSize1(parseInt(x.fontSize))
                setFontFamily1(x.fontFamily)
            }

            if (index === 1) {
                setFontColor2(x.fontColor)
                setFontSize2(parseInt(x.fontSize))
                setFontFamily2(x.fontFamily)
            }
            if (index === 2) {
                setFontColor3(x.fontColor)
                setFontSize3(parseInt(x.fontSize))
                setFontFamily3(x.fontFamily)
            }

            setEditMode(true)
        })
    }

    const handleStop = (event, dragElement) => {
        handleEdit()

        const dragObj = {
            _id: Number(dragElement.node.classList[0]),
            x: dragElement.x,
            y: dragElement.y
        }
        let dragItems = JSON.parse(localStorage.getItem('dragItems')) || []


        const existItem = dragItems.find(x => x._id === Number(dragElement.node.classList[0]))

        if (existItem) {

            dragItems = dragItems.map(x => x._id === existItem._id ? dragObj : x)

        } else {
            dragItems.push(dragObj)

        }


        localStorage.setItem('dragItems', JSON.stringify(dragItems))

    }



    const handleSave = (action, isLastPage) => {
        setEditMode(false)
        const dragItems = JSON.parse(localStorage.getItem('dragItems')) || []

        texts.forEach((element, index) => {

            const existItem = dragItems.find(x => x._id === element._id)
            let x
            let y
            if (existItem) {
                x = existItem.x
                y = existItem.y
            } else {
                x = element.x
                y = element.y
            }


            dispatch(addToCart(
                {
                    _id: element._id,
                    productId: match.params.id,
                    pageNumber: match.params.pageNumber,
                    fontFamily: document.getElementById(element._id).style.fontFamily,
                    fontSize: parseInt(document.getElementById(element._id).style.fontSize),
                    fontColor: document.getElementById(element._id).style.color,
                    message: document.getElementById(element._id).innerHTML,
                    x: x,
                    y: y,
                    width: document.getElementById(element._id).getBoundingClientRect().width,
                    height: document.getElementById(element._id).getBoundingClientRect().height


                }
            ))

        });

        if (isLastPage) {

            redirect ? history.push(`/${redirect}`) : history.push(`/preview/${match.params.id}`)


        } else {

            if (action === 'next') {

                history.push(`/product/${match.params.id}/page/${Number(match.params.pageNumber) + 1}`)

            }
            if (action === 'prev') {
                history.push(`/product/${match.params.id}/page/${Number(match.params.pageNumber) - 1}`)
                // window.location.reload(true)
            }
        }

    }

    return (
        <div className="w-100 nav_margin pt-4">
            {loading || loadingPage ? <Loader width={80} /> : error || errorPage ? <Message width={80} variant='error'>{error || errorPage}</Message> :
                <div className="cover_design" >
                    <div className="cover_paper" ref={printRef} style={{ backgroundImage: `url(${page ? page.background_image : ''})`, width: '396px', height: '612px', position: 'relative', overflow: 'hidden' }}>

                        <Draggable
                            defaultPosition={{ x: 35, y: 42 }}
                            bounds="parent"
                            onStop={handleStop} >
                            {/* <img className='foreground_img' src={page ? page.foreground_image : ''} style={{width:'200px', height:'400px'}} /> */}
                            <Resizable
                                defaultSize={{
                                    width: 328,
                                    height: 507
                                }}
                                style={{
                                    position: 'absolute'
                                }}
                                lockAspectRatio={true}
                            >
                                <div id='foreground' onClick={(e) => handleClick(e)} onBlur={(e) => handleClick(e)}
                                    style={{
                                        backgroundImage: `url(${page ? page.foreground_image : ''})`, width: "100%",
                                        height: "100%", backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                                    }}>

                                </div>

                            </Resizable>
                        </Draggable>

                        {texts.map(text => (
                            cartItems.find(x => x._id === text._id) ?
                                <Draggable
                                    handle={`.textbox${cartItems.find(x => x._id === text._id)._id}`}
                                    key={cartItems.find(x => x._id === text._id)._id}
                                    defaultPosition={{ x: parseInt(cartItems.find(x => x._id === text._id).x), y: parseInt(cartItems.find(x => x._id === text._id).y) }}
                                    bounds="parent"
                                    onStop={handleStop}
                                    disabled={resize}

                                >
                                    <p onDoubleClick={() => setResize(!resize)} dangerouslySetInnerHTML={{ __html: cartItems.find(x => x._id === text._id).message }} className={`${cartItems.find(x => x._id === text._id)._id} textbox${cartItems.find(x => x._id === text._id)._id} text-center`} id={cartItems.find(x => x._id === text._id)._id} contentEditable suppressContentEditableWarning={true} style={{ whiteSpace: 'pre-line', width: cartItems.find(x => x._id === text._id).width, height: 'auto', fontFamily: cartItems.find(x => x._id === text._id).fontFamily, fontSize: cartItems.find(x => x._id === text._id).fontSize, color: cartItems.find(x => x._id === text._id).fontColor, resize: `${resize ? 'both' : 'none'}`, overflow: 'hidden', position: 'relative' }}></p>
                                </Draggable> : <Draggable
                                    handle={`.textbox${text._id}`}
                                    key={text._id}
                                    defaultPosition={{ x: parseInt(text.x), y: parseInt(text.y) }}
                                    bounds="parent"
                                    onStop={handleStop}
                                    disabled={resize}

                                >
                                    <p onDoubleClick={() => setResize(!resize)} dangerouslySetInnerHTML={{ __html: text.defaultText }} className={`${text._id} textbox${text._id} text-center`} id={text._id} contentEditable={editMode} suppressContentEditableWarning={true} style={{ whiteSpace: 'pre-line', width: text.width, height: 'auto', fontFamily: text.fontFamily, fontSize: text.fontSize, color: text.fontColor, resize: `${resize ? 'both' : 'none'}`, overflow: 'hidden' }}></p>
                                </Draggable>))

                        }

                        {/* <p contentEditable suppressContentEditableWarning={true} className="main_cover_text sub_text" style={{fontFamily:'myCoverFont',fontSize:'30px', color:'white'}}>Love, Hellen</p> */}

                    </div>
                    {/* <ul className='instruction_list'>
                        <li className='text-left  instructions'>Double-Click Text To Switch between Drag and Resize</li>
                        <li className='text-left  instructions'>Resize from the bottom-left corner</li>
                        <li className='text-left text-primary instructions'>Setting: {resize?'Resize':'Drag'}</li>
                    </ul> */}


                    {
                        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Upload Photo</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div className="modal-body">
                                        <form className='mb-4'>
                                            {/* <FormControl size="large" variant="standard" fullWidth > */}

                                            <input
                                                className='form-control'
                                                sx={{ padding: '10px 10px', background: 'white', color: '#000000', borderRadius: '.5rem' }}
                                                required
                                                id="custom_photo"
                                                type="file"
                                                accept="image/jpeg, image/jpg, image/png, image/gif"
                                                // ref={selectedPhoto}
                                                onChange={handleFileUpload}
                                            />

                                            {/* </FormControl> */}
                                            <div className='text-center'>* 10 MB maximum</div>
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                <button type="button" onClick={uploadPhotoProxy} className="btn btn-primary" data-bs-dismiss="modal">Upload</button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>

                    }

                    {
                        uploadingImage ? <Loader width={80} />
                            : null
                    }

                    <div className="cover_controls">
                        <List className="w-100 shadow">
                            <ListItem className='w-100'>
                                <List className='w-100'>
                                    <div className='w-100 d-flex justify-content-between'>
                                        {(product.pages && product.pages.length) && <button onClick={() => handleEdit()} className={`mx-4 btn btn-primary ${(sender === 1 || product.sender === 1) && 'male'}`}>Click to Edit</button>}

                                        {(product.pages && product.pages.length) && <button onClick={() => triggerPhotoUpload()} className={`mx-4 btn btn-primary ${(sender === 1 || product.sender === 1) && 'male'}`} data-bs-toggle="modal" data-bs-target="#exampleModal" >Upload Photo</button>}

                                        {redirect && <button onClick={() => handleSave('next', true)} className={`mx-4 btn btn-primary ${(sender === 1 || product.sender === 1) && 'male'}`}>Save</button>}
                                    </div>

                                    {texts.map((text, index) => (
                                        cartItems.find(x => x._id === text._id) ?
                                            <ListItem key={text._id} className='w-100'>
                                                {(product.pages && product.pages.length) && <List className='w-100'>

                                                    <ListItem className='d-flex justify-content-between' >
                                                        <h2 className='control_header'>{`Font ${index + 1}`}</h2>


                                                    </ListItem>
                                                    <Divider />
                                                    {(isMobile && isSafari) && <ListItem className='d-flex flex-column align-items-start' >
                                                        <label className='input_label mb-2'>Message</label>
                                                        <textarea rows="4" className='form-control mb-2' value={index === 0 ? (fontMessage1 === 'Enter Message' ? cartItems.find(x => x._id === text._id).message.replace('<div>', '\n').replace('</div>', '') : fontMessage1.replace('<div>', '\n').replace('</div>', '')) : index === 1 ? (fontMessage2 === 'Enter Message' ? cartItems.find(x => x._id === text._id).message.replace('<div>', '\n').replace('</div>', '') : fontMessage2.replace('<div>', '\n').replace('</div>', '')) : (fontMessage3 === 'Enter Message' ? cartItems.find(x => x._id === text._id).message.replace('<div>', '\n').replace('</div>', '') : fontMessage3.replace('<div>', '\n').replace('</div>', ''))} onChange={(e) => handlefontMessageChange(e, text._id, index)}> </textarea>
                                                    </ListItem>}
                                                    {(isMobile && isSafari) && <Divider />}
                                                    <ListItem className='d-flex justify-content-between' >
                                                        <label className='input_label'>Size</label>
                                                        {!editMode && <div style={{ maxWidth: '50%' }} className='form-control'>{cartItems.find(y => y._id === text._id).fontSize}</div>}
                                                        {editMode && <input className='form-control' type="number" min={20} max={50} aria-label="mainFontSize" style={{ maxWidth: '50%' }} value={index === 0 ? fontSize1 : index === 1 ? fontSize2 : fontSize3} onChange={(e) => handleFontSizeChange(e, text._id, index)} />}

                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem className='d-flex justify-content-between' >
                                                        <label className='input_label'>Color</label>
                                                        {!editMode && <div style={{ maxWidth: '50%', backgroundColor: `${cartItems.find(y => y._id === text._id).fontColor}` }} className='form-control'></div>}
                                                        {editMode && <input type="color" className='form-control' style={{ maxWidth: '50%' }} value={index === 0 ? fontColor1 : index === 1 ? fontColor2 : fontColor3} onChange={(e) => handlefontColorChange(e.target.value, text._id, index)} />}
                                                    </ListItem>
                                                    <Divider />


                                                    <ListItem className='d-flex justify-content-between' >
                                                        <label className='input_label'>Font Style</label>
                                                        {!editMode && <div style={{ maxWidth: '50%' }} className='form-control'>{cartItems.find(y => y._id === text._id).fontFamily}</div>}
                                                        {editMode && <Select
                                                            labelId="font-select-label"
                                                            id={`font-select${text._id}`}
                                                            value={index === 0 ? fontFamily1 : index === 1 ? fontFamily2 : fontFamily3}
                                                            onChange={(e) => handlefontStyleChange(e.target.value, text._id, index)}
                                                            autoWidth
                                                            label="Font"
                                                            className='w-50'
                                                            size='large'
                                                            sx={{ fontSize: 16 }}
                                                        >
                                                            <MenuItem sx={{ fontSize: 16 }} value={'myCoverFont'}>myCoverFont</MenuItem>
                                                            <MenuItem sx={{ fontSize: 16 }} value={'Arial, sans-serif'}>Arial</MenuItem>
                                                            <MenuItem sx={{ fontSize: 16 }} value={`"Brush Script MT", cursive`}>Brush</MenuItem>
                                                            <MenuItem sx={{ fontSize: 16 }} value={`"Times New Roman", serif`}>Times New Roman</MenuItem>
                                                        </Select>}
                                                    </ListItem>
                                                    <Divider />

                                                </List>}
                                                <Divider />
                                            </ListItem>
                                            :
                                            <ListItem key={text._id} className='w-100'>
                                                {(product.pages && product.pages.length && !(match.params.pageNumber >= product.pages.length)) && <List className='w-100'>
                                                    <ListItem>
                                                        <h2 className='d-block'>{`Font ${index + 1}`}</h2>
                                                    </ListItem>
                                                    <Divider />
                                                    {(isMobile && isSafari) && <ListItem className='d-flex flex-column align-items-start' >
                                                        <label className='input_label mb-2'>Message</label>
                                                        <textarea rows="4" className='form-control mb-2' value={index === 0 ? (fontMessage1 === 'Enter Message' ? text.defaultText.replace('<div>', '\n').replace('</div>', '') : fontMessage1.replace('<div>', '\n').replace('</div>', '')) : index === 1 ? (fontMessage2 === 'Enter Message' ? text.defaultText.replace('<div>', '\n').replace('</div>', '') : fontMessage2.replace('<div>', '\n').replace('</div>', '')) : (fontMessage3 === 'Enter Message' ? text.defaultText.replace('<div>', '\n').replace('</div>', '') : fontMessage3.replace('<div>', '\n').replace('</div>', ''))} onChange={(e) => handlefontMessageChange(e, text._id, index)}> </textarea>
                                                    </ListItem>}

                                                    {(isMobile && isSafari) && <Divider />}
                                                    <ListItem className='d-flex justify-content-between' >
                                                        <label className='input_label'>Size</label>
                                                        {!editMode && <div style={{ maxWidth: '50%' }} className='form-control'>{text.fontSize}</div>}
                                                        {editMode && <input className='form-control' type="number" min={10} max={50} aria-label="mainFontSize" style={{ maxWidth: '50%' }} value={index === 0 ? fontSize1 : index === 1 ? fontSize2 : fontSize3} onChange={(e) => handleFontSizeChange(e, text._id, index)} />}
                                                    </ListItem>
                                                    <Divider />
                                                    <ListItem className='d-flex justify-content-between' >
                                                        <label className='input_label'>Color</label>
                                                        {!editMode && <div style={{ maxWidth: '50%', backgroundColor: `${text.fontColor}` }} className='form-control'></div>}
                                                        {editMode && <input type="color" className='form-control' style={{ maxWidth: '50%' }} value={index === 0 ? fontColor1 : index === 1 ? fontColor2 : fontColor3} onChange={(e) => handlefontColorChange(e.target.value, text._id, index)} />}
                                                    </ListItem>
                                                    <Divider />


                                                    <ListItem className='d-flex justify-content-between' >
                                                        <label className='input_label'>Font Style</label>
                                                        {!editMode && <div style={{ maxWidth: '50%' }} className='form-control'>{text.fontFamily}</div>}
                                                        {editMode && <Select
                                                            labelId="font-select-label"
                                                            id={`font-select${text._id}`}
                                                            value={index === 0 ? fontFamily1 : index === 1 ? fontFamily2 : fontFamily3}
                                                            onChange={(e) => handlefontStyleChange(e.target.value, text._id, index)}
                                                            autoWidth
                                                            label="Font"
                                                            className='w-50'
                                                            size='large'
                                                            sx={{ fontSize: 16 }}
                                                        >
                                                            <MenuItem sx={{ fontSize: 16 }} value={'myCoverFont'}>Alphakind</MenuItem>
                                                            <MenuItem sx={{ fontSize: 16 }} value={'Arial, sans-serif'}>Arial</MenuItem>
                                                            <MenuItem sx={{ fontSize: 16 }} value={`"Brush Script MT", cursive`}>Brush</MenuItem>
                                                            <MenuItem sx={{ fontSize: 16 }} value={`"Times New Roman", serif`}>Times New Roman</MenuItem>
                                                        </Select>}
                                                    </ListItem>
                                                </List>}
                                                <Divider />
                                            </ListItem>
                                    ))}

                                    <ListItem className="px-4 d-flex justify-content-between">
                                        {(match.params.pageNumber > 1) && <button className={`btn btn-primary ${(sender === 1 || product.sender === 1) && 'male'}`} onClick={() => handleSave('prev', false)}>Prev</button>}
                                        {(product.pages && product.pages.length && !(match.params.pageNumber >= product.pages.length - 1) && !(match.params.pageNumber <= 2)) && <div className='form-control w-auto'>{`Page ${match.params.pageNumber}`}</div>}
                                        {match.params.pageNumber === 2 && <div className='form-control w-auto'>Front Page</div>}
                                        {match.params.pageNumber === 1 && <div className='form-control w-auto'>Front Cover</div>}
                                        {(product.pages && product.pages.length && (parseInt(match.params.pageNumber) === product.pages.length - 1)) && <div className='form-control w-auto'>Back Page</div>}
                                        {(product.pages && product.pages.length && (parseInt(match.params.pageNumber) === product.pages.length)) && <div className='form-control w-auto'>Back Cover</div>}
                                        {(product.pages && product.pages.length && (parseInt(match.params.pageNumber) === product.pages.length)) && <button className={`btn btn-primary ${(sender === 1 || product.sender === 1) && 'male'}`} onClick={() => handleSave('next', true)}>Save and Preview</button>}
                                        {(product.pages && product.pages.length && !(match.params.pageNumber >= product.pages.length)) && <button className={`btn btn-primary ${(sender === 1 || product.sender === 1) && 'male'}`} onClick={() => handleSave('next', false)}>Next</button>}
                                    </ListItem>

                                </List>
                            </ListItem>
                        </List>
                        {/* <ReactToPrint
                                                trigger={() => <button>Print this out!</button>}
                                                content={() => printRef.current}
                                                
                                                
                                            /> */}


                    </div>



                </div>}

        </div>

    )
}

export default CoverDesignScreen

// && !(parseInt(match.params.pageNumber) === 2) && !(match.params.pageNumber >= product.pages.length - 1)