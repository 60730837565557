import React from 'react'
import { Pagination } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
function Paginate({pages, page, keyword=''}) {
    keyword=''
    if (keyword) {
        keyword = keyword.split('?keyword=')[1].split('&')[0]
    }

  return (pages>1 && (
      <Pagination >
          <LinkContainer to={`/products/?keyword=${keyword}&page=${page-1<=1?1:page-1}`}>
            <Pagination.Prev />
            </LinkContainer>
          {[...Array(pages).keys()].map((x)=>(
              <LinkContainer key={x} to={`/products/?keyword=${keyword}&page=${x+1}`}>
                  <Pagination.Item active={x+1===page}>{x+1}</Pagination.Item>
              </LinkContainer>
          )

          )}
            <LinkContainer to={`/products/?keyword=${keyword}&page=${page+1>=pages?pages:page+1}`}>
                <Pagination.Next />
            </LinkContainer>
      </Pagination>
      
  )
  )
}

export default Paginate