import React, {useState, useRef, useEffect} from 'react'
import Male from '../avatars/Male'
import Female from '../avatars/Female'
import Mother1 from '../avatars/Mother1'
import male from '../images/avatars/male.svg'
import Carousel from 'react-bootstrap/Carousel'
import female from '../images/avatars/female.svg'
import mother1 from '../images/avatars/mother1.svg'
import mother2 from '../images/avatars/mother2.svg'
import { Card } from 'react-bootstrap'
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import selector_female from '../images/avatars/selector_female.jpg'
import selector_male from '../images/avatars/selector_male.jpg'
import selector_mother1 from '../images/avatars/selector_mother1.jpg'
import selector_mother2 from '../images/avatars/selector_mother2.jpg'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listAvatars } from '../actions/productActions'

function AvatarDesignScreen() {
    const dispatch = useDispatch()

    const avatarList = useSelector(state=>state.avatarList)
    const {avatars, loading,error} = avatarList

    const history = useHistory()
    const [senderSkin, setSenderSkin] = useState('#FFDBAC')
    const [recipientSkin, setRecipientSkin] = useState('#FFDBAC')
    const [index, setIndex] = useState(0);
    const [open, setOpen] = useState(false)
    const [receiveIndex, setReceiveIndex] = useState(0);
    const sendRef = useRef()
    const receiveRef = useRef()
    
    const handleSelect = (selectedIndex, e) => {
      
      setIndex(selectedIndex);

      const elem = document.getElementsByClassName('carousel-item active sender')[0]
      
      localStorage.setItem('senderAvatar', elem.getAttribute('data-id'))
      

    };
    

    const handleReceiveSelect = (selectedReceiveIndex, e) => {
      setReceiveIndex(selectedReceiveIndex);
      const elem = document.getElementsByClassName('carousel-item active recipient')[0]
      
      
      localStorage.setItem('recipientAvatar', elem.getAttribute('data-id'))
    };

    const handleSenderClick = ()=>{
      const elem = document.getElementsByClassName('carousel-item active sender')[0]
      localStorage.setItem('senderAvatar', elem.getAttribute('data-id'))
      window.scrollTo(0, receiveRef.current.offsetTop)
      setOpen(true)
    }

    const handleRecipientClick = ()=>{
      const elem = document.getElementsByClassName('carousel-item active sender')[0]
      const relem = document.getElementsByClassName('carousel-item active recipient')[0]
      localStorage.setItem('senderAvatar', elem.getAttribute('data-id'))
      localStorage.setItem('recipientAvatar', relem.getAttribute('data-id'))
      setOpen(true)
      if(localStorage.getItem('senderAvatar')){
        history.push(`/avatarproducts`)
      }
    }

    useEffect(() => {
      window.scrollTo(0,0)

      dispatch(listAvatars())

      
    
      
    }, [dispatch])
    

  return (
    <div className="w-100">
    <section ref={sendRef} id="sender_avatar" className="w-100 d-flex align-items-center justify-content-center flex-column" >
      

      <h2 className="text-center py-4">Choose Sender Avatar</h2>
      
      
    
      <Carousel indicators={false} className="avatar_container shadow shadow" interval={null} variant='dark' activeIndex={index} onSelect={handleSelect}>
      {loading ? <Loader width={80} />
                : error ? <Message width={80} variant='error'>{error}</Message>
                    :avatars.filter(x=>x.canSend).map(avatar => (
                      <Carousel.Item data-id={avatar._id} className='sender' key={avatar._id} >
                      <img
                        className="d-block w-100 avatar_default"
                        src={avatar.image}
                        alt={avatar.name}
                      />
                    </Carousel.Item>
                
            ))}
      
      

      
    </Carousel>
    <div className='avatar_selectors'>
        
        

        {avatars.filter(x=>x.canSend).map((avatar, ind) => (
        <button key={avatar._id} onClick={(e)=>handleSelect(ind,e)} className={`selector_btn ${index===ind && 'selected'}`}>
            <Avatar className={`avatar ${(avatar.name.toLowerCase().includes('male')&& !avatar.name.toLowerCase().includes('female'))?'male':'female'}`}  alt={avatar.name} src={avatar.image} />
        </button>
      ))}
    </div>
      
  
    <Button  className='btn btn-primary' disableElevation onClick={()=>handleSenderClick()} size="medium" sx={{marginTop: '2rem', textTransform:'none'}} variant="contained" endIcon={<ArrowForwardIcon />}>
        Save and Next
    </Button>

    <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={()=>setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
        sx={{width:'50%'}}
        color='#a555bf'

      >
        <Alert variant='filled' sx={{width:'100%', textAlign:'center'}}  severity="success">
          Avatar Saved
        </Alert>
      </Snackbar>
        
        
      
    </section>

    <section ref={receiveRef} id="recipient_avatar" className=" w-100 d-flex align-items-center justify-content-center flex-column" >
      

      <h2 className="text-center py-4">Choose Recipient Avatar</h2>
      
      
    
      <Carousel indicators={false} className="avatar_container shadow" interval={null} variant='dark' activeIndex={receiveIndex} onSelect={handleReceiveSelect}>
      {loading ? <Loader width={80} />
                : error ? <Message width={80} variant='error'>{error}</Message>
                    :avatars.filter(x=>x.canReceive).map(avatar => (
                      <Carousel.Item data-id={avatar._id} className='recipient' key={avatar._id} >
                      <img
                        className="d-block w-100 avatar_default"
                        src={avatar.image}
                        alt={avatar.name}
                      />
                    </Carousel.Item>
                
            ))}

      
    </Carousel>
    <div className='avatar_selectors'>
      {avatars.filter(x=>x.canReceive).map((avatar, index) => (
        <button key={avatar._id} onClick={(e)=>handleReceiveSelect(index,e)} className={`selector_btn ${receiveIndex===index && 'selected'}`}>
            <Avatar className={`avatar ${(avatar.name.toLowerCase().includes('male')&& !avatar.name.toLowerCase().includes('female'))?'male':'female'}`}  alt={avatar.name} src={avatar.image} />
        </button>
      ))}
        
        {/* <button onClick={(e)=>handleReceiveSelect(0,e)} className={`selector_btn male ${receiveIndex===0 && 'selected'}`}>
          <Avatar className='avatar female'  alt="selector_mother1" src={selector_mother1} />
        </button>
        <button onClick={(e)=>handleReceiveSelect(1,e)} className={`selector_btn ${receiveIndex===1 && 'selected'}`}>
          <Avatar className='avatar female'  alt="selector_mother2" src={selector_mother2} />
        </button> */}
    </div>
    <div className="avatar_two_button_wrapper d-flex flex-row justify-content-around">
      <Button className='btn btn-primary'  onClick={()=>window.scrollTo(0,0)} size="medium" sx={{marginTop: '2rem', textTransform:'none'}} variant="contained" startIcon={<ArrowBackIcon />}>
          Back to Choose Sender
      </Button>

      <Button className='btn btn-primary'  onClick={()=>handleRecipientClick()} size="medium" sx={{marginTop: '2rem', textTransform:'none'}} variant="contained" endIcon={<ArrowForwardIcon />}>
          Save and Continue
      </Button>
    </div>
    </section>
    
    

    </div>
  )
}

export default AvatarDesignScreen
