import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { previewPageDetails } from '../actions/productActions';
import PreviewFlip from '../components/PreviewFlip';
import { Container, Row, Col, ListGroup } from 'react-bootstrap';
import { CART_CLEAR_SHIPPING_ADDRESS } from '../constants/cartConstants'
import { createOrder } from '../actions/orderActions';
import { ORDER_CREATE_RESET } from '../constants/orderConstants'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import TuneIcon from '@mui/icons-material/Tune';
import Avatar from '@mui/material/Avatar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { geocodeByAddress } from 'react-places-autocomplete';
import { previewGiftDetails } from '../actions/giftActions'



function PlaceOrderScreen({ match, history }) {
  const [index, setIndex] = useState(0);
  const previewLoaded = useRef(false)
  const tracking_id = localStorage.getItem('tracking_id')

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const dispatch = useDispatch()
  const [orderComments, setOrderComments] = useState('')
  const [addressComponents, setAddressComponents] = useState([])
  const [customizationFee, setCustomizationFee] = useState(0)



  const productDetails = useSelector(state => state.productDetails)
  const { loading, error, product } = productDetails

  const cart = useSelector(state => state.cart)
  const { cartItems } = cart

  const shippingDetails = useSelector(state => state.shippingDetails)
  const { shippingInfo } = shippingDetails
  const [to_collect, setToCollect] = useState(shippingInfo.to_collect);

  const gift_cart = useSelector(state => state.gift_cart)
  const { giftCartItems } = gift_cart

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const orderCreate = useSelector(state => state.orderCreate)
  const { order, loading: loadingCreate, error: errorCreate, success } = orderCreate


  const locationHandler = () => {
    dispatch({ type: CART_CLEAR_SHIPPING_ADDRESS })
    history.push(`/shipping/${match.params.id}/?redirect=placeorder/${match.params.id}`)

  }

  const [paymentMethod, setPaymentMethod] = useState("Cash on Delivery")

  cart.itemsPrice = 2500
  cart.shippingPrice = (Math.round(Math.max(...cart.cartItems.map((a) => a.distance)) * 15)).toFixed(2)
  cart.totalPrice = (Number(cart.itemsPrice) + Number(cart.shippingPrice)).toFixed(2)


  const preview = () => {
    console.log('previewed')
    var pages = document.getElementsByClassName('flip_page');
    for (var i = 0; i < pages.length; i++) {
      var page = pages[i];
      if (i % 2 === 0) {
        page.style.zIndex = (pages.length - i);
      }
    }


    for (var x = 0; x < pages.length; x++) {
      //Or var page = pages[x];
      pages[x].pageNum = x + 1;
      pages[x].onclick = function () {
        if (this.pageNum % 2 === 0) {
          this.classList.remove('flipped');
          this.previousElementSibling.classList.remove('flipped');
        }
        else {
          this.classList.add('flipped');
          this.nextElementSibling.classList.add('flipped');
        }
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    console.log('0', previewLoaded)
    console.log('0', !previewLoaded.current)
    window.scrollTo(0, 0)

    if (product.pages && previewLoaded.current) {
      if (product._id == match.params.id && previewLoaded.current) {
        console.log(product._id, match.params.id)
        preview()
      } else {
        if (tracking_id) {
          dispatch(previewPageDetails(match.params.id, tracking_id))
          previewLoaded.current = true
        } else {
          dispatch(previewPageDetails(match.params.id))
          previewLoaded.current = true
        }
      }

    } else {
      if (tracking_id) {
        dispatch(previewPageDetails(match.params.id, tracking_id))
        previewLoaded.current = true
      } else if (giftCartItems._id) {
        console.log('gift cart items found', gift_cart.giftCartItems)
        dispatch(previewPageDetails(gift_cart.giftCartItems._id))
        previewLoaded.current = true
        // cart.totalPrice = (Number(product.price * (giftCartItems.quantity ? giftCartItems.quantity : 1)) + Number(cart.shippingPrice)).toFixed(2)
      }
      else {
        dispatch(previewPageDetails(match.params.id))
        previewLoaded.current = true
      }
    }
    console.log(to_collect)
    console.log(to_collect)
    // if (cart.shippingAddress.to_collect){
    //   return
    // }
    // else{
    //   if(!cart.shippingAddress.latitude || !cart.shippingAddress.longitude || !cart.shippingAddress.address){
    //     history.push(`/shipping/${match.params.id}/?redirect=placeorder/${match.params.id}`)
    //     console.log("no address",cart)
    //   } 
    //   else {
    //     geocodeByAddress(cart.shippingAddress.address).then(results =>  setAddressComponents(results[0].address_components))
    //     console.log("else",cart)
    //   }
    // }

    if (success) {
      history.push(`/order/${order._id}`)
      dispatch({ type: ORDER_CREATE_RESET })
    }
    console.log("cart items", cartItems)
    if (cart.cartItems.length === 0 && !gift_cart.giftCartItems) {
      history.push('/products')
    }

    if (!userInfo) {
      history.push(`/login?redirect=placeorder/${match.params.id}`)
    } else {
      localStorage.removeItem('redirect')
    }

    if (gift_cart.giftCartItems.personalise) {
      // if (gift_cart.giftCartItems.gift_option.customizable && gift_cart.giftCartItems.personalise) {
      // if (gift_cart.giftCartItems.personalise) {
        setCustomizationFee(300)
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, match, userInfo, success, cart, gift_cart, customizationFee, history, product.pages, product.gift_type, to_collect])



  const pages = [...new Set(cartItems.filter(x => parseInt(x.productId) === parseInt(match.params.id)).map(item => item.pageNumber))].sort((a, b) => a - b)




  const submitHandler = (e) => { e.preventDefault() }

  const placeOrder = () => {
    if (product.gift_type) {
      dispatch(createOrder({
        orderItems: gift_cart.giftCartItems,
        shippingAddress: to_collect ? "Customer to collect" : cart.shippingAddress,
        paymentMethod: paymentMethod,
        shippingPrice: to_collect ? 0 : addressComponents.find(x => x.long_name.includes('Nairobi')) ? 300 : 400,
        customizationPrice: customizationFee,
        itemsPrice: product.price || 0,
        totalPrice: to_collect ? parseInt(product.price * (gift_cart.giftCartItems.quantity ? giftCartItems.quantity : 1)) + customizationFee : addressComponents.find(x => x.long_name.includes('Nairobi')) ? 300 + parseInt(product.price * (gift_cart.giftCartItems.quantity ? giftCartItems.quantity : 1)) + customizationFee : 400 + parseInt(product.price * (gift_cart.giftCartItems.quantity ? giftCartItems.quantity : 1) + customizationFee),
        productId: match.params.id,
      }))
    } else {
      dispatch(createOrder({
        orderItems: cart.cartItems.filter(x => parseInt(x.productId) === parseInt(match.params.id)),
        shippingAddress: to_collect ? "Customer to collect" : cart.shippingAddress,
        paymentMethod: paymentMethod,
        shippingPrice: to_collect ? 0 : addressComponents.find(x => x.long_name.includes('Nairobi')) ? 300 : 400,
        itemsPrice: product.price || 0,
        totalPrice: to_collect ? parseInt(product.price) : addressComponents.find(x => x.long_name.includes('Nairobi')) ? 300 + parseInt(product.price) : 400 + parseInt(product.price),
        productId: match.params.id,
        pages: pages.length || 0,
        tracking_id: tracking_id
      }))
    }
  }


  return (
    <Container fluid className='nav_margin py-3 align-center'>





      {/* <div>
          <div style={{overflow:'hidden', width:0, height:0}}>
            <div className='print_container' ref={printRef}>
            {loading? <Loader width={80} />: error? <Message width={80} variant='error'>{error}</Message>:
              pages.map(page=>(
                <Carousel.Item key={page}>
                  <div className="d-block w-100">
                    <PreviewPage  background={product.pages && product.pages.find(x=> x.pageNumber===parseInt(page)).image} texts={cartItems.filter(x=>parseInt(x.productId) ===parseInt(match.params.id) && parseInt(x.pageNumber)===parseInt(page))} />
                  </div>
                </Carousel.Item>
              )
              )
          }
            </div>,
              
          </div>
        </div> */}

      <Row className='align-items-center'>
        {cartItems.length>0 &&
          <Col xl={8} className='mb-4'>

            <div className='flip_body'>
              <div className="flip_book">
                <div id="flip_pages" className="flip_pages">
                  &nbsp;&nbsp; Click on Book to Flip
                  {
                    loading ? <Loader width={80} />
                      : error ? <Message width={80} variant='error'>{error}</Message>
                        : (product.pages && product._id == match.params.id) && pages.map(page => (


                          <PreviewFlip key={page} background={product.pages && product.pages.find(x => x.pageNumber === parseInt(page)).background_image} foreground={product.pages && product.pages.find(x => x.pageNumber === parseInt(page)).foreground_image} texts={cartItems.filter(x => parseInt(x.productId) === parseInt(match.params.id) && parseInt(x.pageNumber) === parseInt(page))} />


                        )

                        )
                  }
                </div>
              </div>
            </div>
          </Col>
        }

        {giftCartItems && cartItems.length == 0 &&
          <Col xl={8} className='align-items-center mb-4 h-100'>

            <Row className='align-items-center'>

              <Col xl={4} className='mb-2'>

                <div className='d-flex justify-content-center'>
                  <img width='200px' height={'300px'} src={giftCartItems.gift_option ? giftCartItems.gift_option.image : product.image} alt='gift_image' />
                </div>

              </Col>

              <Col xl={8} className='mb-2'>
                <ListGroup className='w-100'>
                  <ListGroup.Item><h2>Customization Details</h2></ListGroup.Item>

                  <ListGroup.Item className='p-0'>
                    <ListGroup variant='flush'>

                      <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                        <label className='list_label d-flex'>
                          {/* <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                            <ShoppingBasketIcon />
                          </Avatar>&nbsp;&nbsp; */}
                          Colour :
                        </label>
                        <div className='list_value'>{giftCartItems.gift_option ? giftCartItems.gift_option.colour_name : 'none selected'}</div>
                      </ListGroup.Item>

                      <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                        <label className='list_label d-flex'>
                          {/* <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                            <ShoppingBasketIcon />
                          </Avatar>&nbsp;&nbsp; */}
                          Images:
                        </label>
                        <div className='d-flex flex-row w-full overflow-x-scroll'>
                          {giftCartItems.upload_images.map((option, index) => {
                            return <div className='m-2' key={index} style={{ position: 'relative' }}>
                              <img width='100px' src={option.encoded_image} alt='upload' />
                              <span style={{ position: "absolute", zIndex: 100, right: 0, top: -8, borderColor: "#f5007e", backgroundColor: "#ec2276" }} className='text-white rounded-circle fs-6' ></span>
                            </div>
                          })}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                        <label className='list_label d-flex'>
                          {/* <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                            <ShoppingBasketIcon />
                          </Avatar>&nbsp;&nbsp; */}
                          Order Notes :
                        </label>
                        <div className='list_value'>{giftCartItems.order_note}</div>
                      </ListGroup.Item>



                    </ListGroup>
                  </ListGroup.Item>

                </ListGroup>

              </Col>

            </Row>
          </Col>
        }

        <Col xl={4} className='buttons d-flex flex-column'>
          <ListGroup className='w-100'>
            <ListGroup.Item><h2>Order Details</h2></ListGroup.Item>

            <ListGroup.Item className='p-0'>
              <ListGroup variant='flush'>
                <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <ShoppingBasketIcon />
                    </Avatar>&nbsp;&nbsp;Product :
                  </label>
                  <div className='list_value'>{product.name}</div>
                </ListGroup.Item>

                {pages.length ? <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <AutoStoriesIcon />
                    </Avatar>&nbsp;&nbsp;Total Pages :
                  </label>
                  <div className='list_value'>{pages.length}</div>
                </ListGroup.Item>

                  : gift_cart && <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                    <label className='list_label d-flex'>
                      <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                        <AutoStoriesIcon />
                      </Avatar>&nbsp;&nbsp;Quantity :
                    </label>
                    <div className='list_value'>{gift_cart.giftCartItems.quantity ? giftCartItems.quantity : 1}</div>
                  </ListGroup.Item>
                }

                <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <AttachMoneyIcon />
                    </Avatar>&nbsp;&nbsp;Price :</label>
                  <div className='list_value'>Kshs. {product.price}</div>
                </ListGroup.Item>

                <ListGroup.Item className='d-flex align-items-center flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <DeliveryDiningIcon />
                    </Avatar>&nbsp;&nbsp;Location :
                  </label>
                  <div className='list_value text-end'>
                    {
                      to_collect ? "Nairobi CBD"
                        : (cart.shippingAddress.addres ? cart.shippingAddress.address.substring(0, 25) + '...' : "No address was provided")
                    }
                    <button onClick={() => locationHandler()} className='btn btn-primary list_value'>Change</button>
                  </div>

                </ListGroup.Item>

                <ListGroup.Item className='d-flex align-items-center flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <TuneIcon />
                    </Avatar>&nbsp;&nbsp;Customization Fee :
                  </label>
                  <div className='list_value'>Kshs. {customizationFee}</div>

                </ListGroup.Item>

                <ListGroup.Item className='d-flex align-items-center flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <DeliveryDiningIcon />
                    </Avatar>&nbsp;&nbsp;Delivery Fee :
                  </label>
                  {to_collect ? <div className='list_value'>Kshs. 0</div>
                    : <div className='list_value'>Kshs. {addressComponents.find(x => x.long_name.includes('Nairobi')) ? 300 : 400}</div>}

                </ListGroup.Item>

                <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <AttachMoneyIcon />
                    </Avatar>&nbsp;&nbsp;Total Price :</label>
                  <div className='list_value'>Kshs. {to_collect ? parseInt(product.price * (gift_cart.giftCartItems.quantity ? giftCartItems.quantity : 1)) + customizationFee : addressComponents.find(x => x.long_name.includes('Nairobi')) ? 300 + parseInt(product.price * (gift_cart.giftCartItems.quantity ? giftCartItems.quantity : 1)) + customizationFee : 400 + parseInt(product.price * (gift_cart.giftCartItems.quantity ? giftCartItems.quantity : 1) + customizationFee)}</div>
                </ListGroup.Item>

                {product.gift_type ? <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                  <button onClick={() => history.push(`/gift/${product.gift_type.name}/${product._id}`)} className={`btn btn-primary`}>Edit Gift</button>
                  <button onClick={() => placeOrder()} className=' btn btn-primary'>Place Order</button>

                </ListGroup.Item>
                  : <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                    <button onClick={() => history.push(`/preview/${match.params.id}/edit`)} className=' btn btn-primary'>Edit Book</button>
                    <button onClick={() => placeOrder()} className=' btn btn-primary'>Place Order</button>
                  </ListGroup.Item>
                }
                {/* <ListGroup.Item className='d-flex align-items-center flex-row justify-content-between py-2'>
                    <Message width={100} variant='info'><a style={{color:'#a555bf', fontSize:'14px', textTransform:'uppercase', fontWeight:500}} target='_blank' rel='noreferrer' href='https://instagram.com/tendajicreations?igshid=YmMyMTA2M2Y='>Add a Custom Made Blanket to your order!</a></Message>
                    
                  </ListGroup.Item> */}
                {(errorCreate || loadingCreate) && <ListGroup.Item>
                  {errorCreate && <Message variant='error'>{errorCreate}</Message>}
                  {loadingCreate && <Loader width={100} />}
                </ListGroup.Item>}
              </ListGroup>
            </ListGroup.Item>
          </ListGroup>
          {/* <button onClick={()=>history.push(`/product/${match.params.id}/page/${index+1}?redirect=preview`)}  className=' btn btn-primary'>Edit This Page</button>
                <button  className=' btn btn-primary'>Proceed to Checkout</button> */}
        </Col>

      </Row>
    </Container>
  )
}

export default PlaceOrderScreen
