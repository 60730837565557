export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'

export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'


export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'


export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'

export const USER_VERIFY_REQUEST = 'USER_VERIFY_REQUEST'
export const USER_VERIFY_SUCCESS = 'USER_VERIFY_SUCCESS'
export const USER_VERIFY_FAIL = 'USER_VERIFY_FAIL'
export const USER_VERIFY_RESET = 'USER_VERIFY_RESET'

export const USER_EMAIL_REQUEST = 'USER_EMAIL_REQUEST'
export const USER_EMAIL_SUCCESS = 'USER_EMAIL_SUCCESS'
export const USER_EMAIL_FAIL = 'USER_EMAIL_FAIL'
export const USER_EMAIL_RESET= 'USER_EMAIL_RESET'

export const USER_RESET_EMAIL_REQUEST = 'USER_RESET_EMAIL_REQUEST'
export const USER_RESET_EMAIL_SUCCESS = 'USER_RESET_EMAIL_SUCCESS'
export const USER_RESET_EMAIL_FAIL = 'USER_RESET_EMAIL_FAIL'
export const USER_RESET_EMAIL_RESET = 'USER_RESET_EMAIL_RESET'

export const USER_RESET_PASSWORD_REQUEST = 'USER_RESET_PASSWORD_REQUEST'
export const USER_RESET_PASSWORD_SUCCESS = 'USER_RESET_PASSWORD_SUCCESS'
export const USER_RESET_PASSWORD_FAIL = 'USER_RESET_PASSWORD_FAIL'
export const USER_RESET_PASSWORD_RESET = 'USER_RESET_PASSWORD_RESET'

export const USER_SUBSCRIBE_REQUEST = 'USER_SUBSCRIBE_REQUEST'
export const USER_SUBSCRIBE_SUCCESS = 'USER_SUBSCRIBE_SUCCESS'
export const USER_SUBSCRIBE_FAIL = 'USER_SUBSCRIBE_FAIL'
export const USER_SUBSCRIBE_RESET= 'USER_SUBSCRIBE_RESET'


export const FAQ_REQUEST = 'FAQ_REQUEST'
export const FAQ_SUCCESS = 'FAQ_SUCCESS'
export const FAQ_FAIL = 'FAQ_FAIL'