import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Container, Col } from 'react-bootstrap';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import { listGifts } from '../actions/giftActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import Gift from '../components/Gift'
import { useHistory } from 'react-router-dom'


function GiftScreen() {
    
    const dispatch = useDispatch()
    const history = useHistory()

    const giftList = useSelector(state => state.giftList)

    // const gifts = [
    //     {
    //         "name":"Mug 1",
    //         "image":"https://instagram.fnuu2-1.fna.fbcdn.net/v/t39.30808-6/347553465_17945297003656519_6812406420027172191_n.jpg?stp=dst-jpg_e15&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fnuu2-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=ZEWe-4iy6EwAX-jfUMk&edm=ACWDqb8AAAAA&ccb=7-5&ig_cache_key=MzE0NDUwNjEyMTQ1OTU4ODcyMA%3D%3D.2-ccb7-5&oh=00_AfAiiJ4tuj9w-PtLQR3EjP7oJ7IYKKhWLApaOF6PiN2rcQ&oe=653CE035&_nc_sid=ee9879",
    //         "price":"1900.00",
    //         "description":"Looking for the perfect birthday gift for a friend? Customize a unique water bottle for them"
    //     },
    //     {
    //         "name":"Mug 2",
    //         "image":"https://instagram.fnuu2-1.fna.fbcdn.net/v/t39.30808-6/371786472_17952459494656519_579408049771717482_n.jpg?stp=dst-jpg_e15&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE1OTEuc2RyIn0&_nc_ht=instagram.fnuu2-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=4jTjfQW_5csAX9yNYaZ&edm=ABmJApAAAAAA&ccb=7-5&ig_cache_key=MzE3OTQwMDczMzA2ODAzMzc5OA%3D%3D.2-ccb7-5&oh=00_AfAFw9JeiSfhAfq76ccfTu5N1iRg5l2TW0OvCceh_tV75g&oe=653C3162&_nc_sid=b41fef",
    //         "price":"1900.00",
    //         "description":"Looking for the perfect birthday gift for a friend? Customize a unique water bottle for them"
    //     },
    //     {
    //         "name":"Mug 3",
    //         "image":"https://instagram.fnuu2-1.fna.fbcdn.net/v/t39.30808-6/371786472_17952459494656519_579408049771717482_n.jpg?stp=dst-jpg_e15&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE1OTEuc2RyIn0&_nc_ht=instagram.fnuu2-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=4jTjfQW_5csAX9yNYaZ&edm=ABmJApAAAAAA&ccb=7-5&ig_cache_key=MzE3OTQwMDczMzA2ODAzMzc5OA%3D%3D.2-ccb7-5&oh=00_AfAFw9JeiSfhAfq76ccfTu5N1iRg5l2TW0OvCceh_tV75g&oe=653C3162&_nc_sid=b41fef",
    //         "price":"1900.00",
    //         "description":"Looking for the perfect birthday gift for a friend? Customize a unique water bottle for them"
    //     },
    //     {
    //         "name":"Water Bottles",
    //         "image":"https://instagram.fnuu2-1.fna.fbcdn.net/v/t39.30808-6/347553465_17945297003656519_6812406420027172191_n.jpg?stp=dst-jpg_e15&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xNDQweDE4MDAuc2RyIn0&_nc_ht=instagram.fnuu2-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=ZEWe-4iy6EwAX-jfUMk&edm=ACWDqb8AAAAA&ccb=7-5&ig_cache_key=MzE0NDUwNjEyMTQ1OTU4ODcyMA%3D%3D.2-ccb7-5&oh=00_AfAiiJ4tuj9w-PtLQR3EjP7oJ7IYKKhWLApaOF6PiN2rcQ&oe=653CE035&_nc_sid=ee9879",
    //         "price":"1900.00",
    //         "description":"Looking for the perfect birthday gift for a friend? Customize a unique water bottle for them"
    //     }
    // ]

    const { error, loading, gifts, giftTypes, page, pages } = giftList

    var [value, setValue] = React.useState('0');
    var [giftName, setGiftName] = React.useState('all');
    
    let keyword = history.location.search

    const handleChange = (event, newValue, keyword) => {
        setValue(newValue);
        
        var selected_gift = event.target.firstChild.data
        setGiftName(selected_gift);

        history.push(`/gifts/${selected_gift}/?keyword=${newValue}&page=1`)
    };

    

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(listGifts(keyword))


    }, [dispatch, keyword])

    return(
        <Container className="products_screen nav_margin">
            <h4>Customizable Gifts</h4>

            <Row>
                <Col>

                    <div className='d-flex flex-row card px-1 py-2'>
                        {loading ? <Loader width={80} />
                            : error ? <Message width={80} variant='error'>{error}</Message>
                            : gifts ?
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    variant="scrollable"
                                    aria-label="scrollable prevent tabs example"
                                    allowScrollButtonsMobile
                                // scrollButtons={false}
                                >

                                        <Tab value="0" label="all" to={`/gifts/all`} />

                                    {gifts.map(gift => (
                                        <Tab value={gift._id} label={gift.name} key={gift._id} to={`/gifts/${gift.name}/?keyword=${gift._id}&page=1`}/>
                                    ))}

                                </Tabs>
                                
                                : <Message width={80} variant='error'>An error occured</Message>
                        }
                        
                    </div>

                </Col>
                
            </Row>

            <Row>
                {/* {(keyword && keyword.split('?keyword=')[1].split('&page=1')[0] !== '') && <h2>Results for {keyword.split('?keyword=')[1].split('&')[0].replaceAll('%20', ' ')}</h2>} */}
                {loading ? <Loader width={80} />
                    : error ? <Message width={80} variant='error'>{error}</Message>
                    : giftTypes.map(giftType => (
                        <Col className="px-3 py-4" sm={12} md={6} lg={4} xl={3} key={giftType._id}>
                            <Gift className='stretch' product={giftType} />
                        </Col>  

                    ))
                }
                {(!loading && !error && giftTypes.length === 0) && <Message className="px-3 py-4" sm={12} md={6} lg={4} xl={3} variant='info'>No products found</Message>}
            </Row>

            {(!loading && !error) && <Paginate page={page} pages={pages} keyword={keyword} />}

        </Container>
    )
}

export default GiftScreen;