import React from 'react'
import { Spinner } from 'react-bootstrap'

function LoaderButton() {
    return (
        <Spinner
            animation='border'
            role='status'
        
        >
            <span className='sr-only'></span>
        </Spinner>
    )
}

export default LoaderButton