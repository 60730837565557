import React, {useState, useEffect} from 'react'
import FormContainer from '../components/FormContainer'
import { Form } from 'react-bootstrap'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux'
import { sendResetEmail } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TextField from '@mui/material/TextField';

import SubjectIcon from '@mui/icons-material/Subject';

function ResetEmailScreen() {
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)

    const dispatch = useDispatch()

    const userSendReset = useSelector(state=>state.userSendReset)
    const {error, loading, success, userMessage} = userSendReset

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(sendResetEmail({email}))

    }

    const validateEmail = () => {
        var re = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
        
        
        setEmailError(!re.test(email))
    }

    const handleEmailChange = e => {
        setEmail( e.target.value)
        
        validateEmail()
    
    };

    useEffect(() => {
        window.scrollTo(0,0)
      if(success){
          setEmail('')
          setEmailError(false)
        }
    }, [success, dispatch])
    
  return (
    <FormContainer classes={'nav_margin py-4'}>
        <h2>Contact Us</h2>
            {error && <Message width={100} variant='error'>{error}</Message>}
            {loading && <Loader width={100} />}
            {success && <Message width={100} variant='success'>{userMessage}</Message>}
            <Form className='mb-4' onSubmit={submitHandler}>


                <FormControl sx={{my:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="email">
                        Your Email Address
                    </InputLabel>
                    <Input
                        required
                        placeholder="Enter Email Address"
                        id="customerEmail"
                        type="email"
                        startAdornment={
                            <InputAdornment position="start">
                                <EmailOutlinedIcon />
                            </InputAdornment>
                        }
                        value={email}
                        onChange={handleEmailChange}
                        error ={emailError}
                        color="success"
                    />
                    
                </FormControl>


                

                

                <button color="success" type='submit' className='w-100 btn btn-primary' >
                    Send <SendOutlinedIcon />
                </button>
            </Form>

            

            


    </FormContainer>
  )
}

export default ResetEmailScreen