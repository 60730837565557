import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Container, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { listMyOrders } from '../actions/orderActions'
import { Button } from '@mui/material'
import LoaderButton from '../components/LoaderButton'
import { FaTimes } from 'react-icons/fa'







function ProfileScreen({ history }) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')


    

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success, error: errorUpdate, loading: loadingUpdate } = userUpdateProfile

    const orderListMy = useSelector(state => state.orderListMy)
    const { loading: loadingOrders, error: errorOrders, orders } = orderListMy


    useEffect(() => {
        window.scrollTo(0,0)
        if (!userInfo) {
            history.push('/login?redirect=/profile')
        } else {
            if (!user || !user.name || success || userInfo._id !== user._id) {
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails('profile'))
                dispatch(listMyOrders())
            } else {
                setName(user.name)
                setEmail(user.email)
                setPhoneNumber(user.phoneNumber)
            }
        }
    }, [dispatch, history, userInfo, user, success, error])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'email': email,
                'password': password,
                'phoneNumber': phoneNumber,
            }))
            setMessage('')
        }

    }
    return (
        <Container className='nav_margin py-5'>
        <Row >
            <Col md={3}>
                <h2>User Profile</h2>

                {message && <Message variant='error'>{message}</Message>}
                {error && <Message variant='error'>{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>

                    <Form.Group className='py-1 pb-4' controlId='name'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            placeholder='Enter name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className='py-1 pb-4' controlId='email'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            required
                            type='email'
                            placeholder='Enter Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className='py-1 pb-4' controlId='phoneNumber'>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            required
                            type="tel"
                            placeholder='Enter Phone Number'
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    

                    <Form.Group className='py-1 pb-4' controlId='password'>
                        <Form.Label>Password</Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Enter Password'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group className='py-1 pb-2' controlId='passwordConfirm'>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Confirm Password'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    {errorUpdate && <div className="my-2"><Message  variant='error'>{errorUpdate}</Message></div>}

                    <Button className='mt-4' type='submit' color="success" variant='contained' fullWidth>
                        {loadingUpdate?<LoaderButton/>:"Update"}
                    </Button>

                </Form>
            </Col>

            <Col md={9}>
                <h2>My Orders</h2>
                {loadingOrders ? (
                    <Loader />
                ) : errorOrders ? (
                    <Message variant='error'>{errorOrders}</Message>
                ) : (
                            <Table bordered striped responsive className='table-sm'>
                                <thead>
                                    <tr>
                                        <th className="text-center">ID</th>
                                        <th>Date</th>
                                        <th className="text-center">Total </th>
                                        <th className="text-center">Paid</th>
                                        <th className="text-center">Delivered</th>
                                        
                                        <th  className="text-center">Details</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {orders.map(order => (
                                        <tr key={order._id}>
                                            <td className="text-center">{order._id}</td>
                                            <td>{order.createdAt.substring(0, 10)}</td>
                                            <td className="text-center">Kshs. {order.totalPrice}</td>
                                            <td className="text-center">{order.isPaid ? order.paidAt.substring(0, 10) : (
                                                <FaTimes style={{color:'red'}} />
                                            )}</td>
                                            <td className="text-center">{order.isDelivered ? order.deliveredAt.substring(0, 10) : (
                                                <FaTimes style={{color:'red'}} />
                                            )}</td>
                                            
                                            <td className="text-center">
                                            
                                                <LinkContainer to={`/order/${order._id}`}>
                                                    <Button color='success' size="small" className='btn-sm'>Click To View</Button>
                                                </LinkContainer>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
            </Col>
        </Row>
    </Container>
    )
}

export default ProfileScreen