import React, {useState, useEffect} from 'react';
import { NavLink as Link} from 'react-router-dom'
import logo2 from '../logo2.png';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { LinkContainer } from 'react-router-bootstrap'
import { logout } from '../actions/userActions'
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchBox from './SearchBox';
import { listOcassions } from '../actions/productActions';
import { useDispatch, useSelector } from 'react-redux';
import CelebrationIcon from '@mui/icons-material/Celebration';
function MyNavbar() {
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    


    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const ocassionList = useSelector(state=>state.ocassionList)
    const {ocassions} = ocassionList

    const logoutHandler = () =>{
        setOpen(false)
        dispatch(logout())
    }

    useEffect(() => {
        if(ocassions.length===0){
            dispatch(listOcassions())
        }
    }, [ocassions.length, dispatch])
    
    return (
    <Navbar className="shadow" expanded={open} fixed="top" collapseOnSelect expand="xxl" bg="light" variant="light">
            <Container className="px-5 mw-100">
                <Link onClick={()=>setOpen(false)}  to='/'>
                    <Navbar.Brand>
                        <img className="nav__logo--img" src={logo2} alt="logo" />
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle onClick={()=>setOpen(!open)} aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <div className="me-auto"></div>
                    <Nav className="me-auto">
                        <Link onClick={()=>setOpen(false)} exact className="nav-link" activeClassName="selected"  to='/'><HomeRoundedIcon color='#f10887' /><div className='px-2'>Home</div></Link>
                        <Link onClick={()=>setOpen(false)} exact className="nav-link" activeClassName="selected" to='/products'><MenuBookRoundedIcon/><div className='px-2'>Books</div></Link>
                        <Link onClick={() => setOpen(false)} exact className="nav-link" activeClassName="selected" to='/gifts/all'><CardGiftcardIcon/><div className='px-2'>Gifts</div></Link>
                        <Link onClick={()=>setOpen(false)} exact className="nav-link" activeClassName="selected" to='/avatar'><GroupRoundedIcon/><div className='px-2'>Avatars</div></Link>
                        <Link onClick={()=>setOpen(false)} exact className="nav-link" activeClassName="selected" to='/contact'><CallRoundedIcon/><div className='px-2'>Contact</div></Link>
                        <NavDropdown title={<div><CelebrationIcon className='me-2'/>Occasions</div>} id="ocassions-dropdown">
                            {ocassions.map(ocassion=>(
                                <LinkContainer key={ocassion._id} to={`/products/?keyword=${ocassion.name}&page=1`}>
                                    <NavDropdown.Item onClick={()=>setOpen(false)}>{ocassion.name}</NavDropdown.Item>
                                </LinkContainer>
                            ))}
                            
                            

                        </NavDropdown>
                        {!userInfo && <Link onClick={()=>setOpen(false)} activeClassName="selected" exact  className="nav-link me-xxl-3" to='/login'><LockRoundedIcon/><div className='px-2'>Login</div></Link>}
                        <SearchBox setOpen={setOpen}/>
                    </Nav>
                    
                    
                    <Nav className='user_nav'>
                        
                        {!userInfo && <Link onClick={()=>setOpen(false)}  exact className="nav-link py-0" to='/register'><button style={{width:'max-content'}} className='bttn'><PersonAddAltRoundedIcon/><div  className='px-2'>Sign Up</div></button></Link>}
                        {/* {userInfo && <Link onClick={()=>setOpen(false)} exact className="nav-link" activeClassName="selected" to='/profile'><AccountCircleIcon/><div className='px-2'>{userInfo.name}</div></Link>} */}
                        {(userInfo) && <NavDropdown className='ms-xl-4' title={<div><AccountCircleIcon className='me-2'/>{userInfo.name}</div>} id="collasible-nav-dropdown">
                            <LinkContainer to='/profile'>
                                <NavDropdown.Item onClick={()=>setOpen(false)}>Profile</NavDropdown.Item>
                            </LinkContainer>
                            
                            {(userInfo && userInfo.isAdmin) && <>
                                <LinkContainer onClick={()=>setOpen(false)} to='/admin/userlist'>
                                    <NavDropdown.Item>Users</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer onClick={()=>setOpen(false)} to='/orders'>
                                    <NavDropdown.Item>Orders</NavDropdown.Item>
                                </LinkContainer>
                            </>}
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>
                        </NavDropdown>}
                        
                        
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default MyNavbar;
