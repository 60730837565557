import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProductDetails } from '../actions/productActions';
import PreviewFlip from '../components/PreviewFlip';
import { ThemeProvider } from '@emotion/react';

function FlipBookScreen({match}) {
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product } = productDetails

    const preview = ()=>{
        var pages = document.getElementsByClassName('flip_page');
        document.getElementById('flip_pages').classList.add('unopen')
        for(var i = 0; i < pages.length; i++)
            {
            var page = pages[i];
            if (i % 2 === 0)
                {
                page.style.zIndex = (pages.length - i);
                }
            }

        
            for(var x = 0; x < pages.length; x++)
            {
                //Or var page = pages[x];
                pages[x].pageNum = x + 1;
                pages[x].onclick=function()
                {
                   
                        document.getElementById('flip_pages').classList.remove('unopen')
                    
                    if (this.pageNum % 2 === 0)
                    {
                        this.classList.remove('flipped');
                        this.previousElementSibling.classList.remove('flipped');
                    }
                    else
                    {
                        this.classList.add('flipped');
                        this.nextElementSibling.classList.add('flipped');
                    }
                }
            }
    }
    useEffect(() => {
        
        window.scrollTo(0,0)
        if (product.pages ){
            product.pages && preview()
        } else{
            if (parseInt(product._id)!==parseInt(match.params.id)){
                dispatch(listProductDetails(match.params.id))
            }
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, match, product.pages])

    const previewpages = [...new Set(cartItems.filter(x=>parseInt(x.productId) ===parseInt(match.params.id)).map(item => item.pageNumber))].sort((a,b)=>a-b)

    
  return (
    <div className='flip_body nav_margin'>
        <div className="flip_book">
            <div id="flip_pages" className={`flip_pages ${document.getElementsByClassName('flipped').length}`}>
                
                {loading? <Loader width={80} />: error? <Message width={80} variant='error'>{error}</Message>:
                    product.pages && product.pages.map(item=>(
                        
                        
                            <PreviewFlip key={item._id} background={item.image} texts={item.texts} />
                        
                        
                    )
                    )
                }
            </div>
            </div>

    </div>
  )
}

export default FlipBookScreen