import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { saveShippingAddress } from '../actions/cartActions'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,

    
} from 'react-places-autocomplete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import LocationOn from '@mui/icons-material/LocationOn';
import { TextField, InputAdornment, Paper, ListItemIcon, Button } from '@mui/material';
import { Row } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';

function ShippingScreen({location, history, match}) {
    const dispatch = useDispatch()
    const redirect = location.search ? location.search.split('=')[1] : undefined


    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [latitude, setLatitude] = useState(shippingAddress.latitude)
    const [longitude, setLongitude] = useState(shippingAddress.longitude)
    const [address, setAddress] = useState('');
    const [to_collect, setToCollect] = useState(false);

    useEffect(() => {
        window.scrollTo(0,0)
        if(shippingAddress.latitude && shippingAddress.longitude){
            redirect?history.push(`/${redirect}`):history.push(`/placeorder/${match.params.id}`)
        }

        if(!userInfo){
            history.push(`/login?redirect=placeorder/${match.params.id}`)
        }else{
            localStorage.removeItem('redirect')
        }

        // if (to_collect){
        //     history.push(`/placeorder/${match.params.id}`)
        // }
        
        
    }, [history, shippingAddress, redirect, match, userInfo])

    useEffect(()=>{
        if (to_collect){
            console.log(to_collect)
            dispatch(saveShippingAddress({ address, latitude, longitude, to_collect }))
            history.push(`/placeorder/${match.params.id}`)
        }
    }, [to_collect, address, latitude, longitude, match, history, dispatch])

    const handleChange = (address) => {
        
        setAddress(address)
    };
    const handleSelect = (address) => {
        setAddress(address)
        
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => {setLatitude(latLng.lat);setLongitude(latLng.lng)})
          .catch(error => console.error('Error', error));
      };
    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(saveShippingAddress({ address, latitude, longitude, to_collect }))
        history.push(`/placeorder/${match.params.id}`)
    }
    
    const toCollectOrder = (e) =>{
        setToCollect(true)
        // setToCollect(true, () => dispatch(saveShippingAddress({ address, latitude, longitude, to_collect })))
        // history.push(`/placeorder/${match.params.id}`)
    }
    
  return (
      
    <div className='p-4 nav_margin'>
        <Row className="address_container mb-4">
                
                
                <PlacesAutocomplete
                    value={address}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    className="w-100"
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        
                        <Paper
                            className="address__input_wrapper p-2"
                            component="form"
                            sx={{ display: 'flex', flexWrap:'wrap', alignItems: 'center', width: '100%', boxShadow: 0, background:'transparent' }}
                        >
                            <h5 className="fw-fw-normal py-2"> Please Enter Your Delivery Address</h5>
                            <TextField
                                sx={{ mr: 2}}
                                hiddenLabel
                                id="filled-hidden-label-small"
                                color="secondary"
                                variant="filled"
                                size="small"

                                {...getInputProps({
                                    placeholder: 'Enter Delivery Address',
                                    className: 'address_input form-control',
                                })}

                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <LocationOn />
                                    </InputAdornment>
                                    ),
                                    
                                }}
                            />
                            <Button className="address_save" disabled={!latitude || !longitude || latitude==='' || longitude===''||address===''} onClick={submitHandler} variant="outlined" color="secondary" size="large">
                                {loading ? <LoaderButton />: "Save"}
                            </Button>
                        </Paper>
                        <div className="autocomplete-dropdown-container address__input_wrapper">
                        <List sx={{width:'100%', margin:'auto'}} >
                            {loading && <LinearProgress color="secondary" />}
                            
                            {suggestions.map(suggestion => {
                                
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <ListItem 
                                        key={suggestion.placeId}
                                        divider
                                        sx={{ py: 1}}
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        
                                    })}>
                                        <ListItemIcon>
                                            <LocationOn />
                                        </ListItemIcon>
                                        <ListItemText primary={suggestion.description}/>
                                    </ListItem>
                                    
                                
                                );
                            })}
                        </List>
                        </div>
                    </div>
                    )}
                </PlacesAutocomplete>
            
        </Row>
        <Row>
              <Button className="address_save" onClick={toCollectOrder} variant="outlined" color="secondary" size="large">
            Collect order instead?
            </Button>
        </Row>
    </div>
  )
}

export default ShippingScreen