import React from 'react'
import {Box, LinearProgress} from '@mui/material/';


function Loader({width}) {
    return (
        <div className='loader'>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader
