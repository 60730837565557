import {
    GIFT_LIST_REQUEST,
    GIFT_LIST_SUCCESS,
    GIFT_LIST_FAIL,

    GIFT_TYPE_DETAILS_REQUEST,
    GIFT_TYPE_DETAILS_SUCCESS,
    GIFT_TYPE_DETAILS_FAIL,

    GIFT_CART_ADD_ITEM_REQUEST,
    GIFT_CART_ADD_ITEM_SUCCESS,
    GIFT_CART_ADD_ITEM_FAIL,
    GIFT_CART_REMOVE_ITEM,
    GIFT_CART_CLEAR_ITEMS,

} from '../constants/giftConstants'


export const allGiftsListReducer = (state = { gifts: [], giftTypes: [] }, action) => {
    switch (action.type) {
        case GIFT_LIST_REQUEST:
            return { loading: true, gifts: [], giftTypes: [] }

        case GIFT_LIST_SUCCESS:

            return {
                loading: false,
                gifts: action.payload.gifts,
                giftTypes: action.payload.gift_types,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case GIFT_LIST_FAIL:
            return { loading: false, error: action.payload, gifts: [], giftTypes: [] }

        default:
            return state
    }
}


export const getGiftTypeReducer = (state = { giftType: {}, giftOptions: [], main_image: "" }, action) => {
    switch (action.type) {
        case GIFT_TYPE_DETAILS_REQUEST:
            return { loading: true, giftType: {} }

        case GIFT_TYPE_DETAILS_SUCCESS:

            return {
                loading: false,
                giftType: action.payload,
                giftOptions: action.payload.gift_options,
                main_image: action.payload.image
            }

        case GIFT_TYPE_DETAILS_FAIL:
            return { loading: false, error: action.payload, giftType: {}, giftOptions: [], main_image: "" }

        default:
            return state
    }
}


export const giftCartReducer = (state = { giftCartItems: {} }, action) => {
    switch (action.type) {
        case GIFT_CART_ADD_ITEM_REQUEST:
            return {
                ...state,
                loading: true
            }
        case GIFT_CART_ADD_ITEM_SUCCESS:
            const item = action.payload

            // const existItem = state.giftCartItems

            // if (existItem) {

            return {
                ...state,
                loading: false,
                success: true,
                giftCartItems: item,

            }

        // } else {
        //     return {
        //         ...state,
        //         giftCartItems: { ...state.giftCartItems, item },
        //         loading: false,
        //         success: true
        //     }
        // }
        case GIFT_CART_ADD_ITEM_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case GIFT_CART_REMOVE_ITEM:
            localStorage.removeItem('giftCartItems')
            return {
                ...state,
                giftCartItems: {}
            }

        case GIFT_CART_CLEAR_ITEMS:
            localStorage.removeItem('giftCartItems')
            return {
                ...state,
                giftCartItems: {}
            }

        default:
            return state
    }
}