import React, { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listOrders, payOrder, deliverOrder } from '../actions/orderActions'
import {FaTimes} from 'react-icons/fa'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { ORDER_DELIVER_RESET, ORDER_PAY_RESET } from '../constants/orderConstants'
function OrderListScreen({ history }) {
    
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)

    const orderList = useSelector(state => state.orderList)
    const { loading, error, orders } = orderList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const orderPay = useSelector(state => state.orderPay)
    const { loading:loadingPay, error:errorPay, success: successPay } = orderPay

    const orderDeliver = useSelector(state => state.orderDeliver)
    const { loading:loadingDeliver, error:errorDeliver, success: successDeliver } = orderDeliver



    useEffect(() => {
        window.scrollTo(0,0)
        if (userInfo) {
            dispatch(listOrders())
        } else {
            history.push('/login')
        }
        if(successPay){
            setOpen(true)
            dispatch({type:ORDER_PAY_RESET})
        }

        if(successDeliver){
            setOpen(true)
            dispatch({type:ORDER_DELIVER_RESET})
        }

    }, [dispatch, history, userInfo, successPay, successDeliver])


    return (
        <Container className='nav_margin pt-5'>
            <h1>Orders</h1>
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={()=>setOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                sx={{width:'50%'}}
                color='#a555bf'

            >
                <Alert variant='standard' sx={{width:'100%', textAlign:'center'}}  severity="success">
                Order Updated
                </Alert>
            </Snackbar>
            {(loadingPay || loadingDeliver) && <Loader />}
            {(errorPay || errorDeliver) && <Message variant='error'>{errorPay || errorDeliver}</Message>}
            {loading
                ? (<Loader />)
                : error
                    ? (<Message variant='error'>{error}</Message>)
                    : (
                        <Table  striped bordered hover responsive className='table-sm mt-2'>
                            
                            <thead>
                                <tr>
                                    <th className="text-center">ID</th>
                                    <th>USER</th>
                                    <th>DATE</th>
                                    <th className="text-center">Total (Kshs)</th>
                                    <th className="text-start">Location</th>
                                    <th className="text-center">PAID</th>
                                    <th className="text-center">DELIVERED</th>
                                    <th>Preview</th>
                                </tr>
                            </thead>

                            <tbody>
                                {orders.map(order => (
                                    <tr key={order._id}>
                                        <td className="text-center">{order._id}</td>
                                        <td>{order.user && order.user.email}</td>
                                        <td>{(new Date(order.createdAt)).toLocaleString('en-AU').substring(0, 24).toUpperCase()}</td>
                                        <td className="text-end">{order.totalPrice}</td>
                                        <td>{order.shippingAddress && order.shippingAddress.address}</td>
                                        <td className="text-center">{order.isPaid ? (
                                            (new Date(order.paidAt)).toLocaleString('en-AU').substring(0, 24).toUpperCase()
                                        ) : (
                                                <Button size='sm' variant='success' onClick={()=>dispatch(payOrder(order._id, 'Admin Update'))}>Update Paid</Button>
                                            )}
                                        </td>

                                        <td className="text-center">{order.isDelivered ? (
                                            (new Date(order.deliveredAt)).toLocaleString('en-AU').substring(0, 24).toUpperCase()
                                        ) : (
                                                <Button size='sm' variant='success' onClick={()=>dispatch(deliverOrder(order._id))}>Update Delivered</Button> 
                                            )}
                                        </td>

                                        
                                        <td className="text-center">
                                            <LinkContainer to={`/order/${order._id}`}>
                                                <Button variant='light' className='btn-sm'>
                                                    Details
                                                </Button>
                                            </LinkContainer>


                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
        </Container>
    )
}

export default OrderListScreen