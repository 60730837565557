import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { cartReducer, shippingDetailsReducer } from './reducers/cartReducers'

import {
    faqListReducer,
    userLoginReducer,
    userRegisterReducer,
    userDetailsReducer,
    userUpdateProfileReducer,
    userListReducer,
    userDeleteReducer,
    userUpdateReducer,
    userVerifyReducer,
    userEmailReducer,
    userSendResetReducer,
    passwordResetReducer,
    userSubscribeReducer
} from './reducers/userReducers'

import {
    productListReducer,
    productDetailsReducer,
    productDeleteReducer,
    productCreateReducer,
    productUpdateReducer,
    productReviewCreateReducer,
    productTopRatedReducer,
    pageDetailsReducer,
    vendorProductListReducer,
    ocassionListReducer,
    avatarListReducer,
    allProductsListReducer
} from './reducers/productReducers'


import {
    allGiftsListReducer,
    getGiftTypeReducer,
    giftCartReducer
} from './reducers/giftReducers'

import {
    orderCreateReducer,
    orderDetailsReducer,
    orderPayReducer,
    orderListMyReducer,
    orderListReducer,
    orderDeliverReducer,
    vendorOrderListReducer,
    jengaPayReducer,
} from './reducers/orderReducers'

const reducer = combineReducers({
    faqList: faqListReducer,
    userLogin: userLoginReducer,
    userRegister: userRegisterReducer,
    userDetails: userDetailsReducer,
    userUpdateProfile: userUpdateProfileReducer,
    userList: userListReducer,
    userDelete: userDeleteReducer,
    userUpdate: userUpdateReducer,
    userVerify: userVerifyReducer,
    userEmail: userEmailReducer,
    userSendReset: userSendResetReducer,
    passwordReset: passwordResetReducer,
    userSubscribe: userSubscribeReducer,

    productList: productListReducer,
    productDetails: productDetailsReducer,
    pageDetails: pageDetailsReducer,
    productDelete: productDeleteReducer,
    productCreate: productCreateReducer,
    productUpdate: productUpdateReducer,
    productReviewCreate: productReviewCreateReducer,
    productTopRated: productTopRatedReducer,
    vendorProductList: vendorProductListReducer,
    ocassionList: ocassionListReducer,
    avatarList: avatarListReducer,
    allProductsList:allProductsListReducer,


    giftList:allGiftsListReducer,
    giftType: getGiftTypeReducer,

    cart: cartReducer,
    gift_cart: giftCartReducer,
    shippingDetails: shippingDetailsReducer,

    orderCreate: orderCreateReducer,
    orderDetails: orderDetailsReducer,
    orderPay: orderPayReducer,
    orderListMy: orderListMyReducer,
    orderList: orderListReducer,
    orderDeliver: orderDeliverReducer,
    vendorOrderList: vendorOrderListReducer,
    payJenga: jengaPayReducer,
    

})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : null

const cartItemsFromStorage = localStorage.getItem('cartItems') ?
    JSON.parse(localStorage.getItem('cartItems')) : []

const giftCartItemsFromStorage = localStorage.getItem('giftCartItems') ?
    JSON.parse(localStorage.getItem('giftCartItems')) : {}

const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
    JSON.parse(localStorage.getItem('shippingAddress')) : {}

const initialState = {
    cart: {
        cartItems: cartItemsFromStorage,
        shippingAddress: shippingAddressFromStorage,
    },        
    userLogin: { userInfo: userInfoFromStorage },
    gift_cart: { giftCartItems: giftCartItemsFromStorage },
    shippingDetails: { shippingInfo:shippingAddressFromStorage},
}


    
const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store