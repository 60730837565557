import React from 'react'
import { LightenDarkenColor } from 'lighten-darken-color';
function Female({skinColor, hairColor, eyeColor, dressColor, ornamentColor, shirtColor}) {
  return (
   
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 1080 1080"
    className='avatar_design'
    
  >
    <defs>
      <style>
        {
          `.fls-10.brow{stroke:#000}.fls-38{fill:${hairColor};}.fls-10,.fls-12,.fls-13{stroke:${hairColor}}.fls-1,.fls-15,.fls-2{fill:${dressColor};stroke:${LightenDarkenColor(dressColor, -30)};}.fls-14,.fls-18{fill:#eaac1f;}.fls-14,.fls-16,.fls-17,.fls-18,.fls-19{stroke:#c6841a;}.fls-1,.fls-10,.fls-11,.fls-12,.fls-13,.fls-16,.fls-17,.fls-23,.fls-27,.fls-5{stroke-linecap:round;}.fls-1,.fls-10,.fls-11,.fls-12,.fls-13,.fls-16,.fls-17,.fls-27,.fls-5{stroke-linejoin:round;}.fls-1,.fls-2,.fls-5{stroke-width:2px;}.fls-14,.fls-15,.fls-18,.fls-19,.fls-2,.fls-20,.fls-21,.fls-22,.fls-23,.fls-24,.fls-25,.fls-26,.fls-6,.fls-7,.fls-8{stroke-miterlimit:10;}.fls-27,.fls-3,.fls-6{fill:${skinColor};}.fls-4{fill:#3d2512;}.fls-10,.fls-11,.fls-12,.fls-13,.fls-16,.fls-17,.fls-23,.fls-5,.fls-8{fill:none;}.fls-25,.fls-26,.fls-27,.fls-5,.fls-6,.fls-7,.fls-8{stroke:#3d2512;}.fls-15,.fls-27,.fls-6,.fls-7,.fls-8{stroke-width:1.93px;}.fls-9{fill:url(#girl-grad);}.fls-23,.fls-24{stroke:#000;}.fls-10{stroke-width:10.6px;}.fls-11{stroke:#3d0416;}.fls-11,.fls-13{stroke-width:8.67px;}.fls-12{stroke-width:7.71px;}.fls-14,.fls-19{stroke-width:1.22px;}.fls-16{stroke-width:5.78px;}.fls-17{stroke-width:2.89px;}.fls-18{stroke-width:0.86px;}.fls-19{fill:#f9e41c;}.fls-20{fill:#dda019;}.fls-20,.fls-21,.fls-22{stroke:#997014;stroke-width:0.85px;}.fls-21{fill:#ffbf1f;}.fls-22{fill:#ffe422;}.fls-23{stroke-width:9.63px;}.fls-24{fill:#fff;stroke-width:0.96px;}.fls-25,.fls-26{stroke-width:0.41px;}.fls-25{fill:url(#girl-grad-2);}.fls-26{fill:url(#girl-grad-3);}.fls-28{font-size:14px;fill:#a5a5a5;font-family:MyriadPro-Regular, Myriad Pro;}.fls-29{letter-spacing:0.01em;}.fls-30{letter-spacing:-0.04em;}.fls-31{letter-spacing:-0.01em;}.fls-32{letter-spacing:0.01em;}.fls-33{letter-spacing:-0.01em;}.fls-34{letter-spacing:-0.01em;}.fls-35{letter-spacing:-0.01em;}.fls-36{letter-spacing:-0.01em;}.fls-37{letter-spacing:-0.02em;}`
        }
      </style>
      <linearGradient
        id="girl-grad"
        x1={542.28}
        y1={263.81}
        x2={541.5}
        y2={171.07}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor={skinColor} />
        <stop offset={1} stopColor={hairColor}/>
      </linearGradient>
      <linearGradient
        id="girl-grad-2"
        x1={514.55}
        y1={283.89}
        x2={512.12}
        y2={252.39}
        xlinkHref="#girl-grad"
      >
        <stop offset={0} stopColor={eyeColor} />
        <stop offset={1} />
      </linearGradient>

      <linearGradient
        id="girl-grad-3"
        x1={574.69}
        y1={283.89}
        x2={572.27}
        y2={252.39}
        xlinkHref="#girl-grad"
      >
        <stop offset={0} stopColor={eyeColor} />
        <stop offset={1} />
      </linearGradient>
    </defs>
    <g id="Layer_1" data-name="Layer 1">
      <path
        className="fls-1"
        d="M666.67,593.19s-27.89-6.59-59.9,7C606.77,600.24,641.86,609.58,666.67,593.19Z"
      />
      <path
        className="fls-2"
        d="M583.47,462.31c.89,1.76,1.79,3.61,2.7,5.53,10.78,22.79,27.78,89.42,32,145.51.84,11.11,41.07,7.79,40.23-3-11.59-150.08-63.51-204.53-65.68-206.76"
      />
      <path
        className="fls-3"
        d="M636.91,680.18a5,5,0,0,1-1.83-.35,5.18,5.18,0,0,1-3-6.1c1.12-4.61,1-10.91.81-14.26a1,1,0,0,0,0-.25,19.16,19.16,0,0,0-1.72-3.61,12.1,12.1,0,0,1-2-5.31,1,1,0,0,0-.9-.92h-.1a1,1,0,0,0-1,.74c-.21.79-1.65,6.66-1.83,7.69A5.07,5.07,0,0,1,621,662a5.83,5.83,0,0,1-.59,0,5,5,0,0,1-1.57-.25,5.17,5.17,0,0,1-3.37-5.91c1.2-6.63,6.46-28.45,24.1-30.52a16.25,16.25,0,0,1,2.08-.13c8.5,0,15.89,6.59,18,16,.95,4.31,3.84,17.43.85,26.61a5.14,5.14,0,0,1-4.27,3.63,3.77,3.77,0,0,1-.58,0,5.16,5.16,0,0,1-1.41-.2l-.49-.17a1,1,0,0,0-.38-.08,1.13,1.13,0,0,0-.44.1,1,1,0,0,0-.52.63,22.47,22.47,0,0,1-.81,2.32,5.08,5.08,0,0,1-4.07,3.08,5.83,5.83,0,0,1-.59,0,4.93,4.93,0,0,1-1.93-.39,5,5,0,0,1-1.5-1,1,1,0,0,0-.68-.27,1.12,1.12,0,0,0-.31,0,1,1,0,0,0-.67.76,5.05,5.05,0,0,1-4.3,3.76A3.81,3.81,0,0,1,636.91,680.18Z"
      />
      <path
        className="fls-4"
        d="M641.63,624.17v2c8.15,0,15,6.13,17,15.25.94,4.24,3.79,17.16.88,26.08a4.17,4.17,0,0,1-3.44,3,3.55,3.55,0,0,1-.46,0,4.48,4.48,0,0,1-1.14-.16l-.39-.14a1.93,1.93,0,0,0-.75-.15,1.89,1.89,0,0,0-.87.21,2,2,0,0,0-1.05,1.24,23,23,0,0,1-.77,2.22,4,4,0,0,1-3.27,2.46l-.47,0a4,4,0,0,1-1.55-.31,4,4,0,0,1-1.2-.77,1.94,1.94,0,0,0-1.34-.53,2,2,0,0,0-.59.09,2,2,0,0,0-1.38,1.44,4,4,0,0,1-3.45,3,3.59,3.59,0,0,1-.47,0,4,4,0,0,1-1.48-.28A4.17,4.17,0,0,1,633,674c1.14-4.73,1-11.15.83-14.55a2.09,2.09,0,0,0-.09-.51,20.3,20.3,0,0,0-1.8-3.8,11,11,0,0,1-1.83-4.88,2,2,0,0,0-1.81-1.84h-.19a2,2,0,0,0-1.93,1.48c-.19.74-1.65,6.68-1.84,7.77a4.06,4.06,0,0,1-3.51,3.32,3.71,3.71,0,0,1-.47,0,4.31,4.31,0,0,1-1.27-.2,4.18,4.18,0,0,1-2.68-4.78c1.16-6.47,6.27-27.71,23.23-29.71a16.11,16.11,0,0,1,2-.12v-2m0,0a18.11,18.11,0,0,0-2.19.13c-17.12,2-23.21,21.55-25,31.35a6.18,6.18,0,0,0,4,7,6.08,6.08,0,0,0,1.87.29,5.37,5.37,0,0,0,.7,0,6,6,0,0,0,5.25-5c.17-1,1.58-6.75,1.8-7.6.3,3.93,2.68,5.9,3.72,9.14.22,4.07.23,9.82-.78,14a6.2,6.2,0,0,0,3.64,7.27,6.1,6.1,0,0,0,2.19.41c.24,0,.47,0,.7,0a6,6,0,0,0,5.16-4.54v0a6.07,6.07,0,0,0,1.79,1.16,6.15,6.15,0,0,0,2.32.46,5.48,5.48,0,0,0,.7,0,6,6,0,0,0,4.89-3.69,24.86,24.86,0,0,0,.84-2.43,5.89,5.89,0,0,0,.59.21,6.16,6.16,0,0,0,1.69.24,5.48,5.48,0,0,0,.7,0,6.19,6.19,0,0,0,5.1-4.32c3.05-9.34.26-22.21-.83-27.13-2.1-9.54-9.52-16.82-18.94-16.82Z"
      />
      <path className="fls-5" d="M642.71,675.93a59.88,59.88,0,0,0,.31-22.4" />
      <path className="fls-5" d="M653,671.58a58.09,58.09,0,0,0,.13-18.77" />
      <path
        className="fls-1"
        d="M641.76,639.51c-21.56,2.54-30.7-2.79-31.22-7.2l-3.77-32.07s12,1.34,30.27-.82,29.63-6.23,29.63-6.23l3.78,32.07C671,629.66,663.32,637,641.76,639.51Z"
      />
      <path
        className="fls-1"
        d="M413.33,593.19s27.89-6.59,59.9,7C473.23,600.24,438.14,609.58,413.33,593.19Z"
      />
      <path
        className="fls-2"
        d="M496.53,462.31c-.89,1.76-1.79,3.61-2.7,5.53-10.78,22.79-27.78,89.42-32,145.51-.84,11.11-41.07,7.79-40.23-3,11.59-150.08,63.51-204.53,65.68-206.76"
      />
      <path
        className="fls-3"
        d="M443.09,680.18a3.81,3.81,0,0,1-.59,0,5,5,0,0,1-4.3-3.78,1,1,0,0,0-.69-.73,1.06,1.06,0,0,0-.31,0,1,1,0,0,0-.66.26,5,5,0,0,1-1.5,1,4.93,4.93,0,0,1-1.93.39,5.83,5.83,0,0,1-.59,0,5.08,5.08,0,0,1-4.07-3.08,22.47,22.47,0,0,1-.81-2.32,1,1,0,0,0-.52-.63,1.13,1.13,0,0,0-.44-.1,1,1,0,0,0-.38.08l-.48.17a5.29,5.29,0,0,1-1.42.2,3.77,3.77,0,0,1-.58,0,5.14,5.14,0,0,1-4.27-3.63c-3-9.18-.1-22.3.85-26.61,2.08-9.44,9.47-16,18-16a16.25,16.25,0,0,1,2.08.13c17.64,2.07,22.9,23.89,24.1,30.52a5.17,5.17,0,0,1-3.37,5.91,5,5,0,0,1-1.57.25h0a5.83,5.83,0,0,1-.59,0,5.07,5.07,0,0,1-4.37-4.14c-.18-1-1.62-6.9-1.83-7.69a1,1,0,0,0-1-.74h-.1a1,1,0,0,0-.9.92,12.1,12.1,0,0,1-2,5.31,19.16,19.16,0,0,0-1.72,3.61,1,1,0,0,0,0,.25c-.18,3.35-.31,9.65.81,14.26a5.18,5.18,0,0,1-3,6.1A5,5,0,0,1,443.09,680.18Z"
      />
      <path
        className="fls-4"
        d="M438.37,626.17a16.11,16.11,0,0,1,2,.12c17,2,22.07,23.24,23.23,29.71a4.18,4.18,0,0,1-2.68,4.78,4.31,4.31,0,0,1-1.27.2,3.71,3.71,0,0,1-.47,0,4.06,4.06,0,0,1-3.51-3.32c-.19-1.09-1.65-7-1.84-7.77a2,2,0,0,0-1.93-1.48h-.19a2,2,0,0,0-1.81,1.84,11,11,0,0,1-1.83,4.88,20.3,20.3,0,0,0-1.8,3.8,2.09,2.09,0,0,0-.09.51c-.18,3.4-.31,9.82.83,14.55a4.17,4.17,0,0,1-2.4,4.93,4,4,0,0,1-1.48.28,3.59,3.59,0,0,1-.47,0,4.06,4.06,0,0,1-3.46-3.06,2,2,0,0,0-1.37-1.44,2.24,2.24,0,0,0-.57-.08,2,2,0,0,0-1.36.54,4,4,0,0,1-1.2.77,4,4,0,0,1-1.55.31l-.47,0a4,4,0,0,1-3.27-2.46,23,23,0,0,1-.77-2.22,2,2,0,0,0-1.05-1.24,1.89,1.89,0,0,0-.87-.21,2,2,0,0,0-.75.15l-.39.14a4.48,4.48,0,0,1-1.14.16,3.55,3.55,0,0,1-.46,0,4.17,4.17,0,0,1-3.44-3c-2.91-8.92-.06-21.84.88-26.08,2-9.12,8.84-15.25,17-15.25m0-2c-9.42,0-16.84,7.28-18.94,16.82-1.09,4.92-3.88,17.79-.83,27.13a6.19,6.19,0,0,0,5.1,4.32,5.48,5.48,0,0,0,.7,0,6.16,6.16,0,0,0,1.69-.24,5.89,5.89,0,0,0,.59-.21,24.86,24.86,0,0,0,.84,2.43,6,6,0,0,0,4.89,3.69,5.48,5.48,0,0,0,.7,0,6.15,6.15,0,0,0,2.32-.46,6.07,6.07,0,0,0,1.79-1.16v0a6,6,0,0,0,5.16,4.54c.23,0,.46,0,.7,0a6.1,6.1,0,0,0,2.19-.41,6.2,6.2,0,0,0,3.64-7.27c-1-4.16-1-9.91-.78-14,1-3.24,3.42-5.21,3.72-9.14.22.85,1.63,6.64,1.8,7.6a6,6,0,0,0,5.25,5,5.37,5.37,0,0,0,.7,0,6.08,6.08,0,0,0,1.87-.29,6.18,6.18,0,0,0,4-7c-1.76-9.8-7.85-29.33-25-31.35a18.11,18.11,0,0,0-2.19-.13Z"
      />
      <path className="fls-5" d="M437.29,675.93a59.88,59.88,0,0,1-.31-22.4" />
      <path className="fls-5" d="M427,671.58a58.09,58.09,0,0,1-.13-18.77" />
      <path
        className="fls-1"
        d="M438.24,639.51c21.56,2.54,30.7-2.79,31.22-7.2l3.77-32.07s-12,1.34-30.27-.82-29.63-6.23-29.63-6.23l-3.78,32.07C409,629.66,416.68,637,438.24,639.51Z"
      />
      <path
        className="fls-6"
        d="M594.86,999.52H558.59a7.82,7.82,0,0,1-7.81-7.83V947.11h29.13v26.2l14.64,12.23,4.12,3.45A6,6,0,0,1,594.86,999.52Z"
      />
      {/* right shoe */}
      <path
        className="fls-7"
        d="M594.86,999.52H558.59a7.82,7.82,0,0,1-7.81-7.83v-9.32s31,9.37,43.77,3.17l4.12,3.45A6,6,0,0,1,594.86,999.52Z"
      />
      <path
        className="fls-6"
        d="M490.24,999.52H526.5a7.82,7.82,0,0,0,7.82-7.83V947.11H505.19v26.2l-14.65,12.23L486.42,989A6,6,0,0,0,490.24,999.52Z"
      />
      {/* left shoe */}
      <path
        className="fls-7"
        d="M490.24,999.52H526.5a7.82,7.82,0,0,0,7.82-7.83v-9.32s-31,9.37-43.78,3.17L486.42,989A6,6,0,0,0,490.24,999.52Z"
      />
      <circle className="fls-38" cx={539.85} cy={94.41} r={59.44} />
      <path
        className="fls-3"
        d="M505.84,510.11l-6.95-29.91-19.47-48.71c0-17.45,4.68-29.71,13.81-36.45A44.38,44.38,0,0,1,498,392c2-1.12,6.1-1.15,10-1.18,6.82,0,14.54-.09,15.41-5.86l0-.07v-55h31.44V385l.06.16c2.08,5.67,8,6.43,13.59,6.43,1.28,0,2.57,0,3.82-.08s2.28-.08,3.33-.08a13,13,0,0,1,4.73.62,13.47,13.47,0,0,1,2.19,1.29l.53.36c12.57,8.23,15.25,25.53,15.27,38.67l-17,46.12-7.41,31.67Z"
      />
      <path
        className="fls-4"
        d="M554,330.83v54.29l.12.32c2.6,7.07,10.94,7.07,14.49,7.07,1.31,0,2.61,0,3.86-.09s2.27-.07,3.3-.07a12.75,12.75,0,0,1,4.35.53,13.38,13.38,0,0,1,2,1.21l.54.36c12.15,8,14.79,24.82,14.83,37.7l-16.9,45.9,0,.11,0,.12-7.23,30.86H506.61l-6.77-29.09,0-.14-.05-.14L480.38,431.3c.06-17,4.58-29,13.43-35.49a42.82,42.82,0,0,1,4.69-3c1.82-1,5.76-1,9.57-1.06,7.2-.05,15.36-.11,16.36-6.69l0-.14V330.83H554m1.93-1.92H522.52v55.86c-1.22,8.07-19.13,3.13-25,6.35a44.79,44.79,0,0,0-4.9,3.14c-10.94,8.08-14.21,22.77-14.21,37.41L498,480.49l7.11,30.58h69.73l7.58-32.35,17-46.23c0-15.54-3.61-31.73-15.7-39.65a20.33,20.33,0,0,0-2.86-1.73,13.65,13.65,0,0,0-5.12-.69c-2.19,0-4.68.16-7.16.16-5.46,0-10.83-.78-12.68-5.81V328.91Z"
      />
      <path
        className="fls-6"
        d="M481.07,307.27a28,28,0,0,1-21.66,10.52c-15.93,0-22.2-15.2-28.84-31-10.19-24.19,15.62-49,41.44-27.86"
      />
      <path
        className="fls-8"
        d="M442,280.52c.89-4.46,6.18-7,10.64-6.12s8,4.25,10.65,8c2.88,4.1,5,9.07,4.34,14.05s-4.61,9.73-9.62,9.94"
      />
      <path className="fls-8" d="M450.67,291.6a12.5,12.5,0,0,1,15.51-2.66" />
      <path
        className="fls-6"
        d="M598.45,307.27a28,28,0,0,0,21.66,10.52c15.93,0,22.2-15.2,28.84-31,10.2-24.19-15.62-49-41.44-27.86"
      />
      <path
        className="fls-8"
        d="M637.48,280.52c-.88-4.46-6.18-7-10.64-6.12s-8,4.25-10.65,8c-2.88,4.1-5,9.07-4.34,14.05s4.62,9.73,9.63,9.94"
      />
      <path className="fls-8" d="M628.86,291.6a12.51,12.51,0,0,0-15.52-2.66" />
      <path
        className="fls-3"
        d="M541.79,365.49c-30.51,0-49.22-19.72-59.54-36.27-11.18-17.93-14.95-36.08-13.55-39l.1-.2v-.33l0-.11c-1-4.42-2.1-9-3.18-13.52-.84-3.58-1.69-7.14-2.49-10.59-1.77-7.56-3.73-16.12-4.89-22.32-7.93-42.42-3.08-75.72,14-96.29,14.51-17.47,37.92-26.33,69.58-26.33s55.08,8.86,69.59,26.33c17.08,20.57,21.93,53.87,14,96.29-1.19,6.37-3.29,15.49-5.18,23.57-.74,3.17-1.52,6.43-2.29,9.7-1,4.42-2.1,8.86-3.1,13.16l0,.1v.34l.1.2c1.4,2.87-2.37,21-13.55,39C591,345.77,572.31,365.49,541.79,365.49Z"
      />
      <path
        className="fls-4"
        d="M541.79,121.54c31.37,0,54.53,8.74,68.85,26,8.85,10.66,14.42,24.7,16.54,41.72,1.93,15.57,1,33.66-2.75,53.77-1.19,6.36-3.28,15.46-5.17,23.53-.74,3.18-1.52,6.45-2.29,9.73-1.05,4.4-2.1,8.84-3.09,13.13l0,.22v.66l.2.4c1.1,2.54-2.49,21.29-14.74,40-10.14,15.45-28.34,33.87-57.49,33.87s-47.34-18.42-57.48-33.87c-12.25-18.68-15.84-37.43-14.74-40l.19-.41v-.67l-.05-.21c-1-4.36-2.08-8.88-3.14-13.36-.86-3.64-1.72-7.25-2.53-10.75-1.77-7.54-3.73-16.09-4.88-22.28-3.76-20.11-4.69-38.2-2.75-53.77,2.12-17,7.68-31.06,16.54-41.72,14.32-17.25,37.48-26,68.84-26m0-1.92c-81.18,0-96.92,57.43-84.53,123.76,1.08,5.77,2.88,13.71,4.9,22.36,1.83,7.81,3.84,16.21,5.68,24.11h0c-3.23,6.59,16.34,76.61,73.95,76.61s77.19-70,74-76.61h0c1.74-7.49,3.64-15.41,5.39-22.86,2.14-9.14,4.06-17.56,5.19-23.61,12.39-66.33-3.35-123.76-84.54-123.76Z"
      />
      <path
        className="fls-9"
        d="M626.33,243.38c-1.13,6.05-3.05,14.47-5.19,23.61h-8.3c3.77-19.27,3.42-42.42,2.32-59.43a43.35,43.35,0,0,0-26.57-37.37c-37.13-15.3-72.15-7.6-94.37,1.11a43.58,43.58,0,0,0-27.63,39.78c-.45,25.17,3.4,53.46,3.4,53.46s-3.74.42-7.83,1.2c-2-8.65-3.82-16.59-4.9-22.36-12.39-66.33,3.35-123.76,84.53-123.76S638.72,177.05,626.33,243.38Z"
      />
      <path
        className="fls-10 brow"
        d="M568.72,215.57c11.58-1.85,24.75,2.79,33.56,10.54"
      />
      <path
        className="fls-10 brow"
        d="M519.67,215.57a42.71,42.71,0,0,0-33.61,9.72"
      />
      <path
        className="fls-11"
        d="M520,322.42c12.33,11.35,33.18,7.43,39.72-.33"
      />
      <path className="fls-10" d="M494.93,169.55c.35-1.07-5.71-12.49,0-36.06" />
      <path className="fls-10" d="M514.48,165c-1.22-6.64-2.8-18.44,0-36.88" />
      <path
        className="fls-10"
        d="M569.84,164.49c-1.23-6.64-2.81-18.43,0-36.87"
      />
      <path
        className="fls-10"
        d="M532.47,161.18c-1.22-6.64-2.8-18.43,0-36.87"
      />
      <path
        className="fls-10"
        d="M551.4,161.18c-1.23-6.64-2.81-18.43,0-36.87"
      />
      <path className="fls-10" d="M480.4,178.81c.34-1.07-5.71-12.5,0-36.06" />
      <path className="fls-10" d="M467.4,193.3c.35-1.07-5.7-12.5,0-36.06" />
      <path className="fls-12" d="M464.05,213.79c.16-1.11-7.82-5-6.38-29.23" />
      <path
        className="fls-13"
        d="M466.16,236.81c0-1.12-8.66-11.16-10.76-28.37"
      />
      <path
        className="fls-13"
        d="M462.41,256.21c-2.25-2.28-7-13.35-7.73-24.65"
      />
      <path
        className="fls-10"
        d="M587.44,169.55c-.35-1.07,5.71-12.49,0-36.06"
      />
      <path className="fls-10" d="M602,178.81c-.34-1.07,5.71-12.5,0-36.06" />
      <path className="fls-10" d="M615,193.3c-.35-1.07,5.7-12.5,0-36.06" />
      <path className="fls-12" d="M618.32,213.79c-.16-1.11,7.82-5,6.38-29.23" />
      <path
        className="fls-13"
        d="M617.43,236.81c0-1.12,7.18-11.16,9.54-28.37"
      />
      <path className="fls-13" d="M620,256.21c2.25-2.28,7-13.35,7.73-24.65" />
      {/* earrings */}
      <circle className="fls-14" cx={457.5} cy={349.19} r={6.98} />
      <circle className="fls-14" cx={457.5} cy={318.46} r={6.98} />
      <rect
        className="fls-14"
        x={450.88}
        y={326.43}
        width={13.24}
        height={15.78}
        rx={3.97}
      />
      <circle className="fls-14" cx={626.97} cy={349.19} r={6.98} />
      <circle className="fls-14" cx={626.97} cy={318.46} r={6.98} />
      <rect
        className="fls-14"
        x={620.35}
        y={326.43}
        width={13.24}
        height={15.78}
        rx={3.97}
      />
      <path
        className="fls-15"
        d="M599.41,432.49c-1.41,10.42-10,29.19-14.1,46.23a250.27,250.27,0,0,1-10.5,32.35H505.08s-4.55-14.55-9.3-30.59c-5.27-17.79-15.62-37.75-17.33-48.81-2.24-14.47,3.27-29.33,14.21-37.41,5.56,11.79,19.58,33.6,48.83,39.24,0,0,36.32-10.08,42.22-40.66C595.8,400.76,601.5,417.09,599.41,432.49Z"
      />
      <path
        className="fls-15"
        d="M562.46,471.49c1,.2,18.2,2.43,27.19-4.2,11.46-8.44,8.06-35.39,8.06-35.39"
      />
      <path
        className="fls-15"
        d="M515.73,471.49c-1,.2-18.19,2.43-27.18-4.2-11.46-8.44-8.07-35.39-8.07-35.39"
      />
      <path
        className="fls-15"
        d="M575.09,511.07H504.77l-7.93,8.39c-20.29,21.46-44,66.57-41.44,103.87C461,704.27,499.55,958,499.55,958H585S617.53,696.5,622.2,616.54c2.18-37.37-21-77.87-41.29-99.33Z"
      />
      {/* necklace */}
      <path
        className="fls-16"
        d="M515.12,389.37s-2.89,69.5,24.73,71.06c28.81,1.63,20.49-70.48,20.49-70.48"
      />
      <path
        className="fls-16"
        d="M515.12,389.37S507.55,485,541.49,485c33.17,0,18.85-95.07,18.85-95.07"
      />
      <path
        className="fls-17"
        d="M458.55,171.6s47.29,31.76,84.67,31.76,80.6-36.51,80.6-36.51"
      />
      <path
        className="fls-17"
        d="M458.55,171.6s47.29,21.2,84.67,21.2,80.6-26,80.6-26"
      />
      <circle className="fls-18" cx={543.2} cy={213.79} r={10.37} />
      <circle className="fls-19" cx={543.2} cy={213.79} r={6.63} />
      <path
        className="fls-20"
        d="M491.33,137.07s12.49-30.71,13-54.25c0,0,21.08,38.69,36.33,38.22C556,121.51,577,82.82,577,82.82c.55,23.54,12.16,58,12.16,58"
      />
      <path
        className="fls-21"
        d="M540.7,146c-12.65-.55-50.5,4.43-50.5,4.43l-12-68s18.71,28.66,29.43,27.49,27.43-41.71,33.06-55.46c5.63,13.75,22.35,54.3,33.07,55.46s29.42-27.49,29.42-27.49l-12,68S553.35,146.53,540.7,146Z"
      />
      <ellipse className="fls-21" cx={540.7} cy={54.39} rx={4.56} ry={5.85} />
      <ellipse className="fls-21" cx={577.27} cy={80.96} rx={4.56} ry={5.85} />
      <ellipse className="fls-21" cx={603.19} cy={82.36} rx={4.56} ry={5.85} />
      <ellipse className="fls-21" cx={504.37} cy={80.96} rx={4.56} ry={5.85} />
      <ellipse className="fls-21" cx={478.21} cy={80.96} rx={4.56} ry={5.85} />
      <path className="fls-21" d="M488.14,138.7s57.67-11.36,105.13,0" />
      <path className="fls-21" d="M485.66,124.63s60.78-12.82,110.09,0" />
      <ellipse className="fls-22" cx={540.7} cy={126.43} rx={3.62} ry={4.65} />
      <ellipse className="fls-22" cx={540.66} cy={92.84} rx={3.62} ry={17.56} />
      <ellipse className="fls-22" cx={520.64} cy={127.14} rx={3.62} ry={4.65} />
      <ellipse className="fls-22" cx={499.25} cy={129.49} rx={3.62} ry={4.65} />
      <ellipse className="fls-22" cx={562.02} cy={127.14} rx={3.62} ry={4.65} />
      <ellipse className="fls-22" cx={583.41} cy={129.49} rx={3.62} ry={4.65} />
      <path
        className="fls-23"
        d="M487.15,266c-4.15-15.09,8.21-30.18,24.88-30.18,9.77,0,16.92,5.09,20.6,12.82"
      />
      <path
        className="fls-23"
        d="M599.29,266c4.16-15.09-8.21-30.18-24.87-30.18-9.78,0-16.92,5.09-20.61,12.82"
      />
      <path
        className="fls-24"
        d="M541.8,276.76a30.19,30.19,0,1,1,1.62-9.77,30.18,30.18,0,0,1-1.62,9.77"
      />
      <circle className="fls-25" cx={513.25} cy={266.99} r={18.32} />
      <circle className="fls-24" cx={573.39} cy={266.99} r={30.17} />
      <circle className="fls-26" cx={573.39} cy={266.99} r={18.32} />
      <rect
        className="fls-3"
        x={485.9}
        y={286.04}
        width={111.78}
        height={20.83}
      />
      <path
        className="fls-27"
        d="M544.62,279.33a39.59,39.59,0,0,0,3.47,8.66c2.23,4.08,5.27,8.07,5.26,12.72,0,4.94-3.67,9.33-8.14,11.43a16.39,16.39,0,0,1-14,.1,13.6,13.6,0,0,1-7.6-11.51"
      />
    </g>
    <g id="Layer_2" data-name="Layer 2">
      <text className="fls-28" transform="translate(474.3 1049.74)">
        <tspan className="fls-29">{"ww"}</tspan>
        <tspan className="fls-30" x={20.94} y={0}>
          {"w"}
        </tspan>
        <tspan x={30.7} y={0}>
          {".in"}
        </tspan>
        <tspan className="fls-31" x={44.65} y={0}>
          {"m"}
        </tspan>
        <tspan className="fls-32" x={56.14} y={0}>
          {"y"}
        </tspan>
        <tspan className="fls-33" x={62.9} y={0}>
          {"w"}
        </tspan>
        <tspan x={73.06} y={0}>
          {"o"}
        </tspan>
        <tspan className="fls-34" x={80.75} y={0}>
          {"r"}
        </tspan>
        <tspan x={85.19} y={0}>
          {"d"}
        </tspan>
        <tspan className="fls-35" x={93.08} y={0}>
          {"s"}
        </tspan>
        <tspan x={98.47} y={0}>
          {"."}
        </tspan>
        <tspan className="fls-36" x={101.37} y={0}>
          {"c"}
        </tspan>
        <tspan className="fls-37" x={107.56} y={0}>
          {"o"}
        </tspan>
        <tspan x={114.91} y={0}>
          {".ke"}
        </tspan>
      </text>
    </g>
  </svg>


  )
    }

export default Female