import MyNavbar from './components/Navbar';
import Footer from './components/Footer';
import { HashRouter as Router, Route } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen';
import AvatarDesignScreen from './screens/AvatarDesignScreen';
import CoverDesignScreen from './screens/CoverDesignScreen';
import ProductsScreen from './screens/ProductsScreen';
import CoverScreen from './screens/CoverScreen';
import PreviewScreen from './screens/PreviewScreen';
import RegisterScreen from './screens/RegisterScreen';
import ConfirmationScreen from './screens/ConfirmationScreen';
import LoginScreen from './screens/LoginScreen';
import ShippingScreen from './screens/ShippingScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import ProfileScreen from './screens/ProfileScreen';
import OrderDetailsScreen from './screens/OrderDetailsScreen';
import ContactScreen from './screens/ContactScreen';
import UserEditScreen from './screens/UserEditScreen';
import UserListScreen from './screens/UserListScreen';
import OrderListScreen from './screens/OrderListScreen';
import ResetEmailScreen from './screens/ResetEmailScreen';
import ResetScreen from './screens/ResetScreen';
import EditAvatarScreen from './screens/EditAvatarScreen';
import PaymentScreen from './screens/PaymentScreen';
import FaqScreen from './screens/FAQ';
import FlipBookScreen from './screens/FlipBookScreen';
import { useDispatch } from 'react-redux'
import { PRODUCT_DETAILS_RESET } from './constants/productConstants'
import PreviewEditScreen from './screens/PreviewEditScreen';
import ProductScreen from './screens/ProductScreen'
import GiftScreen from './screens/GiftScreen';
import GiftDesignScreen from './screens/GiftDesignScreen';
import { useState, useEffect } from 'react';

function App() {

  const dispatch = useDispatch

  return (
    <Router>
      <MyNavbar />
      <main className="w-100">
        <div className="main w-100">
          
          <Route path='/' component={HomeScreen} exact />
          <Route path='/avatar/edit' component={EditAvatarScreen}  />
          <Route path='/avatar' component={AvatarDesignScreen} exact  />
          <Route path='/cover' component={CoverDesignScreen}  />
          <Route path='/avatarproducts' component={CoverScreen}   />
          <Route path='/product/:id' component={ProductScreen} exact />
          
          <Route path='/products' component={ProductsScreen} />
          <Route path='/product/:id/page/:pageNumber' component={CoverDesignScreen} />
          <Route path='/preview/:id/edit' component={PreviewEditScreen} />
          <Route path='/preview/:id' component={PreviewScreen} exact/>
          <Route path='/register' component={RegisterScreen} />
          <Route path='/verify/:id/:token' component={ConfirmationScreen} />
          <Route path='/login' component={LoginScreen}  />
          <Route path='/shipping/:id' component={ShippingScreen} />
          <Route path='/placeorder/:id' component={PlaceOrderScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/order/:id' component={OrderDetailsScreen} />
          <Route path='/contact' component={ContactScreen} />
          <Route path='/admin/userlist' component={UserListScreen} />
          <Route path='/admin/user/:id/edit' component={UserEditScreen} />
          <Route path='/orders' component={OrderListScreen} />
          <Route path='/passwordReset' component={ResetEmailScreen} />
          <Route path='/reset/:id/:token' component={ResetScreen} />
          <Route path='/payment' component={PaymentScreen} />
          <Route path='/faq' component={FaqScreen} />
          <Route path='/gifts/:gift_name' component={GiftScreen} />
          <Route path='/gift/:gift_name/:id' component={GiftDesignScreen} />
          <Route onLeave={()=>dispatch({type:PRODUCT_DETAILS_RESET})} path='/flip/:id' component={FlipBookScreen} />
          
          
          
          
        </div>
        
      </main>
      <Footer />
    </Router>
  );
}

export default App;
