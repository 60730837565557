import React, {useState, useEffect} from 'react'
import {Modal, Image, Form} from 'react-bootstrap'

import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux'
import { addSubscriber } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { USER_SUBSCRIBE_RESET } from '../constants/userConstants'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import BadgeIcon from '@mui/icons-material/Badge';
import subscribe from '../images/subscribe.png';

function SubscribeForm({show, setShow}) {
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
   

    const userSubscribe = useSelector(state=>state.userSubscribe)
    const {loading, error, success, response} = userSubscribe

    
    

    const handleClose = () => {
        
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const submitHandler = (e) => {
        e.preventDefault()
        
        dispatch(addSubscriber({email_address:email, status: "subscribed",  merge_fields: {}}))

    }

    

    const handleEmailChange = e => {
        setEmail( e.target.value)
        
        
    
    };

    useEffect(() => {
        
        if(success){
            setEmail('')
            localStorage.setItem('subscribed', true)

        
            setTimeout(() => {
                dispatch({type:USER_SUBSCRIBE_RESET})
                handleClose()
            }, 5000);
            
            
        }
    }, [success, dispatch])
    

    return (
        <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            
            </Modal.Header>
            <Modal.Body className='subscribe_body'>
                
                <div className='d-block text-center'>
                    <Image className='subscribe_icon' src={subscribe} alt='icon' fluid />
                </div>
                <h4 className='d-block text-center fw-bold'>We are always giving great discounts and adding amazing stuff. Be in the loop by subscribing below.</h4>
                
                <Form className='mb-4' onSubmit={submitHandler}>
                    <FormControl size="large" variant="standard" fullWidth >
                        
                        <Input
                            sx={{padding:'8px 8px', background:'white', color:'#000000', borderRadius:'.5rem'}}
                            required
                            placeholder="Enter Email Address"
                            id="email"
                            type="email"
                            startAdornment={
                                <InputAdornment position="start">
                                    <EmailOutlinedIcon />
                                </InputAdornment>
                            }
                            value={email}
                            onChange={handleEmailChange}
                            
                            color="secondary"
                            size='large'
                        />
                        
                    </FormControl>


                    
                    <button color="success" type='submit' className='w-100 btn btn-primary btn-subscribe' >
                        <MarkEmailReadIcon /> &nbsp;&nbsp;Subscribe 
                    </button>

                    {error && <Message width={100} variant='error'>{error}</Message>}
                    {loading && <Loader width={100} />}
                    {success && <Message width={100} variant='success'>Subscribed Successfully!</Message>}
                </Form>
            </Modal.Body>
        </Modal>
    );
}


export default SubscribeForm