import React, {useState, useEffect} from 'react';
import Carousel from 'react-bootstrap/Carousel'
import female from '../images/avatars/female.svg'
import male from '../images/avatars/male.svg'
import book3 from '../images/book3.jpg';
import book2 from '../images/book2.jpg';
import book1 from '../images/book1.jpg';
import book10 from '../images/book10.jpg';
import book9 from '../images/book9.jpg';
import book11 from '../images/book11.jpg';
import PulseButton from '../components/PulseButton';
import avatarm from '../images/avatarm.png';
import avatarf from '../images/avatarf.png';
import {Row, Col, Navbar} from 'react-bootstrap'
import design from '../images/design.svg'
import cover from '../images/cover.svg'
import personal from '../images/personal.svg'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useHistory } from 'react-router-dom';
import Subscribe from '../components/Subscribe';
import SubscribeForm from '../components/SubscribeForm';
import { useSelector } from 'react-redux'

function HomeScreen() {
    const history = useHistory()
    const [show, setShow] = useState(false);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        window.scrollTo(0,0)
        
        AOS.init({
            
        });
        AOS.refresh();
        if(!userInfo && !localStorage.getItem('subscribed')){
            setTimeout(() => {
                setShow(true)
            }, 3000);
        }
    }, [])
    return (
        <div className="w-100">
            
            <div className='header'>
                
                
                <Carousel indicators={false} interval={2500}>
                    <Carousel.Item>
                        
                        <div className="d-block w-100 slider_wrapper">
                                <div className="slider_img one">
                                    <h1>Create your own personalized gift card or book in your own words</h1>
                                </div>

                                <div className="w-50 slider_book">
                                    <div className="book-container">
                                        <div className="book">
                                            <img alt="Book1" src={book1} />
                                        </div>
                                    </div>
                                </div>

                                <PulseButton path={'/products'} label={"get started"} />
                        
                        </div>
                    </Carousel.Item>

                    <Carousel.Item>
                        <div className="d-block w-100 slider_wrapper">
                                <div className="slider_img three">
                                    <h1>We have amazing templates to choose from</h1>
                                </div>

                                <div className="w-50 slider_book">
                                    <div className="book-container">
                                        <div className="book">
                                            <img alt="Book3" src={book9} />
                                        </div>
                                    </div>
                                </div>
                                
                                <PulseButton path={'/avatar'} label={"get started"} />
                        </div>

                        


                        
                    </Carousel.Item>
                    
                    
                    

                    <Carousel.Item>
                        <div className="d-block w-100 slider_wrapper">
                                <div className="slider_img four">
                                    <h1>Stories for every occassion</h1>
                                </div>

                                <div className="w-50 slider_book">
                                    <div className="book-container">
                                        <div className="book">
                                            <img alt="Book3" src={book11} />
                                        </div>
                                    </div>
                                </div>
                                
                                <PulseButton path={'/avatar'} label={"get started"} />
                        </div>

                        


                        
                    </Carousel.Item>

                    <Carousel.Item>
                        

                        <div className="d-block w-100 slider_wrapper">
                                <div className="slider_img two">
                                    <h1>For all your loved ones, say how they are special to you.</h1>
                                </div>

                                <div className="w-50 slider_book">
                                    <div className="book-container">
                                        <div className="book">
                                            <img alt="Book2" src={book2} />
                                        </div>
                                    </div>
                                </div>

                                <PulseButton path={'/products'} label={"get started"} />
                        
                        </div>
                        
                    </Carousel.Item>

                    

                    <Carousel.Item>
                        <div className="d-block w-100 slider_wrapper">
                                <div className="slider_img five">
                                    <h1>Get your loved ones this unforgettable gift.</h1>
                                </div>

                                <div className="w-50 slider_book">
                                    <div className="book-container">
                                        <div className="book">
                                            <img alt="Book3" src={book10} />
                                        </div>
                                    </div>
                                </div>
                                
                                <PulseButton path={'/avatar'} label={"get started"} />
                        </div>

                        


                        
                    </Carousel.Item>
                </Carousel>

                    
            </div>
            <section className="section avatar_section">
            <h2 className="section__heading">Select Your Avatar</h2>
            <p className="avatar_description">Select your own personalized avatar and your recipient's, to be included in your book with a personal message in your own words.</p>
            
            <div className="avatar_wrapper">
                <img className="avatar avatar--m" src={male} alt="Male Avatar" />
                <img className="avatar avatar--f" src={female} alt="Female Avatar" />
            </div>
            <PulseButton path={'/avatar'} label={"Select now"} />

            </section>

            <section className="order_steps">
            <Row className="py-2 mb-4 instruction_row px-0">
                    <h3 className="py-3 section__heading ">Place an Order in 3 Easy Steps</h3>
                    <Col data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="center-bottom" className="rounded pb-4 shadow position-relative" sm={12} md={6} lg={3} xl={3}>
                        <Row>
                            <h5 className="py-2">Select your Avatar</h5>
                        </Row>
                        <Row className="img_wrapper">
                            <img className="instruction_img" src={design} alt="My Location" />
                        </Row>
                        <Row>
                            <h6 className="pt-4 pb-3 px-0">Choose your avatar based on gender and skin color. Your avatar will now automatically appear throughout your book.</h6>
                        </Row>
                        <div className="instruction_step position-absolute">1</div>
                    </Col>

                    <Col data-aos="fade-up" data-aos-duration="1000" data-aos-delay="250" data-aos-anchor-placement="center-bottom" className="rounded pb-4 shadow position-relative" sm={12} md={6} lg={3} xl={3}>
                        <Row>
                            <h5 className="py-2">Select your template</h5>
                        </Row>
                        <Row className="img_wrapper">
                            <img className="instruction_img" src={cover} alt="My Location" />
                        </Row>
                        <Row>
                            <h6 className="pt-4 pb-3 px-0">Choose from our wide selection of templates to fit any occasion and loved one</h6>
                        </Row>
                        <div className="instruction_step position-absolute">2</div>
                    </Col>

                    <Col data-aos="fade-up" data-aos-duration="1000" data-aos-delay="500" data-aos-anchor-placement="center-bottom" className="rounded pb-4 shadow position-relative" sm={12} md={6} lg={3} xl={3}>
                        <Row>
                            <h5 className="py-2">Personalize</h5>
                        </Row>
                        <Row className="img_wrapper">
                            <img className="instruction_img" src={personal} alt="My Location" />
                        </Row>
                        <Row>
                            <h6 className="pt-4 pb-3 px-0">Personalize the book with a story in your own words to create a personal, one-of-a-kind gift they will love!</h6>
                        </Row>
                        <div className="instruction_step position-absolute">3</div>
                    </Col>

                </Row>
                <PulseButton path={'/products'} label={"Order Now"} />
            </section>
            <section className="section occassion_section">
                <div className='background'>
                    <aside className='text_wrapper'>
                        <h1>Create A Custom Gift with a personalised story for your loved ones one their special day or occasion</h1>
                    </aside>
                </div>
                <div className='cover cover1'></div>
                <div className='cover cover2'></div>
            </section>


            <Subscribe click={setShow} />
            <SubscribeForm setShow={setShow} show={show} />
            
        </div>
    );
}

export default HomeScreen;
