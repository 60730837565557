import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'
import LoadingButton from '@mui/lab/LoadingButton';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Loader from '../components/Loader'



function LoginScreen({ location, history }) {
    
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'
    

    const userLogin = useSelector(state => state.userLogin)
    const { error, loading, userInfo } = userLogin

    useEffect(() => {
        window.scrollTo(0,0)
        if (userInfo) {
            if(localStorage.getItem('redirect')){
                history.push(JSON.parse(localStorage.getItem('redirect')))
                localStorage.removeItem('redirect')
            }else{
                history.push(redirect)
            }
        }
    }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    const validateEmail = () => {
        var re = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
        
        
        setEmailError(!re.test(email))
    }

    const handleEmailChange = e => {
        setEmail( e.target.value)
        
        validateEmail()
    
    };
    const handleClickShowPassword = () => {
        
        setShowPassword(!showPassword)
        
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    return (
        <FormContainer classes={'nav_margin py-4'}>
            <h1>Sign In</h1>
            {error && <Message width={100} variant='error'>{error}</Message>}
            {loading && <Loader width={100} />}
            <Form onSubmit={submitHandler}>


                <FormControl sx={{mt:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="email">
                        Email Address
                    </InputLabel>
                    <Input
                        required
                        placeholder="Enter Email Address"
                        id="email"
                        type="email"
                        startAdornment={
                            <InputAdornment position="start">
                                <EmailOutlinedIcon />
                            </InputAdornment>
                        }
                        value={email}
                        onChange={handleEmailChange}
                        error ={emailError}
                        color="success"
                    />
                    
                </FormControl>


                <FormControl sx={{my:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="password">
                        Password
                    </InputLabel>
                    <Input
                        required
                        placeholder="Enter Password"
                        sx={{mt:0}}
                        color="success"
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    
                </FormControl>

                

                <button color="success" type='submit' className='w-100 btn btn-primary' >
                    Sign In <LoginRoundedIcon />
                </button>
            </Form>

            <Row className='py-3'>
                <Col>
                    New Customer? <Link className='register_link'
                        to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                        Register
                        </Link>
                </Col>

                <Col>
                    Forgot Password? <Link className='register_link'
                        to={'/passwordReset'}>
                        Reset
                        </Link>
                </Col>
            </Row>

        </FormContainer>
    )
}

export default LoginScreen
