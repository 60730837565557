import React, { useState, useEffect } from 'react'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { Link } from 'react-router-dom'
import { Form, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { register } from '../actions/userActions'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import LinearProgress from '@mui/material/LinearProgress';
import LocationOn from '@mui/icons-material/LocationOn'
import LoadingButton from '@mui/lab/LoadingButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';


function RegisterScreen({ location, history }) {
    
    const [address, setAddress] = useState('');

    const handleChange = (address) => {
        setAddress(address)
    };
    const handleSelect = (address) => {
        setAddress(address)
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => setStoreLocation(`${latLng.lat}, ${latLng.lng}`))
          .catch(error => setErrorMaps(error));
      };

    

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isVendor, setIsVendor] = useState(false)
    const [storeName, setStoreName] = useState('')
    const [storeLocation, setStoreLocation] = useState('')
    
    const [errorMaps, setErrorMaps] = useState('')

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'
    localStorage.setItem('redirect', JSON.stringify(redirect))

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo, userMessage } = userRegister

    useEffect(() => {
        window.scrollTo(0,0)
        if (userInfo) {
            history.push(redirect)
        }
        if (userMessage) {
            setFirstName('')
            setLastName('')
            setEmail('')
            setConfirmPassword('')
            setPassword('')
            setPhoneNumber('')
        }
    }, [history, userInfo, redirect, userMessage])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(register({firstName, lastName, email, password, phoneNumber}))
        }

    }

    return (
        <FormContainer classes={'nav_margin py-4'}>
            <h1 className='fw-light'>Register</h1>
            {message  && <Message width={100} variant='error'>{message}</Message>}
            {error && <Message width={100} variant='error'>{error}</Message>}
            {loading && <Loader width={100} />}
            {userMessage && <Message width={100} variant='success'>{userMessage}</Message>}
            <Form onSubmit={submitHandler}>

                <Form.Group className='py-2' controlId='name'>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                        required
                        type='text'
                        placeholder='Enter First name'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group className='py-2' controlId='lastName'>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        required
                        type='text'
                        placeholder='Enter Last name'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group className='py-2' controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                        required
                        type='email'
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group className='py-2' controlId='phoneNumber'>
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                        required
                        type='phone'
                        placeholder='Enter Phone Number'
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group className='py-2' controlId='password'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='Enter Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                <Form.Group className='py-2' controlId='passwordConfirm'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                        required
                        type='password'
                        placeholder='Confirm Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    >
                    </Form.Control>
                </Form.Group>

                

                

                <button type='submit' className='w-100 rounded mt-4 btn btn-primary'>
                    <PersonAddIcon /> Register
                </button>

                

            </Form>
            {userMessage && <Message variant='success'>{userMessage}</Message>}
            <Row className='py-3'>
                <Col>
                    Have an Account? <Link className='register_link'
                        to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                        Sign In &#8594;
                        </Link>
                </Col>
            </Row>
        </FormContainer >
    )
}

export default RegisterScreen
