import React, {useEffect, useState, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { previewPageDetails } from '../actions/productActions';
import { getOrderDetails, payOrder } from '../actions/orderActions';
import PreviewPage from '../components/PreviewPage';
import { Carousel, Col, Container, ListGroup, Row } from 'react-bootstrap';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import Avatar from '@mui/material/Avatar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useReactToPrint } from 'react-to-print';
import PaymentButton from '../components/PaymentButton';
import PreviewFlip from '../components/PreviewFlip';
import PendingIcon from '@mui/icons-material/Pending';


function OrderDetailsScreen({match, history}) {
  const [index, setIndex] = useState(0);
  const previewLoaded = useRef(false)
  const tracking_id = localStorage.getItem('tracking_id')

    const printRef = useRef();
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    
    const dispatch = useDispatch()

    const payJenga = useSelector(state => state.payJenga)
    const { loading:loadPay, error:errorPay, success:successPay } = payJenga

    const orderDetails = useSelector(state => state.orderDetails)
    const { loading, error, product, pages, orderItems, order } = orderDetails

    const preview = ()=>{
      console.log('previewed')
          var pages = document.getElementsByClassName('flip_page');
          for(var i = 0; i < pages.length; i++)
              {
              var page = pages[i];
              if (i % 2 === 0)
                  {
                  page.style.zIndex = (pages.length - i);
                  }
              }

          
              for(var x = 0; x < pages.length; x++)
              {
                  //Or var page = pages[x];
                  pages[x].pageNum = x + 1;
                  pages[x].onclick=function()
                  {
                      if (this.pageNum % 2 === 0)
                      {
                          this.classList.remove('flipped');
                          this.previousElementSibling.classList.remove('flipped');
                      }
                      else
                      {
                          this.classList.add('flipped');
                          this.nextElementSibling.classList.add('flipped');
                      }
                  }
              }
    }

    useEffect(() => {
      window.scrollTo(0,0)
      console.log('0', previewLoaded)
      console.log('0', !previewLoaded.current)
      window.scrollTo(0, 0)

      // if (product.pages && previewLoaded.current) {
      //   if (product._id == match.params.id && previewLoaded.current) {
      //     console.log(product._id, match.params.id)
      //     preview()
      //   } else {
      //     if (tracking_id) {
      //       dispatch(previewPageDetails(match.params.id, tracking_id))
      //       previewLoaded.current = true
      //     } else {
      //       dispatch(previewPageDetails(match.params.id))
      //       previewLoaded.current = true
      //     }
      //   }

      // } else {
      //   if (tracking_id) {
      //     dispatch(previewPageDetails(match.params.id, tracking_id))
      //     previewLoaded.current = true
      //   } else {
      //     dispatch(previewPageDetails(match.params.id))
      //     previewLoaded.current = true
      //   }
      // }

      if(!userInfo){
        history.push(`/login?redirect=order/${match.params.id}`)
      }
      
      // if(product){
      if (order._id){
        console.log(order._id)
        if(order._id==match.params.id){
          preview()
        } else{
          dispatch(getOrderDetails(match.params.id))
        }
      }else{
        
        dispatch(getOrderDetails(match.params.id))
      }
  
      }, [dispatch, match, userInfo, history, successPay, order._id])
    
    const reactToPrintContent = React.useCallback(() => {
      return printRef.current;
    }, [printRef.current]);

    const reactToPrintTrigger = React.useCallback(() => {
      return (
        <button>Print using a custom print method (check dev console)</button>
      );
    }, []);

    const handlePrint = useReactToPrint({
      content: () => printRef.current,
    });
    // AQj_HWGfXisUgmkerG2vWYfVM2d-dEMaWtx0LjzyQ3qXHsQ0x3N6N4tbFR-gswdcVu8o4dBkddXHRj8T

  return (
    <Container  fluid className='nav_margin py-3 align-center'>

        <Row>

          {!product.gift_type && <Col xl={8}>
              <div className='flip_body py-0'>
                <div className="flip_book">
                  <div id="flip_pages" className="flip_pages">
                    &nbsp;&nbsp; Click on Book to Flip
                    {loading? <Loader width={80} />: error? <Message width={80} variant='error'>{error}</Message>:
                        [...new Set(orderItems.map(item => item.pageNumber))].sort((a,b)=>a-b).map(page=>(
                            
                            
                          <PreviewFlip flip={page} key={page} background={product.pages && product.pages.find(x => x.pageNumber === parseInt(page)).background_image} foreground={product.pages && orderItems.find(x => x.pageNumber === parseInt(page)).foreground_image} texts={orderItems.filter(x=> parseInt(x.pageNumber)===parseInt(page))} />
                            
                            
                        )
                        )
                    }
                  </div>
                </div>

              </div>
            </Col>
          }

        {product.gift_type &&
          <Col xl={8} className='align-items-center mb-4 h-100'>

            <Row className='align-items-center'>

              <Col xl={4} className='mb-2'>

                <div className='d-flex justify-content-center'>
                  <img width='200px' height={'300px'} src={orderItems[0].gift_option ? orderItems[0].gift_option.image : product.image} alt='gift_image' />
                </div>

              </Col>

              <Col xl={8} className='mb-2'>
                <ListGroup className='w-100'>
                  <ListGroup.Item><h2>Customization Details</h2></ListGroup.Item>

                  <ListGroup.Item className='p-0'>
                    <ListGroup variant='flush'>

                      <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                        <label className='list_label d-flex'>
                          {/* <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                            <ShoppingBasketIcon />
                          </Avatar>&nbsp;&nbsp; */}
                          Colour :
                        </label>
                        <div className='list_value'>{orderItems[0].gift_option.colour_name}</div>
                      </ListGroup.Item>

                      <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                        <label className='list_label d-flex'>
                          {/* <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                            <ShoppingBasketIcon />
                          </Avatar>&nbsp;&nbsp; */}
                          Images:
                        </label>
                        <div className='d-flex flex-row w-full overflow-x-scroll'>
                          {orderItems[0].gift_order_images.map((option, index) => {
                            return <div className='m-2' key={index} style={{ position: 'relative' }}>
                              <img width='100px' src={option.image} alt='upload' />
                              <span style={{ position: "absolute", zIndex: 100, right: 0, top: -8, borderColor: "#f5007e", backgroundColor: "#ec2276" }} className='text-white rounded-circle fs-6' ></span>
                            </div>
                          })}
                        </div>
                      </ListGroup.Item>

                      <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                        <label className='list_label d-flex'>
                          {/* <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                            <ShoppingBasketIcon />
                          </Avatar>&nbsp;&nbsp; */}
                          Order Notes :
                        </label>
                        <div className='list_value'>{order.order_info}</div>
                      </ListGroup.Item>



                    </ListGroup>
                  </ListGroup.Item>

                </ListGroup>

              </Col>

            </Row>
          </Col>
        }
      
        <Col xl={4} className='buttons  d-flex flex-column'>
          <ListGroup className='w-100'>
            <ListGroup.Item><h2>Order Details</h2></ListGroup.Item>

            <ListGroup.Item className='p-0'>
              <ListGroup variant='flush'>
                <ListGroup.Item className='d-flex flex-row justify-content-between'>
                  <label className='list_label d-flex align-items-center'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <ShoppingBasketIcon />
                    </Avatar>&nbsp;&nbsp;Product :
                  </label>
                  <div className='list_value'>{product.name||''}</div>
                </ListGroup.Item>

                <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                  <label className='list_label d-flex'>
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <AutoStoriesIcon />
                    </Avatar>&nbsp;&nbsp;Total Pages :
                  </label>
                  <div className='list_value'>{pages.length}</div>
                </ListGroup.Item>

                <ListGroup.Item className='d-flex flex-row justify-content-between py-2'>
                    <label className='list_label d-flex'>
                      <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                        <AutoStoriesIcon />
                      </Avatar>&nbsp;&nbsp;Quantity :
                    </label>
                  <div className='list_value'>{order.quantity || 1}</div>
                  </ListGroup.Item>

                <ListGroup.Item className='d-flex flex-row justify-content-between'>
                  <label className='list_label d-flex align-items-center'>                  
                    <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                      <AttachMoneyIcon />
                    </Avatar>&nbsp;&nbsp;Item Price :</label>
                  <div className='list_value'>Kshs. {product.price && product.price}</div>
                </ListGroup.Item>

                  <ListGroup.Item className='d-flex align-items-start flex-row justify-content-between'>
                    
                    <label className='list_label d-flex align-items-center align-items-start'>
                      <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                                        <DeliveryDiningIcon />
                      </Avatar>&nbsp;&nbsp;Destination:&nbsp;
                    </label>
                    <div className='list_value'>&nbsp;{(order &&order.shippingAddress) && order.shippingAddress.address}</div>
                    
                  </ListGroup.Item>
                  <ListGroup.Item className='d-flex flex-row justify-content-between'>
                      <label className='list_label d-flex align-items-center'>                  
                        <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                          <AttachMoneyIcon />
                        </Avatar>&nbsp;&nbsp;Shipping Price :</label>
                      <div className='list_value'>Kshs. {order && order.shippingPrice}</div>
                  </ListGroup.Item>
                  
              

                  <ListGroup.Item>
                      <h4>Payment</h4>
                      <ListGroup>
                        <ListGroup.Item className='d-flex flex-row justify-content-between'>
                          <label className='list_label d-flex align-items-center'>                  
                            <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                              <AttachMoneyIcon />
                            </Avatar>&nbsp;&nbsp;Total Price :</label>
                          <div className='list_value'>Kshs. {order && order.totalPrice}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className={!order.isPaid && 'border-bottom-0'}>
                          
                          <div className='w-100'>{order.isPaid ? 
                            <Message width={100} variant='success'>Paid On: {(new Date(order.paidAt)).toLocaleString('en-AU').substring(0, 24).toUpperCase()}</Message>: 
                            <Message width={100} variant='warning'>Please make payment to complete your order.</Message>}</div>
                          
                        </ListGroup.Item>
                        {(userInfo && !order.isPaid) && (
                          <ListGroup.Item className='border-top-0' >
                            {loadPay ? <Loader width={80} />:<PaymentButton name={userInfo.name} phoneNumber={userInfo.phoneNumber} email={userInfo.email} amount={order.totalPrice} tref={`InMywDs-${order._id}`} description={`payment for ${product.name}`} /> }
                            {errorPay && <Message width={80} variant='error'>{errorPay}</Message>}
                          </ListGroup.Item>)}
                          {order.isPaid && <ListGroup.Item className='d-flex align-items-center flex-row justify-content-between'>
                        <label className='list_label d-flex align-items-center'>
                            <Avatar sx={{ bgcolor: '#a551bf' }} variant="rounded">
                              <PendingIcon />
                            </Avatar>&nbsp;&nbsp;Status :
                        </label>
                        <div>{order.isDelivered ? <Message width={100} variant='success'>Delivered on &nbsp;{(new Date(order.deliveredAt)).toLocaleString('en-AU').substring(0, 10).toUpperCase()}</Message> : <Message width={100} variant='warning'>Processing Order</Message>}</div>
                        
                      </ListGroup.Item>}
                      </ListGroup>
                  </ListGroup.Item>

                  

                  

                  

                  

                  {/* {(userInfo && !userInfo.isAdmin) && <ListGroup.Item className='d-flex align-items-center flex-row justify-content-between'>
                    <Message width={100} variant='info'><a style={{color:'#a555bf', fontSize:'14px', textTransform:'uppercase', fontWeight:500}} target='_blank' rel='noreferrer' href='https://instagram.com/tendajicreations?igshid=YmMyMTA2M2Y='>Add a Custom Made Blanket to your order!</a></Message>
                    
                  </ListGroup.Item>} */}

            {(userInfo && order.isPaid ) && <ListGroup.Item className='d-flex align-items-center flex-row justify-content-between'>
                      <button onClick={()=>handlePrint()} className='btn btn-primary'>Print</button>
                  </ListGroup.Item>}
                  
                  
                  
                </ListGroup>
              </ListGroup.Item>
            </ListGroup>
                {/* <button onClick={()=>history.push(`/product/${match.params.id}/page/${index+1}?redirect=preview`)}  className=' btn btn-primary'>Edit This Page</button>
                <button  className=' btn btn-primary'>Proceed to Checkout</button> */}
        </Col>

      </Row>

      

      {!product.gift_type && 
        <div style={{ height: '0.1px', width: '0px', overflow: 'hidden' }}>
            <div ref={printRef} className='print_container'>
            {loading? <Loader width={80} />: error? <Message width={80} variant='error'>{error}</Message>:
                  [...new Set(orderItems.map(item => item.pageNumber))].sort((a,b)=>a-b).map(page=>(
                    
                      
                    <PreviewPage key={page} background={product.pages && product.pages.find(x => x.pageNumber === parseInt(page)).background_image} foreground={product.pages && orderItems.find(x => x.pageNumber === parseInt(page)).foreground_image} texts={orderItems.filter(x=> parseInt(x.pageNumber)===parseInt(page))} />
                    
                  )
                  )}
              
            </div>
        </div>
        }
      
    </Container>
  )
}

export default OrderDetailsScreen
