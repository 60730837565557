import React from 'react';
import { useHistory } from 'react-router-dom';


   

function PulseButton({label, path}) {
  const history = useHistory()
  return (<button onClick={()=>history.push(path)} className="bton">{label} </button>);
  
}

export default PulseButton;
