import React from 'react'
import { Alert } from '@mui/material'

function Message({ variant, children, width }) {
    return (
        <Alert sx={{ width: `${width}%`, margin:'0 auto' }} severity={variant}>
            {children}
        </Alert>
    )
}

export default Message
