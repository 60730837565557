import axios from 'axios'
import {
    GIFT_LIST_REQUEST,
    GIFT_LIST_SUCCESS,
    GIFT_LIST_FAIL,

    GIFT_TYPE_DETAILS_REQUEST,
    GIFT_TYPE_DETAILS_SUCCESS,
    GIFT_TYPE_DETAILS_FAIL,

    GIFT_CART_ADD_ITEM_REQUEST,
    GIFT_CART_ADD_ITEM_SUCCESS,
    GIFT_CART_ADD_ITEM_FAIL,

} from '../constants/giftConstants'

import { PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_FAIL } from '../constants/productConstants'

export const listGifts = (keyword = '') => async (dispatch, getState) => {

    try {
        dispatch({ type: GIFT_LIST_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/gifts${keyword}`)


        dispatch({
            type: GIFT_LIST_SUCCESS,
            payload: data,

        })

    } catch (error) {
        dispatch({
            type: GIFT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listGiftTypeDetails = (id) => async (dispatch) => {
    try {

        dispatch({ type: GIFT_TYPE_DETAILS_REQUEST })
        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/gifts/${id}/`)

        dispatch({
            type: GIFT_TYPE_DETAILS_SUCCESS,
            payload: data,

        })

    } catch (error) {

        dispatch({
            type: GIFT_TYPE_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const previewGiftDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST })

        const { data } = await axios.get(`${process.env.REACT_APP_PROD_URL}/api/products/${id}/`)

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data,

        })


    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const addToGiftCart = (data) => async (dispatch, getState) => {

    try {
        dispatch({
            type: GIFT_CART_ADD_ITEM_REQUEST
        })


        dispatch({
            type: GIFT_CART_ADD_ITEM_SUCCESS,
            payload: {
                _id: data._id,
                gift_option: data.gift_option,
                order_note: data.order_note,
                quantity: data.quantity ? data.quantity : 1,
                upload_images: data.upload_images,
                personalise:data.personalise
            }
        })
        console.log('cart action',data)
        // localStorage.setItem('giftCartItems', JSON.stringify({
        //     _id: data._id,
        //     gift:data.gift,
        //     gift_option: data.gift_option,
        //     order_note: data.order_note,
        //     quantity: data.quantity,
        //     upload_images: data.upload_images
        localStorage.setItem('giftCartItems', JSON.stringify(getState().gift_cart.giftCartItems)
        // })
        )
    } catch (error) {
        dispatch({
            type: GIFT_CART_ADD_ITEM_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

