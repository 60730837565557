import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {Accordion} from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { listFaqs } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'

function FaqScreen() {
    const dispatch = useDispatch()
    const faqList = useSelector(state => state.faqList)
    const { error, loading, faqs } = faqList

    useEffect(() => {
        window.scrollTo(0, 0)

        dispatch(listFaqs())

    }, [dispatch])

    const [currentActiveKey, setCurrentActiveKey] = useState(null);

    const toggleActiveKey = (key) => {
        console.log(key)
        setCurrentActiveKey(currentActiveKey === key ? null : key);
    };


    return (
        <Container fluid className='nav_margin py-3 align-center justify-content-center'>

            <div className="faq_area section_padding_130" id="faq">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-8 col-lg-6">

                            <div className="section_heading text-center wow fadeInUp" data-wow-delay="0.2s" style={{visibility: "visible", animationDelay: "0.2s", animationName: "fadeInUp"}}>
                                <h3><span>Frequently </span> Asked Questions</h3>
                                
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-4">

                        <div className="col-12 col-sm-10 col-lg-8">
                            <div className="accordion faq-accordian" id="faqAccordion">
                                    <Accordion defaultActiveKey="" flush>
                                    
                                    {loading ? <Loader width={80} />
                                        : error ? <Message width={80} variant='error'>{error}</Message>
                                        : faqs.map(faq => (                                        

                                            <Accordion.Item eventKey={faq.id} key={faq.id}> 
                                            <Accordion.Header style={currentActiveKey === faq.id ? { backgroundColor: "red" } : null} onClick={() => {
                                                toggleActiveKey(faq.id);
                                            }}>
                                                {faq.question}
                                            </Accordion.Header>
                                            <Accordion.Body className="">
                                                {faq.answer}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}

                                    </Accordion>

                            </div>

                            <div className="text-center d-flex flex-column align-items-center justify-content-center mt-4 wow fadeInUp" data-wow-delay="0.5s" style={{ visibility: "visible", animationDelay: "0.5s", animationName: "fadeInUp" }}>
                                <i className="lni-emoji-sad"></i>
                                <p className="mb-0 px-2">Can't find your answers?</p>
                                <div>Drop us an email at <a href="mailto:info@inmywords.co.ke"> info@inmywords.co.ke</a></div>
                                <div>Call / Text / WhatsApp us on <a href="tel:+254769112555"> 0769112555</a></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        
        </Container>
    );
}

export default FaqScreen;