import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    VENDOR_PRODUCT_LIST_REQUEST,
    VENDOR_PRODUCT_LIST_SUCCESS,
    VENDOR_PRODUCT_LIST_FAIL,


    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    PRODUCT_DETAILS_RESET,

    PRODUCT_DELETE_REQUEST,
    PRODUCT_DELETE_SUCCESS,
    PRODUCT_DELETE_FAIL,

    PRODUCT_CREATE_REQUEST,
    PRODUCT_CREATE_SUCCESS,
    PRODUCT_CREATE_FAIL,
    PRODUCT_CREATE_RESET,

    PRODUCT_UPDATE_REQUEST,
    PRODUCT_UPDATE_SUCCESS,
    PRODUCT_UPDATE_FAIL,
    PRODUCT_UPDATE_RESET,

    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,
    PRODUCT_CREATE_REVIEW_RESET,

    PRODUCT_TOP_REQUEST,
    PRODUCT_TOP_SUCCESS,
    PRODUCT_TOP_FAIL,

    PAGE_DETAILS_REQUEST,
    PAGE_DETAILS_SUCCESS,
    PAGE_DETAILS_FAIL,

    OCASSION_LIST_REQUEST,
    OCASSION_LIST_SUCCESS,
    OCASSION_LIST_FAIL,

    AVATAR_LIST_REQUEST,
    AVATAR_LIST_SUCCESS,
    AVATAR_LIST_FAIL,

    ALL_PRODUCTS_LIST_REQUEST,
    ALL_PRODUCTS_LIST_SUCCESS,
    ALL_PRODUCTS_LIST_FAIL,
    
    UPLOAD_PHOTO_REQUEST,
    UPLOAD_PHOTO_SUCCESS,
    UPLOAD_PHOTO_FAIL,

} from '../constants/productConstants'




export const productListReducer = (state = { products: [], distanceProducts: [] }, action) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return { loading: true, products: [] }

        case PRODUCT_LIST_SUCCESS:
            
            return {
                loading: false,
                products: action.payload.products,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const allProductsListReducer = (state = { products: []}, action) => {
    switch (action.type) {
        case ALL_PRODUCTS_LIST_REQUEST:
            return { loading: true, products: [] }

        case ALL_PRODUCTS_LIST_SUCCESS:
            
            return {
                loading: false,
                products: action.payload,
                page: action.payload.page,
                pages: action.payload.pages
            }

        case ALL_PRODUCTS_LIST_FAIL:
            return { loading: false, error: action.payload, products: [] }

        default:
            return state
    }
}

export const ocassionListReducer = (state = { ocassions: []}, action) => {
    switch (action.type) {
        case OCASSION_LIST_REQUEST:
            return { loading: true, ocassions: [] }

        case OCASSION_LIST_SUCCESS:
            
            return {
                loading: false,
                ocassions: action.payload,
                
                
                
            }

        case OCASSION_LIST_FAIL:
            return { loading: false, error: action.payload, ocassions: [] }

        default:
            return state
    }
}

export const avatarListReducer = (state = { avatars: []}, action) => {
    switch (action.type) {
        case AVATAR_LIST_REQUEST:
            return { loading: true, avatars: [] }

        case AVATAR_LIST_SUCCESS:
            
            return {
                loading: false,
                avatars: action.payload,
                
                
                
            }

        case AVATAR_LIST_FAIL:
            return { loading: false, error: action.payload, avatars: [] }

        default:
            return state
    }
}

export const productDetailsReducer = (state = { product:{} , reviews: [] , page:[], texts:[], pictures:[] }, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return { loading: true, product:{} , reviews: [] , page:[], texts:[], pictures:[] }

        case PRODUCT_DETAILS_SUCCESS:
            
            return { loading: false, reviews:action.payload.reviews, product: action.payload, page: (action.pageNumber && action.payload.pages) ? action.payload.pages.find(x => x.pageNumber === Number(action.pageNumber)):[], texts: (parseInt(action.pageNumber) && action.payload.pages && action.payload.pages.find(x => x.pageNumber === Number(action.pageNumber))) ? action.payload.pages.find(x => x.pageNumber === Number(action.pageNumber)).texts:[], pictures: (parseInt(action.pageNumber) && action.payload.pages && action.payload.pages.find(x => x.pageNumber === Number(action.pageNumber))) ? action.payload.pages.find(x => x.pageNumber === Number(action.pageNumber)).pictures:[] }

        case PRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        case PRODUCT_DETAILS_RESET:
            return {  product:{} , reviews: [] , page:[], texts:[], pictures:[] }

        default:
            return state
    }
}

export const pageDetailsReducer = (state ={page:{}, texts:[], pictures:[]}, action) => {
    switch (action.type) {
        case PAGE_DETAILS_REQUEST:

            return { loading: true, page:{}, texts:[], pictures:[] }

        case PAGE_DETAILS_SUCCESS:
            
            return { loading: false, page: action.payload, texts: action.payload.texts ? action.payload.texts:[], pictures: action.payload.pictures ? action.payload.pictures:[] }

        case PAGE_DETAILS_FAIL:
            return { loading: false, error: action.payload, texts:[], pictures:[]}

        

        default:
            return state
    }
}

export const productDeleteReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_DELETE_REQUEST:
            return { loading: true }

        case PRODUCT_DELETE_SUCCESS:
            return { loading: false, success: true }

        case PRODUCT_DELETE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const uploadPhotoReducer = (state = {}, action) => {
    switch (action.type) {
        case UPLOAD_PHOTO_REQUEST:
            return { loading: true }

        case UPLOAD_PHOTO_SUCCESS:
            return { loading: false, success: true }

        case UPLOAD_PHOTO_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const productCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_REQUEST:
            return { loading: true }

        case PRODUCT_CREATE_SUCCESS:
            return { loading: false, success: true, product: action.payload }

        case PRODUCT_CREATE_FAIL:
            return { loading: false, error: action.payload }

        case PRODUCT_CREATE_RESET:
            return {}

        default:
            return state
    }
}


export const productUpdateReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        case PRODUCT_UPDATE_REQUEST:
            return { loading: true }

        case PRODUCT_UPDATE_SUCCESS:
            return { loading: false, success: true, product: action.payload }

        case PRODUCT_UPDATE_FAIL:
            return { loading: false, error: action.payload }

        case PRODUCT_UPDATE_RESET:
            return { product: {} }

        default:
            return state
    }
}



export const productReviewCreateReducer = (state = {}, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_REVIEW_REQUEST:
            return { loading: true }

        case PRODUCT_CREATE_REVIEW_SUCCESS:
            return { loading: false, success: true, }

        case PRODUCT_CREATE_REVIEW_FAIL:
            return { loading: false, error: action.payload }

        case PRODUCT_CREATE_REVIEW_RESET:
            return {}

        default:
            return state
    }
}


export const productTopRatedReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case PRODUCT_TOP_REQUEST:
            return { loading: true, products: [] }

        case PRODUCT_TOP_SUCCESS:
            return { loading: false, products: action.payload, }

        case PRODUCT_TOP_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const vendorProductListReducer = (state = { vendorProducts: []}, action) => {
    switch (action.type) {
        case VENDOR_PRODUCT_LIST_REQUEST:
            return { loading: true, vendorProducts: [] }

        case VENDOR_PRODUCT_LIST_SUCCESS:
            
            return {
                loading: false,
                vendorProducts: action.payload,
                
                
                
            }

        case VENDOR_PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

