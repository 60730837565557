import React, { useState, useEffect } from 'react';
import logo2 from '../logo2.png';
import { NavLink as Link } from 'react-router-dom'

function Footer() {

    return (

        <footer className="text-center text-lg-start text-dark">

            <div className="container p-4">

                <div className="row my-4">

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">

                        <div className="rounded-circle bg-white shadow-1-strong d-flex align-items-center justify-content-center mx-auto" style={{ width: "150px", height: "150px" }}>
                            <img src={logo2} height="70" alt=""
                                loading="lazy" />
                        </div>

                        <p className="text-center">Books for every occassion</p>

                        <ul className="list-unstyled d-flex flex-row justify-content-center">
                            <li>
                                <a className="text-dark px-2" href="#!">
                                    <i className="fab fa-facebook-square"></i>
                                </a>
                            </li>
                            <li>
                                <a className="text-dark px-2" href="#!">
                                    <i className="fab fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a className="text-dark ps-2" href="#!">
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </li>
                        </ul>

                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        {/* <h5 className="text-uppercase mb-4">Animals</h5>

                <ul className="list-unstyled">
                    <li className="mb-2">
                    <a href="#!" className="text-white"><i className="fas fa-paw pe-3"></i>When your pet is missing</a>
                    </li>
                    <li className="mb-2">
                    <a href="#!" className="text-white"><i className="fas fa-paw pe-3"></i>Recently found</a>
                    </li>
                    <li className="mb-2">
                    <a href="#!" className="text-white"><i className="fas fa-paw pe-3"></i>How to adopt?</a>
                    </li>
                    <li className="mb-2">
                    <a href="#!" className="text-white"><i className="fas fa-paw pe-3"></i>Pets for adoption</a>
                    </li>
                    <li className="mb-2">
                    <a href="#!" className="text-white"><i className="fas fa-paw pe-3"></i>Material gifts</a>
                    </li>
                    <li className="mb-2">
                    <a href="#!" className="text-white"><i className="fas fa-paw pe-3"></i>Help with walks</a>
                    </li>
                    <li className="mb-2">
                    <a href="#!" className="text-white"><i className="fas fa-paw pe-3"></i>Volunteer activities</a>
                    </li>
                </ul> */}
                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase mb-4">Quick Links</h5>

                        <ul className="list-unstyled">
                            <li className="mb-2">
                                <Link to="/products" className="text-dark text-decoration-none">Products</Link>
                            </li>
                            <li className="mb-2">
                                <Link to="/avatar" className="text-dark text-decoration-none">Avatar</Link>
                            </li>
                            <li className="mb-2">
                                <Link to="/faq" className="text-dark text-decoration-none">FAQs</Link>
                            </li>
                            <li className="mb-2">
                                <Link to="/" className="text-dark text-decoration-none">Terms and Conditions</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-md-0">
                        <h5 className="text-uppercase mb-4">Contact</h5>

                        <ul className="list-unstyled">
                            <li>
                                <p className='d-flex justify-content-center'>
                                    <div><i className="fas fa-map-marker-alt pe-2"></i></div>
                                    <div>
                                        <p className='mb-0'>Kenya House Complex (building with sno cream), Ground floor, G34</p>
                                        <p className='mb-0'>Koinange/Monrovia street, Nairobi, Kenya</p>
                                    </div>
                                </p>
                            </li>
                            <li>
                                <p><i className="fas fa-phone pe-2"></i>Call/Text/Whatsapp 0769112555</p>
                            </li>
                            <li>
                                <p><i className="fas fa-envelope pe-2 mb-0"></i>info@inmywords.co.ke</p>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

            <div className="text-center p-3" style={{ background: "rgba(0, 0, 0, 0.2)" }}>
                © <span id="year"><script>document.getElementById("year").innerHTML = new Date().getFullYear()</script></span> Copyright:
                <a className="text-dark text-decoration-none" href="/">InMyWords.co.ke</a>
            </div>

        </footer>



    );
};


export default Footer;