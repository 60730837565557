import React, { useEffect, useState, useRef } from 'react'
import { Row, Container, Col, Form } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Loader from '../components/Loader'
import Message from '../components/Message'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { listGiftTypeDetails, addToGiftCart } from '../actions/giftActions'
import { index } from 'd3';
import { CART_CLEAR_ITEMS } from '../constants/cartConstants'

function GiftDesignScreen({ match }) {

    const giftTypeDetails = useSelector(state => state.giftType)
    const { error, loading, giftType, giftOptions, main_image } = giftTypeDetails

    const gift_cart = useSelector(state => state.gift_cart)
    const { giftCartItems } = gift_cart

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const [upload_images, setUploadImage] = useState([]);
    const [order_notes, setOrderNote] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [addImg, setAddImg] = useState(1);
    const [colourSelected, setColourSelected] = useState("");
    const [personalise, setPersonalization] = useState(false);

    // var upload_images = []

    // const colourSelected = useRef("");
    // const order_notes = useRef("");
    // const quantity = useRef(1);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin
    const history = useHistory()


    const dispatch = useDispatch()

    useEffect(() => {
        // localStorage.removeItem('cartItems')
        dispatch({ type: CART_CLEAR_ITEMS })

        dispatch(listGiftTypeDetails(match.params.id))

        if (giftCartItems) {
            setUploadImage(giftCartItems.upload_images ? giftCartItems.upload_images : [])
            setOrderNote(giftCartItems.order_note)
            setQuantity(giftCartItems.quantity ? giftCartItems.quantity : 1)
            for (let i=0; i<giftOptions.length; i++) {
                if (giftOptions[i] === giftCartItems.gift_option._id){
                    setColourSelected(giftCartItems.gift_option)
                }
            }
            setPersonalization(giftCartItems.personalise)
            // upload_images.value = gift_cart.giftCartItems.upload_images ? gift_cart.giftCartItems.upload_images : []
            // order_notes.value = gift_cart.giftCartItems.order_note
            // quantity.value = gift_cart.giftCartItems.quantity
            // colourSelected.value = gift_cart.giftCartItems.gift_option

        }
        // console.log(selected_images)

    }, [dispatch, match, giftCartItems])


    function setImage(_id) {

        let img_main = document.getElementById('other_image')
        var options = document.getElementsByTagName('option')

        if (!Number(_id)) {
            img_main.setAttribute('src', _id)
            for (let i = 0; i < options.length; i++) {
                options[i].selected = false
            }
            setColourSelected("")
            // colourSelected.value = ""
            return
        }


        let colour = giftOptions.filter(obj => { return obj._id === Number(_id) })
        colour = colour[0]
        for (let i = 0; i < options.length; i++) {
            if (Number(options[i].value) === colour._id) {
                setColourSelected(colour)
                // colourSelected.value = ""
                options[i].selected = true
                img_main.setAttribute('src', colour.image)
            }
        }
    }

    function fileSelectedHandler(e) {
        let image = {}
        image.file = e.target.files[0]
        image.url = URL.createObjectURL(image.file);
        let reader = new FileReader();
        reader.onloadend = function () {
            image.encoded_image = reader.result
        }
        reader.readAsDataURL(e.target.files[0]);
        setUploadImage(current => [...current, image]);
        setTimeout(() => { setAddImg(addImg + 1) }, 500)
        // upload_images.push(image)
    }

    function removeImageUpload(e) {
        setUploadImage(current => current.filter((s, i) => (i !== e)))
    }

    function updateOrderNotes(e) {
        setOrderNote(e.target.value)
        // order_notes.value = e.target.value

    }

    function updateQuantity(e) {
        if (e.target.value <= 0) {
            setQuantity(Number(1))
        } else {
            setQuantity(Number(e.target.value))
        }
    }

    function addToCart() {

        let gift_data = {
            _id: giftType._id,
            gift: giftType,
            gift_option: colourSelected,
            order_note: order_notes,
            quantity: quantity ? quantity : 1,
            upload_images: upload_images,
            personalise: personalise
        }

        dispatch(addToGiftCart(gift_data))

        console.log('cart added', gift_data)

        !userInfo && localStorage.setItem('redirect', JSON.stringify(`preview/${match.params.id}`))

        history.push(`/shipping/${match.params.id}`)
    }

    return (

        <Container className=" nav_margin">
            {loading ? <Loader width={80} />
                : error ? <Message width={80} variant='error'>{error}</Message> :
                    <Row className='p-4'>


                        <Col xs={12} md='6' lg={'6'} className='mt-4 d-flex flex-column px-4 py-4 justify-content-center'>

                            <div className='d-flex justify-content-center'>
                                <img width='200px' id='other_image' height={'300px'} src={main_image} alt='gift_image' />
                            </div>

                            <div className='d-flex flex-row w-full overflow-x-scroll mt-3'>

                                <div className='p-2' key={giftType._id} >
                                    <img width='100px' height={'100px'} src={main_image} alt='gift_image' onClick={() => setImage(giftType.image)} />
                                </div>

                                {giftOptions.map(option => {
                                    return <div className='p-2' key={option._id} >
                                        <img width='100px' height={'100px'} src={option.image} alt='gift_image' onClick={e => { setImage(option._id); setImage(option._id) }} />
                                    </div>
                                })}



                            </div>

                        </Col>

                        <Col xs={'12'} md={'6'} lg={'6'} className='mb-3 mt-4 d-flex flex-column card px-4 py-4 justify-content-center'>

                            <div className='d-flex flex-row text-primary text-center justify-content-center mb-3'>
                                <p>Customize your mug here</p>
                            </div>

                            <div className='d-flex flex-row align-items-center mb-3'>
                                <div className='w-25'>
                                    <h6>Color:</h6>
                                </div>
                                <Form.Select aria-label="Default select example" defaultValue={colourSelected ? colourSelected._id : ""} onChange={e => setImage(e.target.value)}>
                                    <option key={''} diabled>---- select ----</option>
                                    {giftOptions.map(option => {
                                        return <option value={option._id} style={{ textTransform: "capitalize" }} key={option._id}>{option.colour_name}</option>
                                    })}

                                </Form.Select>
                            </div>

                            {colourSelected && colourSelected.customizable && <div className='d-flex flex-row align-items-center mb-3'>
                                <div className='w-25'>
                                    <h6>Upload photo:</h6>
                                </div>
                                <div className='form-control flex justify-content-center text-center'>
                                    <label for="fileUpload" className='flex justify-content-center text-center' style={{ width: "100%" }}>
                                        <span>
                                            <svg aria-hidden="true" style={{ width: "25px" }} class="tw-mb-3 tw-text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                                            {/* <p class="text-sm">Upload photo</p> */}
                                            Upload photo
                                        </span>
                                        <input onChange={e => { fileSelectedHandler(e) }} id='fileUpload' type='file' multiple hidden accept="image/png, image/jpeg, image/jpg" />
                                    </label>
                                    {/* <input id='fileUpload' type='file' multiple accept="image/png, image/jpeg, image/jpg" /> */}
                                </div>
                            </div>}

                            {upload_images.length > 0 &&

                                <div className='d-flex flex-row align-items-center mb-3'>


                                    <div className='d-flex flex-row w-full overflow-x-scroll'>
                                        {upload_images.map((option, index) => {
                                            return <div className='m-2' key={index} style={{ position: 'relative' }}>
                                                <img width='100px' src={option.encoded_image} alt='upload' />
                                                <span style={{ position: "absolute", zIndex: 100, right: 0, top: -8, borderColor: "#f5007e", backgroundColor: "#ec2276" }} className='text-white rounded-circle fs-6' onClick={() => { removeImageUpload(index) }} ><CloseRoundedIcon /></span>
                                            </div>
                                        })}
                                    </div>

                                </div>
                            }

                            <div className='d-flex flex-row align-items-center mb-3'>
                                <div className='w-25'>
                                    <h6></h6>
                                </div>
                                <Form.Check
                                    type="switch"
                                    id=""
                                    label="Personalise for KES 300. The image(s) selected will be printed on the item."
                                    checked={personalise}
                                    onChange={e => setPersonalization(!personalise)}
                                />
                            </div>

                            <div className='d-flex flex-row align-items-center mb-3'>
                                <div className='w-25'>
                                    <h6>Quantity:</h6>
                                </div>
                                <Form.Control
                                    type="number"
                                    id=""
                                    value={quantity}
                                    onChange={e => updateQuantity(e)}
                                    defaultValue={Number(1)}
                                    min={Number(1)}
                                />
                            </div>

                            <div className='d-flex flex-row align-items-center mb-3'>
                                <div className='w-25'>
                                    <h6>More info:</h6>
                                </div>

                                <InputGroup>
                                    <Form.Control placeholder='more instructions here' as="textarea" value={order_notes} onChange={e => updateOrderNotes(e)} aria-label="With textarea" />
                                </InputGroup>

                            </div>

                            <div className='mt-4 text-center align-self-center'>
                                <button className={`btn btn-primary`} onClick={() => addToCart()}>Add to Cart</button>
                            </div>

                        </Col>

                    </Row>
            }

        </Container>
    )

}

export default GiftDesignScreen