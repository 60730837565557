import axios from 'axios'
import {
    CART_ADD_ITEM_REQUEST,
    CART_ADD_ITEM_FAIL,
    CART_ADD_ITEM_SUCCESS,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,

    CART_SAVE_PAYMENT_METHOD,

    CART_SAVE_SHIPPING_COST

} from '../constants/cartConstants'
import { geocodeByAddress } from 'react-places-autocomplete'


export const addToCart = (data) => async (dispatch, getState) => {

    try
    {
        dispatch({
            type: CART_ADD_ITEM_REQUEST
        })
        

        dispatch({
            type: CART_ADD_ITEM_SUCCESS,
            payload: {
                _id: data._id,
                productId: data.productId,
                pageNumber: data.pageNumber,
                fontFamily: data.fontFamily,
                fontSize: data.fontSize,
                fontColor: data.fontColor,
                message: data.message,
                x: data.x,
                y: data.y,
                width: data.width,
                height: data.height
                
            }
        })
        localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
    }catch (error) {
       dispatch({
           type: CART_ADD_ITEM_FAIL,
           payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
       }) 
    }
}



export const removeFromCart = (id) => (dispatch, getState) => {
    dispatch({
        type: CART_REMOVE_ITEM,
        payload: id,
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


export const saveShippingAddress = (data) => (dispatch, getState) => {

    
    
    dispatch({
        type: CART_SAVE_SHIPPING_ADDRESS,
        payload: data,
    })

    localStorage.setItem('shippingAddress', JSON.stringify(data))

    

    
}

export const savePaymentMethod = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_PAYMENT_METHOD,
        payload: data,
    })

    localStorage.setItem('paymentMethod', JSON.stringify(data))
}

export const saveShippingCost = (data) => (dispatch) => {
    dispatch({
        type: CART_SAVE_SHIPPING_COST,
        payload: data,
    })

    localStorage.setItem('shippingCost', JSON.stringify(data))
}