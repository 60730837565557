import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {Helmet} from "react-helmet";
import { Row, Col, Image, ListGroup, Card, Form, Container } from 'react-bootstrap'
import Rating from '../components/Rating'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProductDetails, createProductReview } from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import { Button } from '@mui/material'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Avatar from '@mui/material/Avatar';
import StoreIcon from '@mui/icons-material/Store';
import { green } from '@mui/material/colors';
import PreviewFlip from '../components/PreviewFlip';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import { IoCreateOutline } from 'react-icons/io5'




function ProductScreen({ match, history }) {
    
    const preview = ()=>{
        var pages = document.getElementsByClassName('flip_page');
        document.getElementById('flip_pages').classList.add('unopen')
        for(var i = 0; i < pages.length; i++)
            {
            var page = pages[i];
            if (i % 2 === 0)
                {
                page.style.zIndex = (pages.length - i);
                }
            }

        
            for(var x = 0; x < pages.length; x++)
            {
                //Or var page = pages[x];
                pages[x].pageNum = x + 1;
                pages[x].onclick=function()
                {
                    document.getElementById('flip_pages').classList.remove('unopen')
                    
                    if (this.pageNum % 2 === 0)
                    {
                        this.classList.remove('flipped');
                        this.previousElementSibling.classList.remove('flipped');
                    }
                    else
                    {
                        this.classList.add('flipped');
                        this.nextElementSibling.classList.add('flipped');
                    }
                }
            }
    }
    
    
    const [qty, setQty] = useState(1)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')

    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product, reviews } = productDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const productReviewCreate = useSelector(state => state.productReviewCreate)
    const {
        loading: loadingProductReview,
        error: errorProductReview,
        success: successProductReview,
    } = productReviewCreate

    

    useEffect(() => {
        window.scrollTo(0,0)
        if (successProductReview) {
            setRating(0)
            setComment('')
            dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
            dispatch(listProductDetails(match.params.id))
        } 
        else if (product.pages ){
            if (parseInt(product._id)===parseInt(match.params.id)){
                preview()
                
            }else{
                dispatch(listProductDetails(match.params.id))
            }
           
        } else{
            dispatch(listProductDetails(match.params.id))
        }

        
        

        

        // eslint-disable-next-line eqeqeq
        
        
        

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successProductReview, product.pages, match])

    

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createProductReview(
            match.params.id, {
            rating,
            comment
        }
        ))
    }

    return (
        <Container fluid className='nav_margin py-3 flex-column'>
            <Helmet
                style={[{
                    cssText: `
                    .senderskin{fill:#8D5524;}
        .recepientskin{fill:#F1C27D;}
        .senderdress{fill:#6ef17a; stroke: #0b5c12;}
        .recepientdress{fill:#F1878D; stroke: #71877F;}
        .sendershoe{fill:yellow}
        .recepientshoe{fill:red}
        #sender-linear-gradient{
            
            --sender-eye-color: blue;
        }

        #recepient-linear-gradient{
            --recepient-eye-color: #9eb45e;
        }
        .senderhair{stroke:blue;fill:blue;stroke-width:4.42px;}
        .recepienthair{stroke:green;fill:green;stroke-width:4.42px;}

        #recepient-hair-gradient{
            --recepient-skin-color: #F1C27D;
            --recepient-hair-color: green;
        }

        #sender-hair-gradient{
            --sender-skin-color: #8D5524;
            --sender-hair-color: blue;
        }
        .recepientdress{stroke-width:.92px;stroke-miterlimit:10;}
        .senderdress{stroke-width:.92px;stroke-miterlimit:10;}
                    `
                  }]} 
            />
            {/* <Button onClick={history.goBack} startIcon={<ArrowBackIosNewOutlinedIcon />} className="mb-2" variant="outlined" color="success">Go Back</Button> */}
        {loading? <Loader width={80} />: error? <Message width={80} variant='error'>{error}</Message>: 
            <Row className='justify-content-around'>
                <Col md={12} lg={12} xl={8}>
                        <div className='flip_body align-items-start py-0'>
                            <div className="flip_book">
                                <div id="flip_pages" className={`flip_pages ${document.getElementsByClassName('flipped').length}`}>
                                &nbsp;&nbsp; Click on Book to Flip
                                   
                                        {product.pages && product.pages.map(item=>(
                                            
                                            
                                            <PreviewFlip key={item._id} background={item.background_image} foreground={item.foreground_image} texts={item.texts} pictures={item.pictures} />
                                            
                                            
                                        )
                                        )
                                    }
                                </div>
                                </div>

                        </div>
                    </Col>
                    
                    <Col md={12} lg={12} xl={4}>
                            
                                {/* <ListGroup className="" variant='flush'>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Name:</Col>
                                            <Col>
                                                <strong>Kshs. {Number(product.name)*Number(qty)}</strong>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Price:</Col>
                                            <Col>
                                                <strong>Kshs. {product.price}</strong>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                    <ListGroup.Item>
                                        <Row>
                                            <Col>Description:</Col>
                                            <Col>
                                                {product.description}
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                        
                                    


                                    <ListGroup.Item>
                                        <Button
                                            variant="contained"
                                            
                                            fullWidth
                                            
                                            disableElevation
                                            color="success"
                                            startIcon={<ShoppingBasketIcon/>}
                                        >
                                            Create
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup> */}
                                <ListGroup className='w-100 border-0'>
                                    <ListGroup.Item><h2>Book Details</h2></ListGroup.Item>

                                    <ListGroup.Item className='p-0'>
                                        <ListGroup variant='flush'>
                                            
                                            <ListGroup.Item className='d-flex flex-row justify-content-between'>
                                                <label className='list_label d-flex'>Name :</label>
                                                <div className='list_value'>{product.name||''}</div>
                                            </ListGroup.Item>
                                            
                                            <ListGroup.Item className='d-flex flex-row justify-content-between'>
                                                <label className='list_label d-flex'>Price :</label>
                                                <div className='list_value'>Kshs. {product.price && product.price}</div>
                                            </ListGroup.Item>

                                            <ListGroup.Item className='d-flex flex-column justify-content-between'>
                                                <label className='list_label d-flex'>Description :</label>
                                                <div className='list_value'>{product.description}</div>
                                            </ListGroup.Item>

                                            <ListGroup.Item className='d-flex flex-row justify-content-between'>
                                                <label className='list_label d-flex'>Occasions:</label>
                                                <div className='list_value'>{product.ocassions}</div>
                                            </ListGroup.Item>

                                            <ListGroup.Item className='d-flex flex-row justify-content-between'>
                                                <label className='list_label d-flex'>Total Pages :</label>
                                                <div className='list_value'>{product.pages && product.pages.length}</div>
                                            </ListGroup.Item>                                       
                                        </ListGroup>
                                    </ListGroup.Item>
                                    <ListGroup.Item className='d-flex flex-row justify-content-between align-items-center'>
                                        
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            disableElevation
                                            color="success"
                                            onClick={()=>history.push(`/product/${match.params.id}/page/1`)}
                                            startIcon={<IoCreateOutline/>}
                                            className={`text-capitalize btn btn-primary ${ product.sender===1 && 'male'}`}
                                        >
                                            Create
                                        </Button>
                                    </ListGroup.Item>
                                </ListGroup>
                            
                        </Col>
                        
                    
                            

                            
                        
                </Row>}
                {(!loading && !error) && <Row>
                    <Col md={8} className='py-4'>
                    <Button onClick={history.goBack} startIcon={<ArrowBackIosNewOutlinedIcon />} className="mb-2" variant="outlined" color="success">Go Back</Button>
            
                        <ListGroup className="" variant='flush'>

                                <ListGroup.Item className="rounded-top">
                                    <h4>Reviews</h4>
                                    {reviews.length === 0 && <Message variant='info'>No Reviews</Message>}
                                </ListGroup.Item>

                                {reviews.map((review) => (
                                    <ListGroup.Item key={review._id}>
                                        <strong>{review.user.name}</strong>
                                        <Rating value={review.rating} color='#f8e825' />
                                        <p>{review.createdAt.substring(0, 10)}</p>
                                        <p>{review.comment}</p>
                                    </ListGroup.Item>
                                ))}

                                <ListGroup.Item >
                                    <h4>Write a review</h4>

                                    {loadingProductReview && <Loader />}
                                    {successProductReview && <Message variant='success'>Review Submitted</Message>}
                                    {errorProductReview && <Message variant='error'>{errorProductReview}</Message>}

                                    {userInfo ? (
                                        <Form onSubmit={submitHandler}>
                                            <Form.Group controlId='rating'>
                                                <Form.Label>Rating</Form.Label>
                                                <Form.Control
                                                    as='select'
                                                    value={rating}
                                                    onChange={(e) => setRating(e.target.value)}
                                                >
                                                    <option value=''>Select...</option>
                                                    <option value='1'>1 - Poor</option>
                                                    <option value='2'>2 - Fair</option>
                                                    <option value='3'>3 - Good</option>
                                                    <option value='4'>4 - Very Good</option>
                                                    <option value='5'>5 - Excellent</option>
                                                </Form.Control>
                                            </Form.Group>

                                            <Form.Group controlId='comment'>
                                                <Form.Label>Review</Form.Label>
                                                <Form.Control
                                                    as='textarea'
                                                    row='5'
                                                    value={comment}
                                                    onChange={(e) => setComment(e.target.value)}
                                                ></Form.Control>
                                            </Form.Group>

                                            <Button
                                                disabled={loadingProductReview}
                                                type='submit'
                                                variant='outlined'
                                                color="success"
                                                className='mt-2'
                                            >
                                                Submit
                                            </Button>

                                        </Form>
                                    ) : (
                                            <Message variant='info'>Please <Link to={`/login?redirect=/product/${match.params.id}`}>login</Link> to write a review</Message>
                                        )}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>  
                    </Row>}


        </Container >
    )
}

export default ProductScreen
