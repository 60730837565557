import React, {useEffect, useState} from 'react'
import { resetPassword } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import FormContainer from '../components/FormContainer'
import { Form } from 'react-bootstrap'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockResetIcon from '@mui/icons-material/LockReset';
import {USER_RESET_PASSWORD_RESET} from '../constants/userConstants'



function ResetScreen({ match, history }) {
    
    const uid = match.params.id
    const token = match.params.token
    const dispatch = useDispatch()
    const [password, setPassword] = useState('')
    const [message, setMessage] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const passwordReset = useSelector(state => state.passwordReset)
    const {error, loading, userMessage, success} = passwordReset
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    useEffect(() => {

        window.scrollTo(0,0)

        if (success) {
            
            setConfirmPassword('')
            setPassword('')
            history.push(`/login/`)
        }
        

        
    }, [dispatch, success])

    const submitHandler = (e)=>{
        e.preventDefault()
        setMessage('')
        if (password !== confirmPassword) {
            dispatch({type:USER_RESET_PASSWORD_RESET})
            setMessage('Passwords do not match')

        }else{
            dispatch(resetPassword({password, uid, token}))
        }

    }

    const handleClickShowPassword = () => {
        
        setShowPassword(!showPassword)
        

      
  };

  const handleClickShowConfirmPassword = () => {
        
    
    setShowConfirmPassword(!showConfirmPassword)

  
};

  const handleMouseDownPassword = (event) => {
      event.preventDefault();
  };



    return (
        <FormContainer classes={'w-100 nav_margin py-4'}>
            <h2>Set New Password</h2>
            {message !==''  && <Message width={100} variant='error'>{message}</Message>}
            {loading && <Loader/>} 
            {error && <Message width={100} variant='error'>{error}</Message>}
            {success && <Message width={100} variant='success'>{userMessage}</Message>}
            <Form onSubmit={submitHandler}>
                <FormControl sx={{my:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="password">
                        Password
                    </InputLabel>
                    <Input
                        required
                        placeholder="Enter Password"
                        sx={{mt:0}}
                        color="success"
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    
                </FormControl>

                <FormControl sx={{my:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="confirmPassword">
                        Confirm Password
                    </InputLabel>
                    <Input
                        required
                        placeholder="Confirm Password"
                        sx={{mt:0}}
                        color="success"
                        startAdornment={
                            <InputAdornment position="start">
                                <LockOutlinedIcon />
                            </InputAdornment>
                        }
                        id="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e)=>setConfirmPassword(e.target.value)}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle confirmPassword visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                            >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                    
                </FormControl>

                <button type='submit' className='w-100 rounded mt-4 btn btn-primary'>
                    <LockResetIcon /> Reset Password
                </button>
            </Form>
            
        </FormContainer>
    )
}

export default ResetScreen
