import React, {useState, useEffect} from 'react'
import FormContainer from '../components/FormContainer'
import { Form } from 'react-bootstrap'
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { useDispatch, useSelector } from 'react-redux'
import { sendEmail } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import TextField from '@mui/material/TextField';

import SubjectIcon from '@mui/icons-material/Subject';

function ContactScreen() {
    const [customerEmail, setCustomerEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [emailError, setEmailError] = useState(false)

    const dispatch = useDispatch()

    const userEmail = useSelector(state=>state.userEmail)
    const {error, loading, success} = userEmail

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(sendEmail({customerEmail, phoneNumber, subject, message}))

    }

    const validateEmail = () => {
        var re = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;
        
        
        setEmailError(!re.test(customerEmail))
    }

    const handleEmailChange = e => {
        setCustomerEmail( e.target.value)
        
        validateEmail()
    
    };

    useEffect(() => {
        window.scrollTo(0,0)
      if(success){
          setCustomerEmail('')
          setEmailError(false)
          setCustomerEmail('')
          setPhoneNumber('')
          setMessage('')
          setSubject('')
        }
    }, [success, dispatch])
    
  return (
    <FormContainer classes={'nav_margin py-4'}>
        <h2>Contact Us</h2>
            {error && <Message width={100} variant='error'>{error}</Message>}
            {loading && <Loader width={100} />}
            {success && <Message width={100} variant='success'>Email Sent!</Message>}
            <Form className='mb-4' onSubmit={submitHandler}>


                <FormControl sx={{my:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="email">
                        Your Email Address
                    </InputLabel>
                    <Input
                        required
                        placeholder="Enter Email Address"
                        id="customerEmail"
                        type="email"
                        startAdornment={
                            <InputAdornment position="start">
                                <EmailOutlinedIcon />
                            </InputAdornment>
                        }
                        value={customerEmail}
                        onChange={handleEmailChange}
                        error ={emailError}
                        color="success"
                    />
                    
                </FormControl>


                <FormControl sx={{mb:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="phoneNumber">
                        Phone Number
                    </InputLabel>
                    <Input
                        required
                        placeholder="Phone Number"
                        sx={{mt:0}}
                        color="success"
                        startAdornment={
                            <InputAdornment position="start">
                                <PhoneAndroidOutlinedIcon />
                            </InputAdornment>
                        }
                        id="phoneNumber"
                        type='tel'
                        value={phoneNumber}
                        onChange={(e)=>setPhoneNumber(e.target.value)}
                    />
                    
                </FormControl>

                <FormControl sx={{mb:4}} size="large" variant="standard" fullWidth>
                    <InputLabel color="success" sx={{fontSize:20}} htmlFor="subject">
                        Email Subject
                    </InputLabel>
                    <Input
                        required
                        placeholder="Email subject"
                        sx={{mt:0}}
                        color="success"
                        startAdornment={
                            <InputAdornment position="start">
                                <SubjectIcon />
                            </InputAdornment>
                        }
                        id="subject"
                        type='text'
                        value={subject}
                        onChange={(e)=>setSubject(e.target.value)}
                    />
                    
                </FormControl>

                <FormControl sx={{mb:4}} size="large" variant="standard" fullWidth>
                    <TextField
                        id="message"
                        label="message"
                        multiline
                        minRows={4}
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        
                    />
                </FormControl>

                

                <button color="success" type='submit' className='w-100 btn btn-primary' >
                    Send <SendOutlinedIcon />
                </button>
            </Form>

            

            


    </FormContainer>
  )
}

export default ContactScreen