import React from 'react'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

function Subscribe({click}) {
  return (
    <button onClick={()=>click(true)} className='d-flex flex-row justify-content-between align-items-center btn btn-primary subscribe' style={{'zIndex':'-11 '}}>
        <MarkEmailReadIcon />
        <span className='ms-2'>Subscribe</span>
    </button>
  )
}

export default Subscribe