import React, {useEffect, useState} from 'react'
import { Container, Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listUsers } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { LinkContainer } from 'react-router-bootstrap'
import { FaTimes, FaCheck } from 'react-icons/fa'

function UserListScreen({history}) {
    const dispatch = useDispatch()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userList = useSelector(state => state.userList)
    const { users, loading, error } = userList

    useEffect(() => {
        window.scrollTo(0,0)
      if(!userInfo || !userInfo.isAdmin){
          history.push('/login')
      }else{
          dispatch(listUsers())
      }
    }, [userInfo, history, dispatch])
    
  return (
    <Container className='pt-5 nav_margin'>
        <h2>My Users</h2>
                {loading ? (
                    <Loader />
                ) : error ? (
                    <Message variant='error'>{error}</Message>
                ) : (
                            <Table bordered striped responsive className='table-sm'>
                                <thead>
                                    <tr>
                                        <th className="text-center">ID</th>
                                        <th>Name</th>
                                        <th className="text-center">Email </th>
                                        <th className="text-center">Phone Number</th>
                                        <th className="text-center">Active</th>
                                        <th className="text-center">Admin</th>
                                        
                                    </tr>
                                </thead>

                                <tbody>
                                    {users.map(user => (
                                        <tr key={user._id}>
                                            <td className="text-center">{user._id}</td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td className="text-center">{user.phoneNumber}</td>
                                            <td className="text-center">{user.isActive ? <FaCheck style={{color:'green'}} /> : (
                                                <FaTimes style={{color:'red'}} />
                                            )}</td>
                                            <td className="text-center">{user.isAdmin ? <FaCheck style={{color:'green'}} /> : (
                                                <FaTimes style={{color:'red'}} />
                                            )}</td>
                                            
                                            
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
    </Container>
  )
}

export default UserListScreen