import React, {useEffect, useRef, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProductDetails, previewPageDetails } from '../actions/productActions';
import PreviewPage from '../components/PreviewPage';
import { Carousel, ListGroup } from 'react-bootstrap';


function PreviewEditScreen({match, history}) {
  const [index, setIndex] = useState(0);
  const previewLoaded = useRef(false)

    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };
    
    const dispatch = useDispatch()
    const [orderComments, setOrderComments] = useState('')
    const printRef = useRef();
    const pageNumber = parseInt(match.params.id)
    
    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product } = productDetails

  const tracking_id = localStorage.getItem('tracking_id')


    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

  



  useEffect(() => {
    window.scrollTo(0,0)
    if (tracking_id) {
      dispatch(previewPageDetails(match.params.id, tracking_id))
      previewLoaded.current = true
    } else {
      dispatch(previewPageDetails(match.params.id))
      previewLoaded.current = true
    }
    }, [dispatch, history, match, tracking_id])

    const sender = parseInt(localStorage.getItem('senderAvatar'))
    


    const pages = [...new Set(cartItems.filter(x=>parseInt(x.productId) ===parseInt(match.params.id)).map(item => item.pageNumber))].sort((a,b)=>a-b)


    
    

    const submitHandler = (e) => {
      e.preventDefault()

      

  }

  const handleCheckout=()=>{
    localStorage.setItem('redirect', JSON.stringify(`preview/${match.params.id}`))

    history.push(`/shipping/${match.params.id}`)
  }

    

    
  return (
    <div className='d-flex  preview nav_margin align-center'>
      

      
      
      
        {/* <div>
          <div style={{overflow:'hidden', width:0, height:0}}>
            <div className='print_container' ref={printRef}>
            {loading? <Loader width={80} />: error? <Message width={80} variant='error'>{error}</Message>:
              pages.map(page=>(
                <PreviewPage key={page} background={product.pages && product.pages.find(x=> x.pageNumber===parseInt(page)).image} texts={cartItems.filter(x=>parseInt(x.productId) ===parseInt(match.params.id) && parseInt(x.pageNumber)===parseInt(page))} />
                // <div>{page}</div>
              )
              )
            }
            </div>
              
          </div>
        </div> */}
        
        <Carousel interval={null} activeIndex={index} onSelect={handleSelect}>
          {loading? <Loader width={80} />: error? <Message width={80} variant='error'>{error}</Message>:
              pages.map(page=>(
                <Carousel.Item key={page}>
                  <div className="d-block w-100">
                    <PreviewPage background={product.pages && product.pages.find(x => x.pageNumber === parseInt(page)).background_image} foreground={product.pages && product.pages.find(x => x.pageNumber === parseInt(page)).foreground_image} texts={cartItems.filter(x=>parseInt(x.productId) ===parseInt(match.params.id) && parseInt(x.pageNumber)===parseInt(page))} />
                  </div>
                </Carousel.Item>
              )
              )
          }
        </Carousel>

        
      
      <div className='buttons d-flex flex-column'>
        <ListGroup className='w-100'>
          <ListGroup.Item><h2>Order Details</h2></ListGroup.Item>

          <ListGroup.Item className='p-0'>
            <ListGroup variant='flush'>
              <ListGroup.Item className='d-flex flex-row justify-content-between'>
                <label className='list_label'>Product :</label>
                <div className='list_value'>{product.name}</div>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex flex-row justify-content-between'>
                <label className='list_label'>Total Pages :</label>
                <div className='list_value'>{pages.length}</div>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex flex-row justify-content-between'>
                <label className='list_label'>Price :</label>
                <div className='list_value'>Kshs. {product.price}</div>
              </ListGroup.Item>
              <ListGroup.Item className='d-flex flex-row justify-content-between'>
                {(index+1!==2 &&!(parseInt(index+1)===parseInt(pages.length)) && !(parseInt(index+1)===parseInt(pages.length-1))) && <button onClick={()=>history.push(`/product/${match.params.id}/page/${index+1}?redirect=preview/${match.params.id}`)}  className={`btn btn-primary ${(sender===1 || product.sender===1) && 'male'}`}>Edit This Page</button>}
                <button  onClick={()=>history.push(`/preview/${match.params.id}`)} className={`btn btn-primary ${(sender===1 || product.sender===1) && 'male'}`}>Preview</button>
              </ListGroup.Item>
              
            </ListGroup>
          </ListGroup.Item>
        </ListGroup>
            {/* <button onClick={()=>history.push(`/product/${match.params.id}/page/${index+1}?redirect=preview`)}  className={`btn btn-primary ${sender===1 && 'male'}`}>Edit This Page</button>
            <button  className={`btn btn-primary ${sender===1 && 'male'}`}>Proceed to Checkout</button> */}
      </div>
    </div>
  )
}

export default PreviewEditScreen