export const GIFT_LIST_REQUEST = 'GIFT_LIST_REQUEST'
export const GIFT_LIST_SUCCESS = 'GIFT_LIST_SUCCESS'
export const GIFT_LIST_FAIL = 'GIFT_LIST_FAIL'


export const GIFT_TYPE_DETAILS_REQUEST = 'GIFT_TYPE_DETAILS_REQUEST'
export const GIFT_TYPE_DETAILS_SUCCESS = 'GIFT_TYPE_DETAILS_SUCCESS'
export const GIFT_TYPE_DETAILS_FAIL = 'GIFT_TYPE_DETAILS_FAIL'

export const GIFT_CART_ADD_ITEM_REQUEST = 'GIFT_CART_ADD_ITEM_REQUEST'
export const GIFT_CART_ADD_ITEM_SUCCESS = 'GIFT_CART_ADD_ITEM_SUCCESS'
export const GIFT_CART_ADD_ITEM_FAIL = 'GIFT_CART_ADD_ITEM_FAIL'

export const GIFT_CART_REMOVE_ITEM = 'GIFT_CART_REMOVE_ITEM'

export const GIFT_CART_CLEAR_ITEMS = 'GIFT_CART_CLEAR_ITEMS'