import {
    CART_ADD_ITEM_REQUEST,
    CART_ADD_ITEM_FAIL,
    CART_ADD_ITEM_SUCCESS,
    CART_REMOVE_ITEM,
    CART_SAVE_SHIPPING_ADDRESS,
    CART_CLEAR_SHIPPING_ADDRESS,
    CART_SAVE_PAYMENT_METHOD,
    CART_SAVE_SHIPPING_COST,

    CART_CLEAR_ITEMS,
} from '../constants/cartConstants'



export const cartReducer = (state = { cartItems: [], shippingAddress: {}}, action) => {
    switch (action.type) {
        case CART_ADD_ITEM_REQUEST:
            return {
                ...state,
                loading: true
        }
        case CART_ADD_ITEM_SUCCESS:
            const item = action.payload
            const existItem = state.cartItems.find(x => x._id === item._id)

            if (existItem) {                
                
                return {
                    ...state,
                    loading: false,
                    success: true,
                    cartItems: state.cartItems.map(x =>
                        x._id === existItem._id ? item : x),
                    
                }

            } else {
                return {
                    ...state,
                    cartItems: [...state.cartItems, item],
                    loading: false,
                    success: true
                }
            }
        case CART_ADD_ITEM_FAIL:
            return {
                loading: false,
                error: action.payload
        }

        case CART_REMOVE_ITEM:

            return {
                ...state,
                cartItems: state.cartItems.filter(x => x._id !== action.payload)
            }

        case CART_SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingAddress: action.payload
            }
        case CART_SAVE_SHIPPING_COST:
            return {
                ...state,
                shippingCost: action.payload
            }
        case CART_CLEAR_SHIPPING_ADDRESS:
            localStorage.removeItem('shippingAddress')
            return {
                ...state,
                shippingAddress: {},
                
            }

        case CART_SAVE_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethod: action.payload
            }

        case CART_CLEAR_ITEMS:
            localStorage.removeItem('dragItems')
            localStorage.removeItem('senderAvatar')
            localStorage.removeItem('recipientAvatar')
            return {
                ...state,
                cartItems: []
            }

        default:
            return state
    }
}


export const shippingDetailsReducer = (state = { shippingInfo:{}}, action)=>{
    switch (action.type) {
        case CART_SAVE_SHIPPING_ADDRESS:
            return {
                ...state,
                shippingInfo: action.payload
            }

        case CART_CLEAR_SHIPPING_ADDRESS:
            localStorage.removeItem('shippingAddress')
            return {
                ...state,
                shippingInfo: {},

            }
    default:
        return state
    }
}