import React, {useEffect} from 'react'
import { verifyUser } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../components/Loader'
import Message from '../components/Message'



function ConfirmationScreen({ match, history }) {
    
    const id = match.params.id
    const token = match.params.token
    const dispatch = useDispatch()

    const userVerify = useSelector(state => state.userVerify)
    const {error, loading, userMessage} = userVerify

    useEffect(() => {

        window.scrollTo(0,0)
        !userMessage && dispatch(verifyUser(id, token))

        
    }, [dispatch, id, token, userMessage, history])

    

    


    

    

    return (
        <div className='w-100 nav_margin py-4'>
        {loading? <Loader/>: error? <Message width={80} variant='error'>{error}</Message>:
            
                
            <Message width={80} variant='success'>{userMessage}</Message>

        }
        <Message width={80} variant=''>
            <Link className='register_link'
                to={'/login'}>
                Sign In &#8594;
            </Link>
        </Message>

        </div >
    )
}

export default ConfirmationScreen
