import React, {useState} from 'react'
import { Container } from 'react-bootstrap'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CircleIcon from '@mui/icons-material/Circle';
import Male from '../avatars/Male';
import * as d3 from 'd3';
import Mother1 from '../avatars/Mother1';
import { eyeColorArray, skinArray, hairColorArray } from '../constants/colors';
import { FormCheck } from 'react-bootstrap';
import { useSpring, animated} from 'react-spring'
import Female from '../avatars/Female';

function EditAvatarScreen({prop}) {
    const [skinColor, setSkinColor] = useState('#603a21')
    const [hairColor, setHairColor] = useState('#000000')
    const [eyeColor, setEyeColor] = useState('#704b37')
    const [selector, setSelector] = useState('skin')
    const [selectorArray, setSelectorArray] = useState(skinArray)
    const [pantsColor, setPantsColor] = useState('#000000')
    const [shoesColor, setShoesColor] = useState('#0d1c54')
    const [shirtColor, setShirtColor] = useState('#0d1c54')
    const [canceled, setCanceled] = useState(false)
    const [dressColor, setDressColor] = useState('#eaac1f')
    const [ornamentColor, setOrnamentColor] = useState('#0d1c54')
    
    const props = useSpring({
        to: { y: 0, opacity:1 },
        from: { y: 20, opacity:0 },
        reset: true,
        cancel: canceled,
        delay: 0,
        config: { duration: 500 },
        
        
        
    })

    
    const handleAlignment = (event,newAlignment) => {
        setCanceled(true)
        console.log(event, newAlignment)
        selector ==='hair'? setHairColor(newAlignment): selector ==='eye'? setEyeColor(newAlignment): selector ==='dress'? setDressColor(newAlignment): setSkinColor(newAlignment)
      };
    //   d3.xml("/images/avatars/mother1.svg",{
    //     headers: [
    //       ["Content-Type", "image/svg+xml"],
    //     ]
    //   }).then((xml)=> {
        
    //     document.body.appendChild(xml.documentElement);
    //   });
  return (
    <Container className=' d-flex flex-column nav_margin pt-3'>
      <div className='avatar_edit_wrapper'>
        {/* <Male skinColor={skinColor} hairColor={hairColor} eyeColor={eyeColor} pantsColor={pantsColor} shoesColor={shoesColor} shirtColor={shirtColor} /> */}
        <Female skinColor={skinColor} hairColor={hairColor} eyeColor={eyeColor} dressColor={dressColor} shoesColor={shoesColor} ornamentColor={ornamentColor} />
        
        {selector!=='eye'? <FormCheck 
          type={'radio'}
          onChange={()=>void(0)}
          className='part_selector eye'
          checked={selector==='eye'}
          onClick={()=>{setCanceled(false); setSelector('eye'); setSelectorArray(eyeColorArray)}}
        />: <span className='part_selector eye selected'>Eye</span>}

        {selector!=='hair'? <FormCheck 
          type={'radio'}
          onChange={()=>void(0)}
          className='part_selector hair'
          checked={selector==='hair'}
          onClick={()=>{setCanceled(false); setSelector('hair'); setSelectorArray(hairColorArray)}}
        />: <span className='part_selector hair selected'>Hair</span>}

        {selector!=='skin'? <FormCheck 
          type={'radio'}
          onChange={()=>void(0)}
          className='part_selector skin'
          checked={selector==='skin'}
          onClick={()=>{setCanceled(false); setSelector('skin'); setSelectorArray(skinArray)}}
        />: <span className='part_selector skin selected'>Skin</span>}

        {/* {selector!=='shirt'? <FormCheck 
          type={'radio'}
          onChange={()=>void(0)}
          className='part_selector hair shirt'
          checked={selector==='shirt'}
          onClick={()=>{setCanceled(false); setSelector('shirt')}}
        />: <span className='part_selector hair shirt selected'>Shirt</span>} */}

        {/* {selector!=='pants'? <FormCheck 
          type={'radio'}
          onChange={()=>void(0)}
          className='part_selector hair pants'
          checked={selector==='pants'}
          onClick={()=>{setCanceled(false); setSelector('pants')}}
        />: <span className='part_selector hair pants selected'>Trousers</span>} */}

        {selector!=='dress'? <FormCheck 
          type={'radio'}
          onChange={()=>void(0)}
          className='part_selector hair pants'
          checked={selector==='dress'}
          onClick={()=>{setCanceled(false); setSelector('dress')}}
        />: <span className='part_selector hair pants selected'>Dress</span>}



        {selector!=='shoes'? <FormCheck 
          type={'radio'}
          onChange={()=>void(0)}
          className='part_selector shoes'
          checked={selector==='shoes'}
          onClick={()=>{setCanceled(false); setSelector('shoes')}}
        />: <span className='part_selector shoes selected'>Shoes</span>}
      </div>
        
        {(selector!=='pants' && selector!=='shirt' && selector!=='shoes' && selector!=='dress' ) && <animated.div style={props} className='pt-2 skinSelector_wrapper flex-column'>
            <h6 className='text-center'>{selector ==='hair'? 'Hair Color': selector ==='eye'? 'Eye Color': 'Skin Color'}</h6>
            <ToggleButtonGroup
                value={skinArray}
                exclusive
                onChange={handleAlignment}
                aria-label="text alignment"
                className='skinSelector'
            >
                {selectorArray.map(item=>(
                    <ToggleButton className='me-2' selected={item===skinColor||item===hairColor || item===eyeColor} key={item} style={{padding:0, backgroundColor:item}}  value={item} aria-label="item">
                        <CircleIcon sx={{fill:item, width:'100%'}} />
                    </ToggleButton>
                ))}
                
        </ToggleButtonGroup>
        </animated.div>}
        {(selector==='pants'||selector==='shirt' ||selector==='shoes'||selector==='dress') && <animated.div style={props} className='pt-2 skinSelector_wrapper flex-column'>
          <h6 className='text-center'>{selector ==='shirt'? 'Shirt Color':selector ==='pants'? 'Pants Color':selector ==='dress'? 'Dress Color': 'Shoes Color'} <small class="text-muted ms-2">(Click to Change)</small></h6>
          <div className='d-flex align-items-center'>
            
            <input className='form-control' placeholder='click to select' type='color' value={selector==='shirt'? shirtColor: selector==='pants'? pantsColor:selector==='dress'? dressColor:shoesColor} onChange={selector==='dress'? (e)=>{setCanceled(true);setDressColor(e.target.value)}:selector==='shirt'? (e)=>{setCanceled(true);setShirtColor(e.target.value)}: selector==='pants'? (e)=>{setCanceled(true);setPantsColor(e.target.value)}:(e)=>{setCanceled(true);setShoesColor(e.target.value)}} />
            
          </div>
        </animated.div>}
    </Container>
  )
}

export default EditAvatarScreen