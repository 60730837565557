import React from 'react'
import { Image } from 'react-bootstrap'
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import axios from 'axios'


function Product({product}) {
  const history = useHistory()

  // const createOrder = async() => {
  //   console.log("sending request ...")
  //   console.log(product._id)
  //   const tracking_id = localStorage.getItem('tracking_id')

  //   if (tracking_id){
  //     history.push(`/product/${product._id}/page/1`)

  //   } else{
  //     const { data } = await axios.post(`${process.env.REACT_APP_PROD_URL}/api/orders/create/`)
  //     console.log(data)
  //     localStorage.setItem("tracking_id", data["tracking_no"])
  //     history.push(`/product/${product._id}/page/1`)
  //   }

    
  // }

  return (
    <div className="product_card shadow py-4 px-4">
      <Link className="link book-container mb-4" component={RouterLink} to={`/product/${product._id}`}>
        <div className="book">
          <Image fluid src={product.image} className="image" />
        </div>
        
        
      </Link>
      
  


      <Link className="name" component={RouterLink} to={`/product/${product._id}`}>
        {product.name}
        <div className="arrow">&nbsp; &#8594;</div>
      </Link>
      <div className="price">
          <label>Price :</label>
          <div className="amount">Kshs. {product.price}</div>
      </div>
        <div className="description">{product.description.substring(0,120)+ '...'}</div>
        
        <button onClick={()=>{history.push(`/product/${product._id}/page/1/`)}} className="bttn">Create</button>
      {/* <button onClick={createOrder} className="bttn">Create</button> */}
    </div>
  )
}

export default Product